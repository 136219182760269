import React from 'react'
import * as redux from 'redux'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import CurationTool from './curation_tool'

import { GazetteState } from '../types'

import 'gazette/styles/app.scss'
import { TrackJS } from './TrackJS/TrackJS'
import { config } from 'gazette/config'

interface Props {
    store: redux.Store<GazetteState>
}

let baseUrl: string
// Ensure global is defined
declare const BASE_URL: string
if (typeof BASE_URL !== 'undefined') {
    baseUrl = BASE_URL
}

export const App: React.FC<Props> = ({ store }) => {
    return (
        <Provider store={store}>
            {config.trackJsToken && <TrackJS trackJsToken={config.trackJsToken} />}
            <BrowserRouter basename={baseUrl}>
                <CurationTool />
            </BrowserRouter>
        </Provider>
    )
}
