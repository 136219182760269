import React from 'react'
import { Logo } from 'gazette/components/logo/logo'
import DeskSelection from 'gazette/components/desk_selection'
import MainNavigation from 'gazette/components/main_navigation'

import { Desk } from 'gazette/types'

import './menu_bar.scss'

interface Props {
    activeDesk: Desk
    desks: Desk[]
}

export const MenuBar = ({ activeDesk, desks }: Props) => (
    <header className="header" role="banner">
        <Logo activeDesk={activeDesk} />
        <MainNavigation desk={activeDesk} />
        <DeskSelection activeDesk={activeDesk} desks={desks} />
    </header>
)

export const MenuBarSimple: React.FC = () => (
    <header className="header" role="banner">
        <Logo />
    </header>
)
