import { Dispatch } from 'redux'
import { LOAD_CURATIONS, SET_CURATIONS_ORDER } from 'gazette/constants/action-types'
import { checkStatus } from 'gazette/actions/common'
import {
    GazetteAction,
    LoadCurationsAction,
    ErrorAction,
    SetCurationsOrderAction,
} from 'gazette/types'
import { CurationApi_CurationListingV4 } from '@west-australian-newspapers/publication-types'

const dispatchCuration = (
    desk: string,
    data: CurationApi_CurationListingV4 | Error,
): LoadCurationsAction | ErrorAction => {
    if (data instanceof Error) {
        return {
            type: LOAD_CURATIONS,
            payload: data,
            error: true,
        }
    }

    return {
        type: LOAD_CURATIONS,
        meta: { desk },
        payload: data,
        error: false,
    }
}

export function refreshCurations(api: string, desk: string) {
    return (dispatch: Dispatch<GazetteAction>): Promise<LoadCurationsAction | ErrorAction | void> =>
        fetch(`${api}/v4/curation?page_size=100`, { credentials: 'same-origin' })
            .then(checkStatus)
            .then((resp) => resp.json() as Promise<CurationApi_CurationListingV4>)
            .then((data) => dispatch(dispatchCuration(desk, data)))
            .catch((err) => {
                // tslint:disable-next-line:no-console
                console.warn('Refresh failed', err)
            })
}

const curationOrderStorageKey = (desk: string) => `${desk}-custom-curation-order`
export function saveCurationOrder(desk: string, curationOrder: string[]): SetCurationsOrderAction {
    try {
        localStorage.setItem(curationOrderStorageKey(desk), JSON.stringify(curationOrder))
    } catch (err) {
        // tslint:disable-next-line:no-console
        console.error('Failed to persist curation order', err)
    }
    const action: SetCurationsOrderAction = {
        type: SET_CURATIONS_ORDER,
        desk,
        payload: curationOrder,
    }

    return action
}

export const fetchCurations = (api: string, desk: string) => (
    dispatch: Dispatch<GazetteAction>,
): void => {
    dispatch({
        type: LOAD_CURATIONS,
        meta: {},
        error: false,
    })

    try {
        const curationOrderRaw = localStorage.getItem(curationOrderStorageKey(desk))
        if (curationOrderRaw) {
            const curationOrder: string[] = JSON.parse(curationOrderRaw)
            const action: SetCurationsOrderAction = {
                type: SET_CURATIONS_ORDER,
                desk,
                payload: curationOrder,
            }

            dispatch(action)
        }
    } catch (err) {
        // tslint:disable-next-line:no-console
        console.error('Failed to restore curation order', err)
    }

    fetch(`${api}/v4/curation?page_size=100`, { credentials: 'same-origin' })
        .then(checkStatus)
        .then((resp) => resp.json() as Promise<CurationApi_CurationListingV4>)
        .then((data) => dispatch(dispatchCuration(desk, data)))
        .catch((err) => dispatch(dispatchCuration(desk, err)))
}
