import React from 'react'

export const TheWestIcon: React.FC = () => (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
        <title>Desk Avatar / The West</title>
        <g id="Symbols" fill="none" fillRule="evenodd">
            <g id="Desk-Avatar-/-The-West">
                <path id="The-West-Blue" fill="#043DE2" d="M0 0h64v64H0z" />
                <path
                    d="M57.6386 46.45818l-1.8132 1.79838-3.0999-3.08283.072-17.4384 2.2863-2.2607-.4529-.3905-1.85444 1.80807-4.67614-4.67547-5.2641 5.32334v-5.1894c-.76182.76-2.35908 1.94198-4.0073 1.78788-2.4618-.2364-4.5629-1.36673-7.26168-1.36673-3.16217-.0105-5.3773 1.7056-5.3773 4.2543 0 2.31233 1.1848 3.38055 3.81158 3.39104-2.02913-.7092-2.58554-.95607-2.58554-2.03478 0-.9964 1.03034-1.42806 2.41005-1.41837 2.16338 0 3.6466 1.0586 7.0975 1.0586-10.05345 8.10846-13.0927 14.5718-8.96165 20.07025-4.1723 1.87987-9.39433 1.0787-12.21683-2.70282-3.22445-4.3261-2.85324-10.8516 3.9038-16.7502l-.03074 19.6894 5.882-5.22085.03078-20.7891c-.76263.76084-2.3591 1.9428-4.0073 1.7887-2.4618-.2364-4.5427-1.36672-7.25117-1.36672-3.15247-.0105-5.3773 1.7056-5.3773 4.2543-.01052 2.31153 1.1848 3.38055 3.81077 3.39104-2.02912-.7092-2.58473-.95607-2.58473-2.03478 0-.99723 1.04004-1.41838 2.41005-1.41838 2.16337 0 3.6466 1.05853 7.0975 1.05853C9.5744 36.1004 6.51574 42.54357 10.64597 48.0307l.7416.94558c4.1205 4.86104 12.9277 5.48793 17.32644.03066 4.32596 5.12806 13.87476 5.53873 17.98476-.873l5.03764 5.0353 6.3559-6.32055-.45372-.3905zm-24.58813-1.03756c-3.22444-4.3366-2.85323-10.8516 3.9038-16.7502l-.03074 19.6894 5.89248-5.22086.0203-14.6743 1.32876-1.3054 2.4723 2.46642.01053 17.78857c-4.31625 2.76413-10.4554 2.20905-13.59736-1.99363zM8.86154 14.62602v-3.79525h43.99142V14.626H8.86154z"
                    id="The-West-Mark"
                    fill="#FFF"
                />
            </g>
        </g>
    </svg>
)
