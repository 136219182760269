import React from 'react'

import 'gazette/components/Toolbar/Toolbar.scss'
import classNames from 'classnames'

interface Props {
    children: React.ReactNode
    title: string
    halfWidth?: boolean
}

export const Toolbar: React.FC<Props> = (props) => (
    <section className={classNames('Toolbar', { 'Toolbar--embiggened': props.halfWidth })}>
        <header className="Toolbar--heading">
            <h2>{props.title}</h2>
        </header>
        {props.children}
    </section>
)
