import React from 'react'
import Select from 'react-select'
import { GazetteFieldProps } from './metadata_item'
import { SelectDefinition, SelectOption } from '../data'
import { ValueType } from 'react-select/src/types'

export const MetadataSelect: React.FC<GazetteFieldProps<SelectDefinition>> = ({
    currentValue,
    field,
    updateCurationMetadata,
}) => (
    <Select
        className="collection-metadata__select"
        name={name}
        options={field.options}
        onChange={(select: ValueType<SelectOption>) => {
            if (select && 'value' in select) {
                updateCurationMetadata(select.value)
            }
        }}
        value={field.options.find((option) => option.value === currentValue)}
    />
)

MetadataSelect.displayName = 'MetadataSelect'
