import React from 'react'

export const ExpiredIcon: React.FC = () => (
    <svg width="12" height="16" viewBox="0 0 12 16" xmlns="http://www.w3.org/2000/svg">
        <path
            fill="#000"
            d="M13.25 2a.75.75 0 0 0 .75-.75v-.5a.75.75 0 0 0-.75-.75H2.75A.75.75 0 0 0 2 .75v.5c0 .414.336.75.75.75 0 2.843 1.594 5.242 3.776 6-2.182.758-3.776 3.157-3.776 6a.75.75 0 0 0-.75.75v.5c0 .414.336.75.75.75h10.5a.75.75 0 0 0 .75-.75v-.5a.75.75 0 0 0-.75-.75c0-2.843-1.594-5.242-3.776-6 2.182-.758 3.776-3.157 3.776-6zM8 6.5C6.194 6.5 4.75 4.421 4.75 2h6.5c0 2.436-1.453 4.5-3.25 4.5z"
        />
    </svg>
)
