import { GazetteAction, CurationState } from 'gazette/types'
import { CHECK_CURATION_UPDATED, curationInitialState } from 'gazette/features/manage-curation'

export const checkCurationUpdatedReducer = (
    state = curationInitialState,
    action: GazetteAction,
): CurationState => {
    switch (action.type) {
        case CHECK_CURATION_UPDATED: {
            if (action.error) {
                return {
                    ...state,
                    error: action.payload.message,
                }
            }

            /**
             * If the response is for a different desk or curation id
             * do not mutate the state
             */
            if (state.desk !== action.meta.desk || state.id !== action.meta.id) {
                return state
            }

            if (!action.payload) {
                return state
            }

            return {
                ...state,
                lastCheckedForUpdate: new Date(),
                data: { loading: false, curation: action.payload },
            }
        }
        default:
            return state
    }
}
