import React from 'react'
import classnames from 'classnames'

import { ItemContainer } from 'gazette/components/ItemContainer/ItemContainer'
import { StatusFlag } from 'gazette/components/StatusFlag/StatusFlag'
import { Timestamp } from 'gazette/components/Timestamp/Timestamp'
import { ItemActions } from 'gazette/components/ItemActions/ItemActions'
import { EditIcon } from 'gazette/icons/edit_icon'
import { DeleteIcon } from 'gazette/icons/delete_icon'
import { NewTabIcon } from 'gazette/icons/newtab_icon'
import { Redirect, Desk } from 'gazette/types'
import { ErrorBoundary } from 'gazette/components/ErrorBoundary'
import { LoadingIcon } from 'gazette/icons/loading_icon'

interface Props {
    item: Redirect
    handleEdit: (id: number) => void
    handleDelete: (id: number) => void
    pending?: boolean
    desk: Desk
}

interface State {
    dropdownOpen: boolean
}

export class RedirectItem extends React.Component<Props, State> {
    state = {
        dropdownOpen: false,
    }

    toggleDropdown = () => {
        if (this.state.dropdownOpen) {
            return this.setState({ dropdownOpen: false })
        }

        return this.setState({ dropdownOpen: true })
    }

    handleMouseLeave = () => {
        this.setState({ dropdownOpen: false })
    }

    render() {
        const item = this.props.item
        const isActive = item && item.active
        const isPending = this.props.pending
        const baseUrl = this.props.desk.baseUrl

        return (
            <ErrorBoundary>
                <ItemContainer
                    className={classnames('RedirectItem', isPending && 'RedirectItem--pending')}
                    draggable={false}
                    onMouseLeave={this.handleMouseLeave}
                >
                    <div>
                        <div className="RedirectLink">
                            <h3>Source</h3>
                            <a
                                className="RedirectLink--source"
                                href={`${baseUrl}${item.sourceUrl}`}
                                target="_blank"
                            >
                                {`${baseUrl}${item.sourceUrl}`}
                                <NewTabIcon />
                            </a>
                            <h3>Target</h3>
                            <span className="RedirectLink--target">{item.targetUrl}</span>
                        </div>
                        <div className="RedirectMeta">
                            <StatusFlag
                                text={isActive ? 'Active' : 'Inactive'}
                                status={isActive ? 'positive' : 'negative'}
                            />
                            {item.lastUpdated && `Last modified:`}
                            {item.lastUpdated && <Timestamp dateTime={item.lastUpdated} />}
                        </div>
                    </div>
                    {isPending ? (
                        <LoadingIcon />
                    ) : (
                        <ItemActions
                            isOpen={this.state.dropdownOpen}
                            handleClick={this.toggleDropdown}
                        >
                            <button
                                className="button button--text-icon story__button story__button--copy"
                                type="button"
                                onClick={() => item.id && this.props.handleDelete(item.id)}
                            >
                                <DeleteIcon />
                                Delete
                            </button>

                            <button
                                className="button button--text-icon story__button story__button--copy"
                                type="button"
                                onClick={() => item.id && this.props.handleEdit(item.id)}
                            >
                                <EditIcon />
                                Edit
                            </button>
                        </ItemActions>
                    )}
                </ItemContainer>
            </ErrorBoundary>
        )
    }
}
