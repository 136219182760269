import React from 'react'

interface Props {
    children: React.ReactNode
}

interface State {
    hasError: boolean
}

export class ErrorBoundary extends React.Component<Props, State> {
    static getDerivedStateFromError(error: Error) {
        if (!error) {
            return
        }

        return { hasError: true }
    }

    state = {
        hasError: false,
    }

    componentDidCatch(error: Error, info: any) {
        // tslint:disable-next-line:no-console
        console.error(error, info)
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>
        }

        return this.props.children
    }
}
