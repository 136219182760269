import React from 'react'
import { FutureIcon } from 'gazette/icons/future_icon'
import { DeadIcon } from 'gazette/icons/dead_icon'

interface StoryFeatureProps {
    status: string
    isFuturePublication: boolean
}

export const StoryFeature: React.FC<StoryFeatureProps> = ({ status, isFuturePublication }) => {
    let icon: React.ReactNode
    let label: React.ReactNode

    switch (status.toLowerCase()) {
        case 'live':
            icon = isFuturePublication ? <FutureIcon /> : undefined
            break
        case 'dead':
        case 'killed':
            icon = <DeadIcon />
            label = <p>{status}</p>
            break
        case 'pending':
        case 'held':
            label = <p>{status}</p>
            break
    }

    return (
        <React.Fragment>
            {icon && icon}
            {label && label}
        </React.Fragment>
    )
}
StoryFeature.displayName = 'StoryFeature'
