import {
    RawDraftContentBlock,
    genKey,
    RawDraftEntity,
    RawDraftInlineStyleRange,
    RawDraftEntityRange,
} from 'draft-js'
import { BlockV4DTO, Intention } from '@west-australian-newspapers/publication-types'

interface EntityMap {
    [key: string]: RawDraftEntity
}

export function toDraftJSBlock(
    block: BlockV4DTO,
    getNextEntityMapKey: () => number,
): { blocks: RawDraftContentBlock[]; entityMap: EntityMap } {
    if (block.kind === 'text') {
        const entityMap: EntityMap = {}
        return {
            blocks: [
                {
                    key: genKey(),
                    text: block.text,
                    type: 'paragraph',
                    depth: 0,
                    entityRanges: intentionsToInlineEntityRanges(
                        block.text,
                        block.intentions,
                        getNextEntityMapKey,
                        entityMap,
                    ),
                    inlineStyleRanges: intentionsToInlineStyleRanges(block.text, block.intentions),
                },
            ],
            entityMap,
        }
    }

    if (block.kind === 'ordered-list') {
        const entityMap: EntityMap = {}
        return {
            blocks: block.items.map<RawDraftContentBlock>((item) => ({
                key: genKey(),
                type: 'ordered-list-item',
                text: item.text,
                depth: 0,
                entityRanges: intentionsToInlineEntityRanges(
                    item.text,
                    item.intentions,
                    getNextEntityMapKey,
                    entityMap,
                ),
                inlineStyleRanges: intentionsToInlineStyleRanges(item.text, item.intentions),
            })),
            entityMap,
        }
    }

    if (block.kind === 'unordered-list') {
        const entityMap: EntityMap = {}
        return {
            blocks: block.items.map<RawDraftContentBlock>((item) => ({
                key: genKey(),
                type: 'unordered-list-item',
                text: item.text,
                depth: 0,
                entityRanges: intentionsToInlineEntityRanges(
                    item.text,
                    item.intentions,
                    getNextEntityMapKey,
                    entityMap,
                ),
                inlineStyleRanges: intentionsToInlineStyleRanges(item.text, item.intentions),
            })),
            entityMap,
        }
    }

    if (block.kind === 'embed') {
        const key = getNextEntityMapKey()
        const entityMap: EntityMap = {
            [key]: {
                type: 'EMBED',
                mutability: 'MUTABLE',
                data: {},
            },
        }
        return {
            blocks: [
                {
                    key: genKey(),
                    type: 'EMBED',
                    text: block.reference,
                    depth: 0,
                    entityRanges: [{ key, offset: 0, length: block.reference.length }],
                    inlineStyleRanges: [],
                },
            ],
            entityMap,
        }
    }

    // tslint:disable-next-line:no-console
    console.warn('Unhandled block type', block.kind)

    return {
        blocks: [],
        entityMap: {},
    }
}

function intentionsToInlineStyleRanges(text: string, intentions: Intention[]) {
    const inlineStyles: RawDraftInlineStyleRange[] = []

    intentions.forEach((intention) => {
        const offset = calculateOffset(intention, text)

        if (intention.kind === 'important') {
            inlineStyles.push({
                style: 'BOLD',
                offset,
                length: intention.match.length,
            })
        } else if (intention.kind === 'emphasized') {
            inlineStyles.push({
                style: 'ITALIC',
                offset,
                length: intention.match.length,
            })
        }
    })

    return inlineStyles
}

function intentionsToInlineEntityRanges(
    text: string,
    intentions: Intention[],
    getNextEntityMapKey: () => number,
    entityMap: EntityMap,
) {
    const inlineEntities: RawDraftEntityRange[] = []

    intentions.forEach((intention) => {
        const offset = calculateOffset(intention, text)

        if (intention.kind === 'link') {
            const key = getNextEntityMapKey()
            inlineEntities.push({
                key,
                offset,
                length: intention.match.length,
            })
            entityMap[key] = {
                type: 'LINK',
                mutability: 'MUTABLE',
                data: { url: intention.href },
            }
        }
    })

    return inlineEntities
}

function calculateOffset(intention: Intention, text: string) {
    let offset = 0
    for (let i = 0; i <= intention.index; i++) {
        offset = text.indexOf(intention.match)
    }
    return offset
}
