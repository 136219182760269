import * as React from 'react'
import { useParams, Link } from 'react-router-dom'

import { Post, PostList } from './post-list'

import './view-live-blog.scss'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Blog } from './live-blog'
import { PostEditor } from './post-editor'
import { getBlog } from './api'
import { Desk } from 'gazette/types'

export const ViewLiveBlog: React.FC<{ desk: Desk }> = ({ desk }) => {
    const { blogId } = useParams<{ blogId: string }>()
    const [editingPost, setEditingPost] = React.useState<undefined | Post>()

    const [currentBlog, setCurrentBlog] = React.useState<Blog | null>()

    React.useEffect(() => {
        async function loadBlogs() {
            const result = await getBlog(desk.id, blogId)
            setCurrentBlog(result)
        }

        loadBlogs()
    }, [blogId])

    if (currentBlog === null) {
        return <section className="container">Cannot find blog</section>
    }
    if (!currentBlog) {
        return <section className="container">Loading...</section>
    }

    return (
        <section className="container">
            <div className="live-blog--edit">
                <Link to={`/desk/${desk.id}/live-blogs`}>Back to blog list</Link>

                <h2 className="live-blog__page-title">
                    Editing Blog: <span>{currentBlog.name}</span> (
                    <CopyToClipboard text={`liveblog:${currentBlog.id}`}>
                        <a className="edit-blog--copy">Copy embed link</a>
                    </CopyToClipboard>
                    )
                </h2>
                <div className="live-blog__form-section">
                    <PostEditor
                        blogId={currentBlog.id}
                        editingPost={editingPost}
                        cancelEdit={() => {
                            setEditingPost(undefined)
                        }}
                    />
                </div>

                <div id="posts" className="live-blog__form-section">
                    <PostList editPost={(post) => setEditingPost(post)} blogId={currentBlog.id} />
                </div>
            </div>
            <div>
                {/* <QuestionList
                        questions={questions}
                        blogId={blogId}
                        createPost={async post => {
                            await submitPost(text, blogId)
                            setPosts([post, ...posts])
                        }}
                    /> */}
            </div>
        </section>
    )
}
