import React from 'react'

import { MetadataItem } from './components/metadata_item'

import { curationMetadataDefinitions, FieldDefinition } from './data'

import './curation_metadata.scss'
import { CurationApi_CurationV4ResponseDTO } from '@west-australian-newspapers/publication-types'
import { CurationState, Desk } from 'gazette/types'

interface Props {
    desk: Desk
    curation: CurationState
    updateCurationMetadata: (update: CurationApi_CurationV4ResponseDTO['metadata']) => Promise<any>
}

const CollectionMetadata: React.FC<Props> = ({ curation, updateCurationMetadata, desk }) => {
    /**
     * @param {Array<*>} dataArray Array of metadata FieldDefinitions as specified in ./data.js
     */
    const renderMetadataItems = (dataArray: FieldDefinition[] = []) =>
        dataArray.map((item) => (
            <MetadataItem
                field={item}
                desk={desk}
                key={item.name}
                currentValue={curation.metadata ? curation.metadata[item.name] : undefined}
                updateCurationMetadata={updateCurationMetadata}
            />
        ))

    /**
     * targeted - metadata options applied to a specific curation
     * common - metadata options applied to all curations
     */
    const targetedDesk =
        curationMetadataDefinitions.targeted && curationMetadataDefinitions.targeted[desk.id]
    const targeted = targetedDesk && targetedDesk[curation.id!]
    const { common = [] } = curationMetadataDefinitions

    if (!targeted && !common.length) {
        return null
    }

    return (
        <div className="collection-metadata">
            <h3 className="collection-metadata__title">Curation Options</h3>
            <fieldset className="collection-metadata__fieldset">
                {renderMetadataItems(targeted)}
                {renderMetadataItems(common)}
            </fieldset>
        </div>
    )
}

export default CollectionMetadata
