import React from 'react'
import './alertBanner.scss'

type Level = 'information' | 'warning' | 'error'

interface Props {
    level: Level
    text: string
    icon?: string // TODO
}

const getThemeFromLevel = (level: Level) => {
    switch (level) {
        case 'information':
            return 'theme-info'

        case 'warning':
            return 'theme-warning'

        case 'error':
            return 'theme-error'

        default:
            throw new Error('invalid level provided')
    }
}

const AlertBanner: React.FC<Props> = (props) => {
    return (
        <div className="alert-banner">
            <div className={'message ' + getThemeFromLevel(props.level)}>{props.text}</div>
        </div>
    )
}

export default AlertBanner
