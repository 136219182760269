import React from 'react'
import CloseIcon from 'gazette/icons/close_icon'

interface Props {
    handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const ItemRemoveButton: React.FC<Props> = (props) => {
    return (
        <button
            className="button button--icon story__button story__button--remove"
            onClick={props.handleClick}
            type="button"
        >
            <CloseIcon />
            <span className="sr-only">Close</span>
        </button>
    )
}
ItemRemoveButton.displayName = 'ItemRemoveButton'
