import { LOAD_STORIES, CLEAR_STORIES } from 'gazette/features/search'
import { StoriesState, GazetteAction } from 'gazette/types'

const defaultState: StoriesState = { list: [], loaded: false, error: null, loading: false }

/**
 * Reducer to load articles into the state
 * @param  {array}  [state={ list: [], error: null }] - Existing state
 * @param  {string} action - Action object passed in from actions
 * @return {object} Article or error object for state
 */
export default (state = defaultState, action: GazetteAction): StoriesState => {
    switch (action.type) {
        case CLEAR_STORIES:
            return defaultState
        case LOAD_STORIES:
            if (action.error) {
                return {
                    ...state,
                    error: action.payload && action.payload.message,
                    loaded: false,
                    loading: false,
                }
            }

            if (!action.payload) {
                return {
                    ...state,
                    loaded: false,
                    loading: true,
                    list: [],
                    total: undefined,
                    page_size: undefined,
                    page: undefined,
                }
            }

            return {
                ...state,
                error: null,
                loaded: true,
                loading: false,
                list: action.payload.documents,
                total: action.payload.total,
                page_size: action.payload.page_size,
                page: action.payload.page,
            }
        default:
            return state
    }
}
