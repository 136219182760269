import React from 'react'
import { Desk, Redirect, GazetteState } from 'gazette/types'

import './manage-redirects.scss'

import {
    editRedirect,
    newRedirect,
    fetchRedirects,
    deleteRedirect,
    RedirectActions,
} from './components/EditRedirect/EditRedirect.redux'
import { Toolbar } from 'gazette/components/Toolbar/Toolbar'
import { EditRedirect } from 'gazette/features/manage-redirects/components/EditRedirect/EditRedirect'
import { RedirectItem } from 'gazette/features/manage-redirects/components/RedirectItem/RedirectItem'
import { connect } from 'react-redux'
import PublishIcon from 'gazette/features/manage-curation/save_curation/components/publish_icon'
import { ErrorBoundary } from 'gazette/components/ErrorBoundary'
import { ThunkDispatch } from 'redux-thunk'

interface InternalProps {
    desk: Desk
}

interface StateProps {
    toolbarIsOpen: boolean
    editMode: boolean
    redirects: Redirect[]
    loadingRedirects: boolean
}

interface DispatchProps {
    editRedirect: any
    newRedirect: any
    deleteRedirect: any
    fetchRedirects: any
}

type Props = InternalProps & DispatchProps & StateProps

export class InternalManageRedirects extends React.Component<Props> {
    handleDelete = (id: number) => {
        this.props.deleteRedirect(this.props.desk.api, id)
    }

    handleEdit = (id: number) => {
        this.props.editRedirect(id)
    }

    componentDidMount() {
        this.props.fetchRedirects(this.props.desk.api)
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.desk !== nextProps.desk) {
            nextProps.fetchRedirects(this.props.desk.api)
        }
    }

    render() {
        const toolbarTitle = this.props.editMode ? 'Edit Redirect' : 'Add New Redirect'
        const isLoading = this.props.loadingRedirects
        const redirects = this.props.redirects
        const noRedirects = !isLoading && redirects.length <= 0

        return (
            <ErrorBoundary>
                <React.Fragment>
                    <section className="collection container">
                        <header className="ManageRedirects__header">
                            <h2 className="ManageRedirects__header--title">
                                Redirects | <span>{this.props.desk.baseUrl}</span>
                            </h2>
                            <button
                                className="button button--generic button--small"
                                onClick={this.props.newRedirect}
                            >
                                <PublishIcon />
                                New Redirect
                            </button>
                        </header>
                        <ol className="RedirectList">
                            {isLoading && <div>Loading...</div>}

                            {noRedirects && <div>No redirects available</div>}

                            {!isLoading &&
                                redirects.map((item, i) => {
                                    return (
                                        <li
                                            className="RedirectList__item"
                                            key={item.id || `pending-${i}`}
                                        >
                                            <RedirectItem
                                                desk={this.props.desk}
                                                pending={item.pending}
                                                item={item}
                                                handleEdit={() =>
                                                    item.id && this.handleEdit(item.id)
                                                }
                                                handleDelete={() =>
                                                    item.id && this.handleDelete(item.id)
                                                }
                                            />
                                        </li>
                                    )
                                })}
                        </ol>
                    </section>
                    {this.props.toolbarIsOpen && (
                        <Toolbar title={toolbarTitle}>
                            <EditRedirect desk={this.props.desk} />
                        </Toolbar>
                    )}
                </React.Fragment>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = (state: GazetteState) => ({
    toolbarIsOpen: state.redirects.editing.drawerOpen,
    editMode: state.redirects.editMode,
    redirects: state.redirects.items,
    loadingRedirects: state.redirects.loading,
})

const mapDispatchToProps = (
    dispatch: ThunkDispatch<GazetteState, RedirectActions, RedirectActions>,
) => ({
    editRedirect: (item: string) => dispatch(editRedirect(item)),
    newRedirect: () => dispatch(newRedirect()),
    deleteRedirect: (api: string, item: string) => dispatch(deleteRedirect(api, item)),
    fetchRedirects: (api: string) => dispatch(fetchRedirects(api)),
})

export const ManageRedirects = connect<StateProps, DispatchProps, InternalProps, GazetteState>(
    mapStateToProps,
    mapDispatchToProps,
)(InternalManageRedirects)
