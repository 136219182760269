import * as React from 'react'
import { MenuBarSimple } from '../components/menu_bar/menu_bar'
import AlertBanner from '../components/AlertBanner'

import IconSave from './components/IconSave'
import IconClose from './components/IconClose'

import { ArticleV4DTO } from '@west-australian-newspapers/publication-types'

import DESK_STATE from 'gazette/constants/desks'
import { checkStatus, findById } from 'gazette/actions/common'
import { ContentEditorWrapper } from './components/content-editor'
import { Desk } from 'gazette/types'

interface Props {
    params: {
        desk: string
        id: string
    }
}

interface State {
    data?: ArticleV4DTO
    error: string | null
    success: string | null
    activeDesk: Desk | undefined
}

const ERROR_NO_ACTIVE_DESK = 'Could not retrieve the active Desk'
// const NO_CHANGE_REASON_SPECIFIED = "Please provide a reason for changing the Publication's content"

export class EditContentPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        const defaultDesk = findById(DESK_STATE.desks, DESK_STATE.defaultDesk) as Desk

        const activeDesk =
            this.props.params && this.props.params.desk
                ? findById(DESK_STATE.desks, this.props.params.desk)
                : defaultDesk

        this.state = { error: null, success: null, activeDesk }
    }

    componentDidMount() {
        this.getPublicationData()
    }

    getPublicationData() {
        const activeDesk = this.state.activeDesk
        if (activeDesk) {
            fetch(
                `${activeDesk.api}/v4/publication/${this.props.params.id}?includeFuture=true&includeDead=true`,
                { credentials: 'same-origin' },
            )
                .then(checkStatus)
                .then((resp) => resp.json() as Promise<ArticleV4DTO>)
                .then((data) => {
                    this.setState({ data })
                })
                .catch((error) => {
                    this.setState({ error })
                    // tslint:disable-next-line:no-console
                    console.error(error)
                })
        } else {
            this.setState({ error: ERROR_NO_ACTIVE_DESK })
        }
    }

    savePublication() {
        // TODO Going to not add save right now until we have tested some more
    }

    getBanner() {
        let banner = null

        // an error occured, return straight away
        if (this.state.error) {
            return <AlertBanner level="error" text={this.state.error} />
        }

        // if no errors are present show the warning message
        if (
            this.state.data &&
            this.state.error === null &&
            Date.parse(this.state.data.publicationDate) > Date.now() - 14 * 24 * 60 * 60 * 1000
        ) {
            banner = (
                <AlertBanner
                    level="warning"
                    text={
                        'This article was first published within the last 14 days. We recommend checking in NewsGate before editing this article in Gazette.'
                    }
                />
            )
        }

        // show the success message if no errors are present
        if (this.state.error === null && this.state.success) {
            banner = <AlertBanner level="information" text={this.state.success} />
        }
        return banner
    }

    render() {
        if (!this.state.data) {
            return <div>Loading data...</div>
        }

        return (
            <main>
                {this.getBanner()}
                <MenuBarSimple />
                <div className="editor__container">
                    <h1 className="editor__title">
                        Edit Article: {this.state.data.homepageHead} ({this.state.data.id})
                    </h1>

                    <ContentEditorWrapper article={this.state.data} />
                    <div>
                        <button type="submit" className="button button--small button--success">
                            <IconSave />
                            Save Changes
                        </button>
                        <button
                            type="button"
                            className="button button--small button--keyline"
                            onClick={() => {
                                window.close()
                            }}
                        >
                            <IconClose /> Close Editor
                        </button>
                    </div>
                </div>
            </main>
        )
    }
}
