import React from 'react'
import classnames from 'classnames'

import 'gazette/components/StatusFlag/StatusFlag.scss'

export interface StatusFlagProps {
    text: string
    status?: 'positive' | 'negative' | 'premium' | undefined
}

export const StatusFlag: React.FC<StatusFlagProps> = ({ text, status }) => {
    const classes = classnames('StatusFlag', `status-${status}`)
    return <div className={classes}>{text}</div>
}
StatusFlag.displayName = 'StatusFlag'
