import React from 'react'
import { Switch } from 'react-router'
import { Route, Redirect } from 'react-router-dom'
import Markdown from 'react-remarkable'
import Editor from '../../editor/editor'
import Welcome from '../../components/welcome/welcome'
import { Edm } from '../../email-direct-marketing/EmailDirectMarketing'
import Desk from '../../components/desk'
import { EditContentPage } from '../../edit-content-page/edit-content-page'
import DESK_STATE from '../../constants/desks'
import searchHelpText from '../../help/search.md'
import uploadHelpText from '../../help/search.md'

import './curation_tool.scss'

/* eslint-disable @typescript-eslint/no-var-requires */
const SearchHelp = () => <Markdown source={searchHelpText} />
const UploadHelp = () => <Markdown source={uploadHelpText} />

export const CurationTool = () => (
    <Switch>
        <Route path="/help/search" component={SearchHelp} />
        <Route path="/help/upload" component={UploadHelp} />

        <Route path="/desk/:desk/edm/:curationid" render={({ match }) => <Edm {...match} />} />

        <Route
            path="/desk/:desk/edit/content/:id"
            render={({ match }) => <EditContentPage {...match} />}
        />
        <Route path="/desk/:desk/edit/:id" render={({ match }) => <Editor {...match} />} />
        <Route path="/desk/:desk" render={({ match }) => <Desk {...match} />} />

        <Route exact path="/" render={() => <Redirect to={`/desk/${DESK_STATE.defaultDesk}`} />} />
        <Route exact path="/welcome" render={() => <Welcome />} />
    </Switch>
)

export default CurationTool
