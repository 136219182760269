import { TopicsState, GazetteAction } from 'gazette/types'
import { LOAD_TOPICS } from 'gazette/features/search'

const defaultState: TopicsState = { list: [], loaded: false, error: null }

export default (state = defaultState, action: GazetteAction): TopicsState => {
    switch (action.type) {
        case LOAD_TOPICS:
            if (action.error) {
                return {
                    ...state,
                    error: action.payload.message,
                }
            }

            if (!action.payload) {
                return {
                    ...state,
                    list: [],
                    loaded: false,
                }
            }

            return {
                ...state,
                error: null,
                loaded: true,
                list: action.payload,
            }
        default:
            return state
    }
}
