import { LOAD_CURATIONS, SET_CURATIONS_ORDER } from 'gazette/constants/action-types'
import { CurationsState, GazetteAction } from 'gazette/types'
import {
    SAVE_CURATION_DONE,
    CHECK_CURATION_UPDATED,
    LOAD_CURATION_DONE,
} from 'gazette/features/manage-curation'
import { CurationV4InfoDTO } from '@west-australian-newspapers/publication-types'

const curationsInitialState: CurationsState = {
    error: null,
    order: {},
}

const compareAlphabet = (curationOne: CurationV4InfoDTO, curationTwo: CurationV4InfoDTO) => {
    const curationOneText = curationOne.title.toLowerCase()
    const curationTwoText = curationTwo.title.toLowerCase()

    if (curationOneText < curationTwoText) {
        return -1
    }
    if (curationOneText > curationTwoText) {
        return 1
    }
    return 0
}

export function sortCurations(
    curations: CurationV4InfoDTO[],
    order: string[] | undefined,
): CurationV4InfoDTO[] {
    const initialSort = curations.sort(compareAlphabet)
    if (order) {
        // If the curation is missing in the saved ordering, just put it first (happens for new curations)
        const missingInOrdering = initialSort.filter((val) => order.indexOf(val.id) === -1)
        const specifiedInOrder = order
            .filter((orderId) => curations.find((curation) => orderId === curation.id))
            .map((id) => curations.find((curation) => curation.id === id))
            .filter((item) => !isUndefined(item)) as CurationV4InfoDTO[]

        return [...missingInOrdering, ...specifiedInOrder]
    }

    return initialSort
}

function isUndefined<T>(item: any): item is T {
    return item === undefined
}

export default (state = curationsInitialState, action: GazetteAction): CurationsState => {
    switch (action.type) {
        case CHECK_CURATION_UPDATED:
        case SAVE_CURATION_DONE:
        case LOAD_CURATION_DONE: {
            if (!action.error && state.data && !state.data.loading && action.payload) {
                const payload = action.payload
                return {
                    ...state,
                    data: {
                        ...state.data,
                        list: state.data.list.map((curation) => {
                            if (curation.id === payload.id) {
                                return payload
                            }
                            return curation
                        }),
                    },
                }
            }

            return state
        }
        case LOAD_CURATIONS: {
            if (action.error) {
                return {
                    ...state,
                    error: action.payload.message,
                }
            }

            const meta = action.meta || {}
            if (!action.payload) {
                return {
                    ...state,
                    desk: meta.desk,
                    data: undefined,
                }
            }
            const deskOrder = action.meta.desk ? state.order[action.meta.desk] : undefined
            const sortedCurations = sortCurations(action.payload.documents, deskOrder)

            return {
                ...state,
                desk: meta.desk,
                error: null,
                data: { loading: false, list: sortedCurations, total: action.payload.total },
            }
        }
        case SET_CURATIONS_ORDER: {
            return {
                ...state,
                data:
                    state.data && !state.data.loading
                        ? { ...state.data, list: sortCurations(state.data.list, action.payload) }
                        : state.data,
                order: {
                    ...state.order,
                    [action.desk]: action.payload,
                },
            }
        }
        default:
            return state
    }
}
