import React from 'react'
import { CollectionSlot } from '../../curation_slot/curation_slot'
import { GazetteFieldProps } from './metadata_item'
import { StoryPlaceholderDefinition } from '../data'
import { ListPublicationV4DTO } from '@west-australian-newspapers/publication-types'

export function serialiseCurationStory(this: ListPublicationV4DTO) {
    return { id: this.id, type: 'story' }
}

export const MetadataStory: React.FC<GazetteFieldProps<StoryPlaceholderDefinition>> = ({
    currentValue,
    desk,
    updateCurationMetadata,
}) => (
    <CollectionSlot
        index={0}
        item={currentValue}
        desk={desk}
        removeCurationItem={() => updateCurationMetadata(undefined)}
        updateCuration={(update) =>
            updateCurationMetadata({
                ...update.article,
                toJSON: serialiseCurationStory,
            })
        }
    />
)

MetadataStory.displayName = 'MetadataStory'
