import React from 'react'
import { GazetteFieldProps } from './metadata_item'
import { TextDefinition } from '../data'

export const MetadataText: React.FC<GazetteFieldProps<TextDefinition>> = ({
    field,
    currentValue,
    updateCurationMetadata,
}) => {
    const isValid = !field.validation || new RegExp(field.validation).test(currentValue)

    return (
        <React.Fragment>
            <input
                className="form__input form__input--text"
                type="text"
                name={name}
                id={name}
                onChange={(e) => {
                    updateCurationMetadata(e.target.value)
                }}
                value={currentValue}
            />
            {!isValid && (
                <label className="form__label form__label--error">
                    {field.invalidMessage || 'Invalid value'}
                </label>
            )}
        </React.Fragment>
    )
}
