import React from 'react'
import format from 'date-fns/format'

import 'gazette/components/Timestamp/Timestamp.scss'

interface TimestampProps {
    dateTime: string
}

export const Timestamp: React.FC<TimestampProps> = ({ dateTime }) => (
    <time className="Timestamp" dateTime={dateTime}>
        {format(new Date(dateTime), 'd MMMM yyyy, hh:mma')}
    </time>
)
Timestamp.displayName = 'Timestamp'
