import { EditorState, RichUtils } from 'draft-js'
import React from 'react'

const INLINE_CONTROLS: Array<{ value: string; style: string }> = [
    { value: 'Bold', style: 'BOLD' },
    { value: 'Italic', style: 'ITALIC' },
]

// Why onMouseDown and not onClick?
// Because we don’t want the Draft editor to lose its focus, so by listening onMouseDown and
// calling event.preventDefault() we stop that event from bubbling up and causing the editor to
// lose focus.

export const InlineControls: React.FC<{
    editorState: EditorState
    updateState: (state: EditorState) => void
}> = ({ editorState, updateState }) => {
    const currentStyle = editorState.getCurrentInlineStyle()
    return (
        <div className="inline-controls">
            {INLINE_CONTROLS.map((control) => (
                <button
                    type="button"
                    className={
                        currentStyle.has(control.style)
                            ? 'RichEditor-styleButton RichEditor-styleButton--activeButton'
                            : 'RichEditor-styleButton'
                    }
                    key={control.style}
                    onMouseDown={(event) => {
                        updateState(RichUtils.toggleInlineStyle(editorState, control.style))
                        event.preventDefault()
                    }}
                    value={control.value}
                >
                    {control.value}
                </button>
            ))}
        </div>
    )
}
