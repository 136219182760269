import React from 'react'
import classnames from 'classnames'
import * as imgPlaceholder from 'gazette/images/img-placeholder.png'
import { FutureIcon } from 'gazette/icons/future_icon'
import { DeadIcon } from 'gazette/icons/dead_icon'
import { SponsoredIcon } from 'gazette/icons/sponsored_icon'
import { ItemContainer } from 'gazette/components/ItemContainer/ItemContainer'
import { Desk } from 'gazette/types'

import './edm_story.scss'
import { CopyToClipboardStateful } from 'gazette/components/copy-to-clipboard'
import { ListArticleV4DTO, ImageV4DTO } from '@west-australian-newspapers/publication-types'
import { getPreferredImage } from 'gazette/utils/pick-image'
import { IconPremium } from 'gazette/icons/premium_icon'

interface Props {
    className?: string
    desk: Desk
    publication: ListArticleV4DTO
    hasCopied: boolean
}

const statusMessage = (text: string) => <p>{text}</p>

const getFeatures = (
    statusString: string,
    isFuturePublication: boolean,
): { icon: Record<string, any> | null; text: Record<string, any> | null } => {
    const empty = { icon: null, text: null }
    switch (statusString.toLowerCase()) {
        case 'live':
            return isFuturePublication ? { icon: <FutureIcon />, text: null } : empty
        case 'dead':
        case 'killed':
            return { icon: <DeadIcon />, text: statusMessage(statusString) }
        case 'pending':
        case 'held':
            return { icon: null, text: statusMessage(statusString) }
        default:
            return empty
    }
}

export const EdmStory: React.FC<Props> = (props) => {
    const { className } = props

    const {
        heading,
        homepageTeaser,
        homepageHead,
        publicationDate,
        status,
        isSponsored,
        headKicker,
    } = props.publication
    const link = props.publication._self

    const images = getImages(props.publication)
    const mainImage = getPreferredImage(
        [
            { width: 16, height: 9 },
            { width: 13, height: 8 },
        ],
        ...images,
    )

    const displayImage = mainImage || { src: imgPlaceholder }
    const isLive = status === 'live'
    const isFuturePublication = new Date(publicationDate).valueOf() > Date.now()
    const isPremium = props.publication.kind === 'article' && props.publication.isPremium

    const features = getFeatures(status, isFuturePublication)

    const storyImage = (
        <div className="story__image-container">
            <img className="story__image" alt="" draggable={false} src={displayImage.src} />
            <CopyToClipboardStateful text={displayImage.src + '?imwidth=800'} />
        </div>
    )

    return (
        <ItemContainer
            className={classnames(className, {
                'story--dead': !isLive,
                'story--future': isFuturePublication,
                'story--premium': isPremium,
            })}
            draggable={false}
        >
            {storyImage}
            <div>
                {isPremium && (
                    <span className="story__premium-label">
                        <IconPremium width={20} height={20} /> Premium
                    </span>
                )}
                <div>
                    {headKicker && <CopyToClipboardStateful text={headKicker} />}
                    <span className="story__kicker">{headKicker}</span>
                </div>
                <h2 className="story__title">
                    <CopyToClipboardStateful text={heading} />
                    {heading}
                </h2>

                <div>
                    {homepageTeaser && <CopyToClipboardStateful text={homepageTeaser} />}
                    {homepageTeaser}
                </div>

                <div>
                    {homepageHead && <CopyToClipboardStateful text={homepageHead} />}
                    {homepageHead}
                </div>

                <div>
                    <CopyToClipboardStateful text={link} />
                    <a href={link} target="_blank" rel="noreferrer">
                        {link}
                    </a>
                </div>

                {(features.text || isSponsored) && (
                    <div className="story__metadata">
                        {features.text && (
                            <div className="story__published-date">
                                {features.icon}
                                {features.text}
                            </div>
                        )}
                        {isSponsored && (
                            <div className="story__sponsored-wrapper">
                                <SponsoredIcon />
                                <strong>Sponsored</strong>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </ItemContainer>
    )
}

function getImages(publication: ListArticleV4DTO): ImageV4DTO[][] {
    if (publication.kind === 'article') {
        return [publication.cardImages, publication.mainImages]
    }

    return []
}
