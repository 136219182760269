import React from 'react'
import { InlineNotification, Notification } from 'react-redux-notifications'
import classnames from 'classnames'
import format from 'date-fns/format'

import { getHoursFromPresent } from '../../../utils/get-hours-from-present'

import { CollectionSlotList } from '../curation_slot_list/curation_slot_list'

import {
    Desk,
    CurationState,
    UpdateCurationActionParam,
    SaveCurationDoneAction,
    ErrorAction,
} from 'gazette/types'

import './curation.scss'
import { ClockIcon } from './components/clock_icon'
import { SAVE_CURATION_DONE } from 'gazette/features/manage-curation'
import { CurationApi_CurationV4ResponseDTO } from '@west-australian-newspapers/publication-types'
import { SaveCuration } from '../save_curation/save_curation'
import { ItemRemoveButton } from 'gazette/components/ItemContainer/components/ItemRemoveButton'
import { RemoveAfter } from 'gazette/components/RemoveAfter/RemoveAfter'

interface Props {
    desk: Desk
    curation: CurationState
    removeCurationItem: (id: string) => Promise<any>
    section?: string
    updateCuration: (update: UpdateCurationActionParam) => Promise<any>
    updateCurationMetadata: (update: CurationApi_CurationV4ResponseDTO['metadata']) => Promise<any>
}

// TODO If collection.error is set we need to show an error
export const Curation: React.FC<Props> = (props) => {
    const {
        curation: curationState,
        section,
        removeCurationItem,
        updateCuration,
        updateCurationMetadata,
        desk,
    } = props

    const curation =
        curationState.data && !curationState.data.loading ? curationState.data.curation : undefined

    const isLatest = curation && curation.lastUpdated === curationState.lastUpdatedWhenLoaded

    const lastUpdated = curation ? curation.lastUpdated : new Date().toISOString()

    const hoursDiff = getHoursFromPresent(lastUpdated)
    const isOutdated = hoursDiff >= 2

    const curationLatestClasses = classnames({
        collection__latest: true,
        'collection__latest-false': !isLatest,
    })

    // Convert updated timestamp to format HH:MM:SS
    const lastCheckDateHuman = curationState.lastCheckedForUpdate
        ? format(new Date(curationState.lastCheckedForUpdate), 'HH:mm:ss')
        : '-'

    const curationLastChecked = (
        <div className={curationLatestClasses}>
            {isLatest ? (
                <React.Fragment>
                    Last checked: <div className={'curation__emphasise'}>{lastCheckDateHuman}</div>
                </React.Fragment>
            ) : (
                <strong className="curation--outdated">
                    Curation is out of date, reset the curation for updates
                </strong>
            )}
        </div>
    )

    const publishOutdateNote = (
        <div className="collection__latest">
            {'| Published: '}
            <time dateTime={new Date(lastUpdated).toISOString()} className="curation__emphasise">
                {format(new Date(lastUpdated), 'E, d MMM yyyy HH:mm:ss')}
            </time>
            {isOutdated ? ` (outdated by ${hoursDiff} hours)` : null}
        </div>
    )

    const minStories = curation && (
        <div className="collection__latest">
            {'| Minimum stories: '}
            <div className="curation__emphasise">{curation.minimumItems}</div>
        </div>
    )

    return (
        <section className="collection curation" data-outdated={isOutdated}>
            <header className="collection__container">
                <h1 className="collection__heading">{curation && curation.title}</h1>

                <a
                    href={`/desk/${desk.id}/edm/${curation && curation.id}`}
                    target="_blank"
                    rel="noreferrer"
                    className="button button--keyline button--small"
                >
                    Go to EDM helper tool
                </a>
            </header>

            <div className="collection__header">
                <div className="curation__info">
                    <ClockIcon />
                    {curationLastChecked}
                    {publishOutdateNote}
                    {minStories}
                </div>
            </div>
            <div className="collection__curation-info">
                <p className="lead">
                    Drag articles from search results into collection slot provided in order
                    required.
                </p>
                {curation && curation.curationNotes && (
                    <p className="notes">{curation.curationNotes}</p>
                )}
                <CollectionSlotList
                    desk={desk}
                    section={section}
                    curation={curationState}
                    updateCuration={updateCuration}
                    updateCurationMetadata={updateCurationMetadata}
                    removeCurationItem={removeCurationItem}
                />
            </div>
            <div className="collection__save-curation-container">
                <SaveCuration desk={desk} />
            </div>

            <InlineNotification
                triggeredBy={SAVE_CURATION_DONE}
                showDismiss={true}
                renderNotification={({ key, trigger }: Notification, dismiss: any) => {
                    const action: SaveCurationDoneAction | ErrorAction = trigger as any

                    const isError = action.error
                    return isError ? (
                        <div
                            key={key}
                            className="collection__publish-notification collection__publish-notification__failed"
                        >
                            {isError ? (
                                `Publish failed: ${action.payload}`
                            ) : (
                                <React.Fragment>
                                    <strong>Success:</strong> Curation updated
                                </React.Fragment>
                            )}
                            <ItemRemoveButton handleClick={dismiss} />
                        </div>
                    ) : (
                        <RemoveAfter key={key} delay={2500}>
                            <div className="collection__publish-notification collection__publish-notification__success">
                                <strong>Success:</strong> Curation updated
                                <ItemRemoveButton handleClick={dismiss} />
                            </div>
                        </RemoveAfter>
                    )
                }}
                renderContainer={(notifications: Array<React.ReactElement<any>>) => (
                    <div className="collection__publish-notification-container">
                        {notifications}
                    </div>
                )}
            />
        </section>
    )
}
Curation.displayName = 'Curation'
