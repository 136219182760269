import React from 'react'
import { Link } from 'react-router-dom'

import './logo.scss'
import { Desk } from 'gazette/types'

interface LogoProps {
    activeDesk?: Desk
}

export const Logo = ({ activeDesk }: LogoProps) => (
    <Link className="logo" to={activeDesk ? `/desk/${activeDesk.id}` : '/'}>
        Gazette
    </Link>
)
