import React from 'react'
import classnames from 'classnames'

import { StoryDropdown } from 'gazette/components/story/components/story_dropdown/story_dropdown'
import VideoIcon from 'gazette/icons/video_icon'
import { ExpiredIcon } from 'gazette/icons/expired_icon'
import { StatusFlag } from 'gazette/components/StatusFlag/StatusFlag'
import { ItemRemoveButton } from 'gazette/components/ItemContainer/components/ItemRemoveButton'
import { ItemActions } from 'gazette/components/ItemActions/ItemActions'
import { Timestamp } from 'gazette/components/Timestamp/Timestamp'
import { ItemContainer } from 'gazette/components/ItemContainer/ItemContainer'
import {
    ListPublicationV4DTO,
    ListArticleV4DTO,
} from '@west-australian-newspapers/publication-types'
import { KilledPublicationV4DTO } from '@west-australian-newspapers/publication-types'
import { Desk } from 'gazette/types'

import './story.scss'
import { StoryFeature } from './components/StoryFeature'
import { StoryImage } from './components/StoryImage'
import { ClassificationLabel } from './components/ClassificationLabel/ClassificationLabel'
import { isCueArticle } from 'gazette/actions/cue/isCueArticle'

interface Props {
    className?: string
    desk: Desk
    publication: ListPublicationV4DTO | KilledPublicationV4DTO
    enableDragDrop: boolean
    removeStory?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>
    miniMode?: boolean
}

export const Story: React.FC<Props> = ({
    className,
    desk,
    publication,
    enableDragDrop,
    removeStory,
    miniMode,
}) => {
    const [copied, setCopied] = React.useState(false)
    const [dropdownOpen, setDropdownOpen] = React.useState(false)

    const copyIdToClipboard = () => {
        setCopied(true)
        setTimeout(() => setCopied(false), 1000)
    }

    const handleDragStart: React.EventHandler<React.DragEvent<HTMLDivElement>> = (e) => {
        setDropdownOpen(false)
        e.dataTransfer.dropEffect = 'copy'
        e.dataTransfer.setData('text/plain', JSON.stringify(publication))
    }

    const toggleDropdown = () => setDropdownOpen((val) => !val)

    const handleMouseLeave = () => setDropdownOpen(false)

    const hasVideo = getHasVideo(publication)
    let hasCustomLayout = false
    let kindLabel: string = publication.kind

    if (
        isArticle(publication) &&
        typeof publication.layoutView !== 'undefined' &&
        publication.layoutView !== 'default'
    ) {
        hasCustomLayout = true
        kindLabel = publication.layoutView
    }

    let link
    if (isLivePublication(publication)) {
        const topicParam = publication.topics.primary.id
            .toLowerCase()
            .replace(/\s+/g, '-')
            .split('&')
            .join('and')
        link = `${desk.baseUrl}/${topicParam}/${publication.slug}`
    }

    const isFuturePublication = new Date(publication.publicationDate).valueOf() > Date.now()
    const isExpiringPublication = isLivePublication(publication) && !!publication.expiryDate
    const isExpiredPublication =
        isLivePublication(publication) && publication.expiryDate
            ? new Date(publication.expiryDate).valueOf() < Date.now()
            : false
    const isPremium = isArticle(publication) && publication.isPremium
    const isSponsored = isArticle(publication) && publication.isSponsored
    const isCueSource = isCueArticle(publication.id)

    const canEditContent =
        publication.kind === 'article' || publication.kind === 'gallery' || isCueSource

    return (
        <ItemContainer
            className={classnames(className, {
                'story--dead': !isLivePublication(publication),
                'story--future': isFuturePublication,
                'story--premium': isPremium,
                'story--expiring': isExpiringPublication,
                'story--expired': isExpiredPublication,
            })}
            draggable={enableDragDrop}
            onDragStart={handleDragStart}
            onMouseLeave={handleMouseLeave}
        >
            <ItemRemoveButton handleClick={removeStory} />

            <ItemActions isOpen={dropdownOpen} handleClick={toggleDropdown}>
                <StoryDropdown
                    className="dropdown--story"
                    id={publication.id}
                    desk={desk.id}
                    link={link}
                    onCopy={copyIdToClipboard}
                    hasCopied={copied}
                    canEditContent={canEditContent}
                />
            </ItemActions>

            <StoryImage
                miniMode={miniMode}
                images={isLivePublication(publication) ? publication.mainImages : []}
                label={kindLabel}
                status={hasCustomLayout ? 'premium' : undefined}
                draggable={enableDragDrop}
            />

            <div>
                <div className="story__source">
                    {isLivePublication(publication) ? publication.source : ''}
                </div>
                <h2 className="story__title">
                    {hasVideo && <VideoIcon />}
                    {isLivePublication(publication) ? publication.heading : publication.slug}
                </h2>

                <div className="story__metadata">
                    {/* {isPremium && (
                        <span className="story__premium-label">
                            <IconPremium width={20} height={20} /> Premium
                        </span>
                    )} */}
                    <div className="story__published-date">
                        <StoryFeature
                            status={publication.status}
                            isFuturePublication={isFuturePublication}
                        />
                        <Timestamp dateTime={publication.publicationDate} />
                        {isLivePublication(publication) && publication.expiryDate && (
                            <React.Fragment>
                                <ExpiredIcon />
                                <Timestamp dateTime={publication.expiryDate} />
                            </React.Fragment>
                        )}
                        {!miniMode && (
                            <React.Fragment>
                                {' '}
                                <StatusFlag
                                    text={kindLabel}
                                    status={hasCustomLayout ? 'premium' : undefined}
                                />
                            </React.Fragment>
                        )}
                    </div>

                    {isLivePublication(publication) && isArticle(publication) && (
                        <ClassificationLabel
                            isSponsored={isSponsored}
                            classification={publication.classification}
                        />
                    )}
                </div>
            </div>
        </ItemContainer>
    )
}
Story.displayName = 'Story'

function getHasVideo(publication: ListPublicationV4DTO | KilledPublicationV4DTO): boolean {
    if (publication.status !== 'live') {
        return false
    }

    if (!isArticle(publication)) {
        return false
    }

    if (!publication.mainVideo) {
        return false
    }

    // its not a 'missing-video'
    if (publication.mainVideo.kind !== 'video') {
        return false
    }

    return !!publication.mainVideo.videoId
}

function isArticle(
    publication: ListPublicationV4DTO | KilledPublicationV4DTO,
): publication is ListArticleV4DTO {
    return publication.kind === 'article'
}

function isLivePublication(
    publication: ListPublicationV4DTO | KilledPublicationV4DTO,
): publication is ListPublicationV4DTO {
    return publication.status === 'live'
}
