import { ImageV4DTO } from '@west-australian-newspapers/publication-types'

export interface Ratio {
    width: number
    height: number
}

export interface ImageSelection {
    image: ImageV4DTO
    isFallback: boolean
}

export function getPreferredImage(
    preferredRatios: Ratio[],
    ...imageSets: ImageV4DTO[][]
): ImageV4DTO | undefined {
    for (const imageSet of imageSets) {
        const pickedImage = pickImage(preferredRatios, imageSet)
        if (pickedImage && !pickedImage.isFallback) {
            return pickedImage.image
        }
    }

    return undefined
}

/**
 * We will accept a prioritised list of ratios
 * We will return the first precise match or we will return the first fallback image
 *
 * Can take a prioritised list of image arrays from the DTO
 * It will check the sets in order and take the return match
 */
export function pickImage(
    preferredRatios: Ratio[],
    imageSet: ImageV4DTO[],
): ImageSelection | undefined {
    for (const { width, height } of preferredRatios) {
        const preferredRatioRounded = Math.round((width / height + 0.00001) * 100) / 100
        const match = checkDTOImageArray(imageSet, preferredRatioRounded)
        if (match) {
            return { image: match, isFallback: false }
        }
    }

    return undefined
}

function convertDimensions(dimensions: string) {
    const [widthString, heightString] = dimensions.split('x')

    return {
        width: parseInt(widthString, 10),
        height: parseInt(heightString, 10),
    }
}

function checkDTOImageArray(images: ImageV4DTO[], preferredRatioRounded: number) {
    let largestMatch: ImageV4DTO | undefined
    let largestMatchWidth: number = 0

    for (const image of images) {
        const imageDimensions = convertDimensions(image.dimensions)
        const imageRatio =
            Math.round((imageDimensions.width / imageDimensions.height + 0.00001) * 100) / 100

        const ratioDiff = preferredRatioRounded - imageRatio

        if (0.02 > ratioDiff && ratioDiff > -0.02) {
            // TODO for card images we probably want to provide some info about
            // whether the image was too large or too small

            if (imageDimensions.width > largestMatchWidth) {
                largestMatch = image
                largestMatchWidth = imageDimensions.width
            }
        }
    }

    return largestMatch
}
