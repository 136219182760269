import React from 'react'

const NoMatches: React.FC = () => (
    <section className="collection">
        <header className="collection__header">
            <h1 className="collection__heading">No collection found</h1>
        </header>
        <p className="lead">
            Unfortunately we couldn&rsquo;t find a matching collection for you to curate.
        </p>
        <p>
            Please try again, check the web address for any errors, or contact the digital
            department at <a href="mailto:digital@wanews.com.au">digital@wanews.com.au</a>.
        </p>
    </section>
)

export default NoMatches
