import React from 'react'

const VideoIcon: React.FC = () => (
    <svg
        width="16px"
        height="10px"
        viewBox="0 0 16 10"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <title>Icon / Video</title>
        <defs>
            <path
                d="M9.6665 3c.55133 0 1 .48003 1 1.0715v7.8577c0 .59005-.44867 1.0715-1 1.0708H1c-.55134.0007-1-.48075-1-1.0708V4.0715C0 3.48004.44866 3 .99998 3H9.6665zm5.85084.75127c.222-.11858.48266.0543.48266.32002v7.8577c0 .26356-.25933.4386-.48266.32l-3.99993-2.143c-.11264-.06145-.184-.18503-.184-.32004V6.2143c0-.13572.07136-.2593.184-.32002l3.99996-2.143z"
                id="path-1"
            />
        </defs>
        <g id="Symbols" fill="none" fillRule="evenodd">
            <g id="Icon-/-Video" transform="translate(0 -3)">
                <mask id="mask-2" fill="#fff">
                    <use xlinkHref="#path-1" />
                </mask>
                <use xlinkHref="#path-1" />
            </g>
        </g>
    </svg>
)

export default VideoIcon
