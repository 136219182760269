import React from 'react'

const ResetIcon = () => (
    <svg fillRule="evenodd" viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg">
        {/* tslint:disable-next-line max-line-length */}
        <path d="M7.2 15.85C3.87 16 .93 13.58.17 10.12c-.02-.1 0-.2.07-.27.06-.08.14-.16.24-.16h1.9c.13 0 .25.12.28.26.64 2.1 2.5 3.53 4.67 3.53 2.7 0 4.9-2.26 4.9-5.02s-2.2-5-4.9-5C6.08 3.47 4.9 3.95 4 4.8l1.32 1.36c.18.18.23.45.13.68-.1.23-.3.4-.56.4H.6c-.32 0-.6-.3-.6-.64V2.2c0-.24.15-.47.38-.56.22-.1.5-.05.66.13l1.24 1.27C3.63 1.74 5.45.96 7.33.96c4.04 0 7.33 3.38 7.33 7.52s-3.3 7.37-7.46 7.37z" />
    </svg>
)

export default ResetIcon
