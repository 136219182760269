import { ResponseError } from 'gazette/types'

/**
 * Checks the response body of an API call and returns either the response or an error.
 *
 * @param  {object} response - API response object
 * @return {object} Returns original response object
 * @throws Will throw an error if the response status is not between 200-299
 */
export const checkStatus = async (response: Response) => {
    if (response.status >= 200 && response.status < 300) {
        return response
    }

    try {
        // The Curation API has meaningful error messages
        // Try and serialise these and surface the message to the user
        const json = await response.json()
        const err: ResponseError = new Error(`${response.statusText} - ${json.message}`)
        err.response = response
        return Promise.reject(err)
    } catch (_) {
        // We failed to serialise the response as JSON
        const err: ResponseError = new Error(`${response.status} - ${response.statusText}`)
        err.response = response
        return Promise.reject(err)
    }
}

interface ObjectWithId {
    id: any
}

/**
 * Searchs an array of objects for a matching id value
 *
 * @param  {array}  arr - Array of objects
 * @param  {string} id  - The id being searched for
 * @return {object} Returns matching id object or undefined
 */
export const findById = <T extends ObjectWithId>(arr: T[], id: any) => {
    for (const entry of arr) {
        if (entry.id === id) {
            return entry
        }
    }
    return undefined
}
