import React from 'react'
import classnames from 'classnames'

import './sponsored_icon.scss'

export interface Props {
    className?: string
}

export const SponsoredIcon: React.FC<Props> = ({ className }) => (
    <svg
        className={classnames(className, 'icon-sponsored')}
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill="#0B986A"
            d="M11.667 2.042a2.042 2.042 0 0 1 0 4.083c-1.126 0-2.042-.916-2.042-2.042s.916-2.04 2.042-2.04zM7.583 0c.965 0 1.75.785 1.75 1.75S8.548 3.5 7.583 3.5s-1.75-.785-1.75-1.75S6.618 0 7.583 0zM3.5 4.667c-.965 0-1.75-.785-1.75-1.75s.785-1.75 1.75-1.75 1.75.785 1.75 1.75-.785 1.75-1.75 1.75zm-.583 1.75a1.46 1.46 0 0 1-1.46 1.458A1.46 1.46 0 0 1 0 6.417c0-.804.654-1.46 1.458-1.46.805 0 1.46.656 1.46 1.46zm-.584 2.04c.805 0 1.46.656 1.46 1.46a1.46 1.46 0 1 1-1.46-1.46zm2.917 2.626c.643 0 1.167.523 1.167 1.167 0 .643-.524 1.167-1.167 1.167s-1.167-.524-1.167-1.167c0-.644.524-1.167 1.167-1.167zm3.135.377a.875.875 0 1 1 0 1.75.875.875 0 0 1 0-1.75zm2.115-.74a.582.582 0 1 1 .002 1.166.582.582 0 0 1-.002-1.167zm1.33-1.387a.584.584 0 0 1 .001 1.167.585.585 0 0 1 .001-1.167zm1.003-.875a.292.292 0 1 1-.583 0 .29.29 0 0 1 .582 0z"
        />
    </svg>
)
