import 'whatwg-fetch'

import React from 'react'
import ReactDOM from 'react-dom'
import configureStore from 'gazette/store/configureStore'
import { App } from './components/App'
const store = configureStore()
const container = document.getElementById('root')

const render = () => {
    ReactDOM.render(<App store={store} />, container)
}

render()

if (module.hot) {
    module.hot.accept('./components/App', () => {
        setTimeout(render)
    })
}
