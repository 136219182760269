import thunk from 'redux-thunk'
import rootReducer from 'gazette/reducers'
import {
    createStore,
    applyMiddleware,
    compose,
    Middleware,
    Dispatch,
    Store,
    CombinedState,
} from 'redux'
import { middleware as notification } from 'react-redux-notifications'
import { GazetteState, ErrorAction, GazetteAction } from 'gazette/types'

const logErrors: Middleware = () => (next: Dispatch<GazetteAction>) => (action: any) => {
    if (action && (action as ErrorAction).error) {
        // tslint:disable-next-line:no-console
        console.info('Error dispatched', action.payload)
    }

    return next(action)
}

type DevtoolsWindow = Window & {
    __REDUX_DEVTOOLS_EXTENSION__?: () => any
}

export default function configureStore(
    preloadedState: GazetteState,
): Store<CombinedState<GazetteState>, GazetteAction> {
    const devtoolsWindow = window as DevtoolsWindow
    const store = createStore(
        rootReducer,
        preloadedState as any,
        compose(
            applyMiddleware(thunk, notification, logErrors),
            devtoolsWindow && devtoolsWindow.__REDUX_DEVTOOLS_EXTENSION__
                ? devtoolsWindow.__REDUX_DEVTOOLS_EXTENSION__()
                : (f: any) => f,
        ) as any,
    )

    if (module.hot) {
        module.hot.accept('../reducers', () => {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const nextRootReducer = require('gazette/reducers').default

            store.replaceReducer(nextRootReducer)
        })
    }

    return store
}
