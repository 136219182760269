import React from 'react'

const CollectionsIcon = () => (
    <svg
        className="nav__icon"
        fill="#fff"
        fillRule="evenodd"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M0 0h16v7H0V0zm0 9h7v7H0V9zm9 0h7v7H9V9z" />
    </svg>
)

export default CollectionsIcon
