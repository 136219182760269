import React from 'react'

const TaxonomyIcon = () => (
    <svg
        className="nav__icon"
        fill="#fff"
        fillRule="evenodd"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M4 14v1H3V4H0V0h7v4H4v2h5V5h7v3H9V7H4v3h5V9h7v3H9v-1H4v3H3v1h6v1h7v-3H9v1H4z" />
    </svg>
)

export default TaxonomyIcon
