export function scriptExists(src: string) {
    const scripts = document.getElementsByTagName('script')
    for (let i = scripts.length; i--; ) {
        if (scripts[i].src === src) {
            return true
        }
    }
    return false
}

export function createScript(src: string) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = src
        script.addEventListener('load', () => resolve())
        script.addEventListener('error', (e) => reject(e))
        document.body.appendChild(script)
    })
}
