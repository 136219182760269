import React from 'react'

import { MetadataFieldset } from './metadata_fieldset'
import { MetadataSelect } from './metadata_select'
import { MetadataText } from './metadata_text'
import { MetadataNumber } from './metadata_number'
import { CurationApi_CurationV4ResponseDTO } from '@west-australian-newspapers/publication-types'
import { MetadataStory } from './metadata_story'
import { Desk } from 'gazette/types'
import { FieldDefinition } from '../data'

interface Props {
    field: FieldDefinition
    desk: Desk
    currentValue: any
    updateCurationMetadata: (update: CurationApi_CurationV4ResponseDTO['metadata']) => Promise<any>
}

export interface GazetteFieldProps<FieldInfo extends FieldDefinition> {
    desk: Desk
    field: FieldInfo
    currentValue: any
    updateCurationMetadata: (value: any) => Promise<any>
}

export const MetadataItem: React.FC<Props> = (props) => {
    const { currentValue, field } = props
    const value = currentValue === undefined ? field.defaultValue : currentValue
    const Field = getField(field)

    if (!Field) {
        return null
    }

    return (
        <MetadataFieldset label={props.field.label} name={props.field.name}>
            <Field
                field={props.field}
                desk={props.desk}
                updateCurationMetadata={(newValue) => {
                    return props.updateCurationMetadata({
                        [props.field.name]: newValue,
                    })
                }}
                currentValue={value}
            />
        </MetadataFieldset>
    )
}

function getField<FieldInfo extends FieldDefinition>(
    fieldDefinition: FieldInfo,
): React.ComponentType<GazetteFieldProps<FieldInfo>> | undefined {
    switch (fieldDefinition.inputType) {
        case 'select':
            return MetadataSelect as React.ComponentType<GazetteFieldProps<FieldInfo>>
        case 'text':
            return MetadataText as React.ComponentType<GazetteFieldProps<FieldInfo>>
        case 'number':
            return MetadataNumber as React.ComponentType<GazetteFieldProps<FieldInfo>>
        case 'story':
            return MetadataStory as React.ComponentType<GazetteFieldProps<FieldInfo>>

        default:
            return undefined
    }
}
