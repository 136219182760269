import React from 'react'

export const ClockIcon: React.FC = () => (
    <svg
        width="12"
        height="12"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M8 16c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm3.332-5H7.666C7.298 9 7 8.7 7 8.33V4.666C7 4.298 7.298 4 7.666 4c.37 0 .667.298.667.666v3h3c.368 0 .666.298.666.666 0 .37-.3.667-.668.667z"
                id="clockicon-a"
            />
        </defs>
        <use fill="#393939" xlinkHref="#clockicon-a" fillRule="evenodd" />
    </svg>
)
