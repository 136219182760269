import React from 'react'

export const SevenNewsIcon: React.FC = () => (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
        <title>Desk Avatar / Seven News</title>
        <path fill="#FFF" d="M0 0h64v64H0z" />
        <path
            d="M44.522 8.438L54.57 25.155 37.126 55.6H16.913l27.61-47.163zm-35.084 0h33.736l-9.762 16.718H19.283L9.437 8.438z"
            fill="#ED1C24"
        />
    </svg>
)
