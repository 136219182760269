import React from 'react'
import ReactJsonSchemaForm from 'react-jsonschema-form'
import DESK_STATE from 'gazette/constants/desks'
import { checkStatus, findById } from 'gazette/actions/common'
import { uiSchema, PublicationData, FormSchema, getSchema } from './schema/formSchema'
import { MenuBarSimple } from '../components/menu_bar/menu_bar'
import AlertBanner from '../components/AlertBanner'
import { Desk as DeskType, TaxonomyTopicsResponse, GazetteState } from 'gazette/types'
import { connect } from 'react-redux'
import { fetchTopics } from '../features/search/search.redux'

import './editor.scss'
import { generateEditLink } from 'gazette/components/story/components/story_dropdown/story_dropdown'
import IconSave from 'gazette/edit-content-page/components/IconSave'
import IconClose from 'gazette/edit-content-page/components/IconClose'
import { isCueArticle } from 'gazette/actions/cue/isCueArticle'

// tslint:disable-next-line:no-console
const log = (type: string) => console.log.bind(console, type)

interface Props {
    params: {
        desk: string
        id: string
    }
    topics: TaxonomyTopicsResponse
    fetchTopics: (api: string) => void
}

interface State {
    data: any
    error: string | null
    success: string | null
    activeDesk: DeskType | undefined
}

const ERROR_NO_ACTIVE_DESK = 'Could not retrieve the active Desk'
const NO_CHANGE_REASON_SPECIFIED = "Please provide a reason for changing the Publication's content"
const CUE_ID_DETECTED = 'It looks like this publication is editable in Cue, redirecting'

class Editor extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        const defaultDesk = findById(DESK_STATE.desks, DESK_STATE.defaultDesk) as DeskType

        const activeDesk =
            this.props.params && this.props.params.desk
                ? findById(DESK_STATE.desks, this.props.params.desk)
                : defaultDesk

        this.state = { data: null, error: null, success: null, activeDesk }
    }

    componentWillMount() {
        if (isCueArticle(this.props.params.id)) {
            this.setState({
                error: CUE_ID_DETECTED,
            })

            if (this.state.activeDesk) {
                return window.location.replace(
                    generateEditLink(this.props.params.id, this.state.activeDesk.id),
                )
            }
        }

        this.getPublicationData()
    }

    getPublicationData() {
        const activeDesk = this.state.activeDesk
        if (activeDesk) {
            this.props.fetchTopics(activeDesk.api)

            fetch(
                `${activeDesk.api}/v4/publication/${this.props.params.id}?includeFuture=true&includeDead=true&include_expired=true`,
                { credentials: 'same-origin' },
            )
                .then(checkStatus)
                .then((resp) => resp.json() as Promise<any>)
                .then((data) => {
                    this.setState({ data })
                })
                .catch((error) => {
                    this.setState({ error })
                    // tslint:disable-next-line:no-console
                    console.error(error)
                })
        } else {
            this.setState({
                error: ERROR_NO_ACTIVE_DESK,
            })
        }
    }

    savePublication(formData: FormSchema) {
        const activeDesk = this.state.activeDesk

        if (activeDesk) {
            // update publication date
            formData.settings.publicationDate = new Date(
                formData.settings.publicationDate,
            ).toISOString()

            // Unsure undefined props are set to an empty string
            if (formData.articleContent.socialHead === undefined) {
                formData.articleContent.socialHead = ''
            }
            if (formData.articleContent.socialTeaser === undefined) {
                formData.articleContent.socialTeaser = ''
            }
            if (formData.articleContent.byline === undefined) {
                formData.articleContent.byline = { text: '' }
            }
            if (formData.settings.source === undefined) {
                formData.settings.source = ''
            }
            if (formData.settings.canonicalTitle === undefined) {
                formData.settings.canonicalTitle = ''
            }
            if (formData.settings.canonicalUrl === undefined) {
                formData.settings.canonicalUrl = ''
            }

            // remove primary topic
            delete (formData.settings as any).primaryTopic

            this.setState({
                success: null,
                error: null,
            })

            if (formData.settings.changeReason && formData.settings.changeReason.length >= 5) {
                fetch(`${activeDesk.api}/v4/publication/${this.props.params.id}`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        ...formData.settings,
                        ...formData.articleContent,
                        // Override requiredAccess with proper value e.g map 'Everybody' -> 'anonymous' - should be the level sent to the CURA
                        requiredAccess: this.requiredAccessReadableToLevel(
                            formData.settings.requiredAccess,
                        ),
                        // We pass the last updated as it was defined on the server - this is important because the
                        // server will compare it to the stored value so it can determine if concurrent updates were made
                        lastUpdated: this.state.data.lastUpdated,
                    }),
                })
                    .then(checkStatus)
                    .then((resp) => resp.json() as Promise<{ id: string; lastUpdated: string }>)
                    .then((data) => {
                        this.setState({
                            success: `Publication ${data.id} was successfully updated at ${data.lastUpdated}`,
                            error: null,
                        })
                        // lets request new data to refresh the form
                        this.getPublicationData()
                    })
                    .catch((error) => {
                        this.setState({
                            error: error.message,
                            success: null,
                        })
                        // tslint:disable-next-line:no-console
                        console.error(error)
                    })
            } else {
                this.setState(({ data }: { data: PublicationData }) => {
                    return {
                        data: { ...data, ...this.mapFormToData(formData) },
                        error: NO_CHANGE_REASON_SPECIFIED,
                    }
                })
            }
        } else {
            this.setState({ error: ERROR_NO_ACTIVE_DESK })
        }
    }

    getBanner() {
        let banner = null

        // an error occured, return straight away
        if (this.state.error) {
            return <AlertBanner level="error" text={this.state.error} />
        }

        // if no errors are present show the warning message
        if (
            this.state.data &&
            this.state.error === null &&
            Date.parse(this.state.data.publicationDate) > Date.now() - 14 * 24 * 60 * 60 * 1000
        ) {
            banner = (
                <AlertBanner
                    level="warning"
                    text={
                        'This article was first published within the last 14 days. We recommend checking in NewsGate before editing this article in Gazette.'
                    }
                />
            )
        }

        // show the success message if no errors are present
        if (this.state.error === null && this.state.success) {
            banner = <AlertBanner level="information" text={this.state.success} />
        }
        return banner
    }

    onSubmit(form: { formData: any }) {
        this.savePublication(form.formData)
    }

    mapFormToData(formData: FormSchema): PublicationData {
        // Read only fields so don't need them
        delete (formData.settings as any).publicationDate
        delete (formData.settings as any).primaryTopic
        delete (formData.settings as any).lastUpdated
        // Take a copy so we can map it properly
        const requiredAccessReadable = formData.settings.requiredAccess
        delete (formData.settings as any).requiredAccess

        // Extra step as flattening formData doesn't map directly
        return {
            ...formData.settings,
            ...formData.articleContent,

            // Override requiredAccess with proper value e.g map 'Everybody' -> 'anonymous' - should be the level sent to the CURA
            requiredAccess: { level: this.requiredAccessReadableToLevel(requiredAccessReadable) },
        }
    }

    populateFormData(data: PublicationData) {
        return {
            articleContent: {
                headKicker: data.headKicker || '',
                homepageHead: data.homepageHead || '',
                homepageTeaser: data.homepageTeaser || '',
                heading: data.heading || '',
                byline: data.byline.text || '',
                socialHead: data.socialHead || '',
                socialTeaser: data.socialTeaser || '',
            },
            settings: {
                status: data.status || '',
                source: data.source || '',
                primaryTopic: data.topics.primary !== null ? data.topics.primary.id : '',
                secondaryTopics:
                    data.topics.secondary !== null
                        ? data.topics.secondary.map((topic) => topic.id)
                        : [],
                isSponsored: data.isSponsored || false,
                requiredAccess: this.levelToRequiredAccessReadable(data.requiredAccess.level),
                excludeFeaturedVideo: data.excludeFeaturedVideo || false,
                lastUpdated: new Date(data.lastUpdated).toUTCString() || '',
                publicationDate: new Date(data.publicationDate).toUTCString() || '',
                canonicalTitle: data.canonicalTitle || '',
                canonicalUrl: data.canonicalUrl || '',
                allowCommenting: data.allowCommenting,
            },
        }
    }

    levelToRequiredAccessReadable(
        level: 'subscriber' | 'anonymous' | 'registered',
    ): 'Everyone' | 'Registered' | 'Subscriber' {
        switch (level) {
            case 'anonymous':
                return 'Everyone'
            case 'registered':
                return 'Registered'
            case 'subscriber':
                return 'Subscriber'
        }
    }

    requiredAccessReadableToLevel(
        requiredAccessDropdownValue: 'Everyone' | 'Registered' | 'Subscriber',
    ): 'subscriber' | 'anonymous' | 'registered' {
        switch (requiredAccessDropdownValue) {
            case 'Everyone':
                return 'anonymous'
            case 'Registered':
                return 'registered'
            case 'Subscriber':
                return 'subscriber'
        }
    }

    render() {
        let formData
        let title = 'Invalid ID'

        // Publication content loaded
        if (this.state.data) {
            title = this.props.params.id
            formData = this.populateFormData(this.state.data)
        }

        return (
            <main>
                {this.getBanner()}
                <MenuBarSimple />
                <div className="editor__container">
                    <h1 className="editor__title">Edit Article: {title}</h1>
                    <ReactJsonSchemaForm
                        schema={getSchema(this.props.topics)}
                        showErrorList={true}
                        uiSchema={uiSchema}
                        formData={formData}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onChange={() => {}}
                        onSubmit={(e: any) => this.onSubmit(e)}
                        onError={log('errors')}
                    >
                        <div>
                            <button type="submit" className="button button--small button--success">
                                <IconSave />
                                Save Changes
                            </button>
                            <button
                                type="button"
                                className="button button--small button--keyline"
                                onClick={() => {
                                    window.close()
                                }}
                            >
                                <IconClose /> Close Editor
                            </button>
                        </div>
                    </ReactJsonSchemaForm>
                </div>
            </main>
        )
    }
}

const mapStateToProps = ({ search: { topics } }: GazetteState) => {
    return {
        topics: topics.list,
    }
}

export default connect(mapStateToProps, { fetchTopics })(Editor)
