import React from 'react'

export const FutureIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14">
        <path
            fill="#000"
            d="M7 0c3.5 0 6.4 2.58 6.9 5.94l.88-1.3c.2-.32.62-.4.92-.2.3.2.4.62.2.93l-2.1 3.1c-.26.26-.68.26-.94 0l-2.36-2.7c-.24-.28-.2-.7.06-.94.28-.24.7-.22.94.06l1.1 1.24c-.43-2.72-2.77-4.8-5.6-4.8-3.13 0-5.67 2.53-5.67 5.66 0 3.12 2.54 5.66 5.67 5.66.37 0 .66.3.66.67 0 .37-.3.67-.66.67-3.86 0-7-3.14-7-7s3.14-7 7-7zm3.33 8.33H6.67A.67.67 0 0 1 6 7.66V4a.67.67 0 0 1 1.34 0v3h3c.36 0 .66.3.66.66 0 .37-.3.67-.67.67z"
        />
    </svg>
)
