import React from 'react'
import { connect, HandleThunkActionCreator } from 'react-redux'

import PublishIcon from './components/publish_icon'
import ResetIcon from './components/reset_icon'

import { GazetteState, Desk } from 'gazette/types'

import './save_curation.scss'
import { fetchCuration, saveCuration } from 'gazette/features/manage-curation/manage-curation.redux'
import { CurationV4ResponseDTO } from '@west-australian-newspapers/publication-types'

interface OwnProps {
    desk: Desk
}

interface DispatchProps {
    fetchCuration: HandleThunkActionCreator<typeof fetchCuration>
    saveCuration: HandleThunkActionCreator<typeof saveCuration>
}
interface StateProps {
    update: string[]
    lastUpdatedWhenLoaded: string | undefined
    id: string
    metadata: CurationV4ResponseDTO['metadata'] | undefined
    saving: boolean
}
interface Props extends OwnProps, DispatchProps, StateProps {}

const InternalSaveCuration: React.FC<Props> = (props) => (
    <form className="form form--save-curation">
        <div className="form__control form__control--submit">
            <button
                disabled={props.saving}
                onClick={(event) => {
                    event.preventDefault()
                    if (props.saving) {
                        return
                    }
                    props.saveCuration(props.desk.api, {
                        id: props.id,
                        body: {
                            updated: props.lastUpdatedWhenLoaded,
                            metadata: props.metadata,
                            articles: props.update,
                        },
                    })
                }}
                className="button button--success button--small"
                type="submit"
            >
                <PublishIcon />
                Publish
            </button>
            <button
                className="button button--keyline button--small"
                onClick={() => props.fetchCuration(props.desk.api, props.desk.id, props.id)}
                type="button"
            >
                <ResetIcon />
                Reset to last published
            </button>
        </div>
    </form>
)
InternalSaveCuration.displayName = 'InternalDisplayName'

const mapStateToProps = ({ curation }: GazetteState): StateProps => ({
    update: curation.articles && curation.articles.map(({ id }) => id),
    lastUpdatedWhenLoaded: curation.lastUpdatedWhenLoaded,
    metadata: curation.metadata,
    id: curation.id || '',
    saving: curation.saving || false,
})

export const SaveCuration: React.ComponentType<OwnProps> = connect(mapStateToProps, {
    fetchCuration,
    saveCuration,
})(InternalSaveCuration)
