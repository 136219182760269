import { GazetteAction, CurationState } from 'gazette/types'
import {
    SAVE_CURATION,
    SAVE_CURATION_DONE,
    curationInitialState,
} from 'gazette/features/manage-curation'

/**
 * Reducer to save curation externally and update state
 * @param  {array}  [state={ articles: [], metadata: {}, error: null }] - Existing state
 * @param  {string} action - Action object passed in from actions
 * @return {object} Article or error object for state
 */
export const saveCurationReducer = (
    state = curationInitialState,
    action: GazetteAction,
): CurationState => {
    switch (action.type) {
        case SAVE_CURATION:
            return {
                ...state,
                saving: true,
            }
        case SAVE_CURATION_DONE: {
            if (action.error) {
                return {
                    ...state,
                    saving: false,
                    error: action.payload.message,
                }
            }

            const { articles, lastUpdated } = action.payload

            return {
                ...state,
                saving: false,
                articles,
                data: { loading: false, curation: action.payload },
                lastUpdatedWhenLoaded: lastUpdated,
            }
        }
        default:
            return state
    }
}
