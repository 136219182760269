import { GazetteAction, CurationState, ErrorAction } from 'gazette/types'
import {
    REMOVE_CURATION_ITEM,
    UPDATE_CURATION,
    UPDATE_CURATION_METADATA,
    curationInitialState,
} from 'gazette/features/manage-curation'
import { ListPublicationV4DTO } from '@west-australian-newspapers/publication-types'

const getIndexInCuration = (articles: ListPublicationV4DTO[], id: string): number => {
    let indexInCuration = -1
    articles.some((article, index) => {
        if (id === article.id) {
            indexInCuration = index
            return true
        }
        return false
    })

    return indexInCuration
}

/**
 * Reducer to update curation metadata in the state
 * @param  {array}  [state={ articles: [], metadata: {}, error: null }] - Existing state
 * @param  {string} action - Action object passed in from actions
 * @return {object} Article or error object for state
 */
export const updateCurationReducer = (
    state = curationInitialState,
    action: GazetteAction,
): CurationState => {
    if (isErrorAction(action)) {
        return state
    }

    switch (action.type) {
        case REMOVE_CURATION_ITEM:
            return {
                ...state,
                articles: [...state.articles.filter((article) => action.payload.id !== article.id)],
            }
        case UPDATE_CURATION: {
            if (!state.data || state.data.loading) {
                throw new Error('Cannot update curation when not loaded')
            }
            const articleId = action.payload.update.article.id
            const indexInCuration = getIndexInCuration(state.articles, articleId)

            // Publication exists in the current curation
            if (indexInCuration !== -1) {
                const updateList = [...state.articles]
                updateList.splice(indexInCuration, 1)
                updateList.splice(action.payload.update.index, 0, action.payload.update.article)

                return {
                    ...state,
                    articles: updateList,
                }
            }

            const articles = [
                ...state.articles.slice(0, action.payload.update.index),
                action.payload.update.article,
                ...state.articles.slice(action.payload.update.index),
            ]

            const futurePublications = articles.filter((article) => {
                const isLive = article.status === 'live'
                const futurePublication =
                    isLive && new Date(article.publicationDate).valueOf() > Date.now()
                return futurePublication
            })
            // This trims the article list down to the correct number of item slots
            articles.splice((state.data.curation.itemSlots || 0) + futurePublications.length, 1)

            return {
                ...state,
                articles,
            }
        }

        case UPDATE_CURATION_METADATA: {
            const metadata = {
                ...state.metadata,
                ...action.payload.update,
            }
            return {
                ...state,
                metadata,
            }
        }

        default:
            return state
    }
}

function isErrorAction(action: GazetteAction): action is ErrorAction {
    // Redirect actions don't use error action, we need to rule them out
    if (action.type.indexOf('@@REDIRECT/') === 0) {
        return false
    }

    return !!(action as any).error
}
