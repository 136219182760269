import React from 'react'
import { getPosts } from './api'
import useWebSocket from 'react-use-websocket'
import { BlockV4DTO } from '@west-australian-newspapers/publication-types'
import { config } from 'gazette/config'

export interface Post {
    id: string
    post: number
    content: {
        posted: string
        blocks: BlockV4DTO[]
        author: string
        version: number
    }
}

export const PostList: React.FC<{
    blogId: string
    editPost: (post: Post) => void
}> = ({ blogId, editPost }) => {
    const [postsLoaded, setPostsLoaded] = React.useState(false)
    const [posts, setPosts] = React.useState<Post[]>([])

    const [, lastMessage] = useWebSocket(config.liveBlogSocket || '')

    React.useEffect(() => {
        setPosts([])
        async function loadPosts() {
            const postsResponse = await getPosts(blogId)

            setPosts(postsResponse.items)
            setPostsLoaded(true)
        }

        loadPosts()
    }, [blogId])

    React.useEffect(() => {
        if (lastMessage !== null) {
            const newPost = JSON.parse(lastMessage.data) as Post
            // Websocket is shared for all blogs - drop message if not for this blog
            if (newPost.id !== blogId) {
                return
            }
            const existingIndex = posts.findIndex((post) => post.post === newPost.post)
            if (existingIndex !== -1) {
                // Update existing post
                setPosts(posts.map((post) => (post.post === newPost.post ? newPost : post)))
            } else {
                // Add new post
                setPosts([newPost, ...posts])
            }
        }
    }, [lastMessage, blogId, posts])

    return postsLoaded ? (
        posts.length === 0 ? (
            <div>No posts yet</div>
        ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <React.Fragment>
                {posts.map((item) => (
                    <BlogPost key={item.post} post={item} editPost={() => editPost(item)} />
                ))}
            </React.Fragment>
        )
    ) : (
        <div>Loading posts...</div>
    )
}

const BlogPost: React.FC<{ post: Post; editPost: (post: Post) => void }> = ({ post, editPost }) => {
    return (
        <div>
            <header>{post.content.posted}</header>
            {post.content.blocks
                ? post.content.blocks.map((block, i) => {
                      if (block.kind === 'text') {
                          return <p key={i}>{block.text}</p>
                      }
                      if (block.kind === 'embed') {
                          return (
                              <p key={i}>
                                  Embed:{' '}
                                  <a href={block.reference} target="_blank" rel="noreferrer">
                                      {block.reference}
                                  </a>
                              </p>
                          )
                      }

                      return <p key={i}>Unknown block</p>
                  })
                : ''}
            <button
                onClick={() => {
                    editPost(post)
                }}
            >
                edit
            </button>
            <hr />
            <br />
        </div>
    )
}
