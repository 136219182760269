import React from 'react'

import 'gazette/components/ItemActionLink/ItemActionLink.scss'

interface Props {
    icon: React.ReactNode
    text: string
    linkTo?: string
    useWindowOpen?: boolean
    forceNewWindow?: boolean
}

export const ItemActionLink: React.FC<Props> = (props) => {
    const rel = props.forceNewWindow ? 'noopener noreferrer' : ''
    const target = props.forceNewWindow ? '_blank' : ''

    return (
        <a
            className="button button--text-icon story__button story__button--link"
            href={props.linkTo}
            rel={rel}
            target={target}
            onClick={(e) => {
                if (props.useWindowOpen) {
                    window.open(props.linkTo, target)
                    e.preventDefault()
                }
            }}
        >
            {props.icon}
            {props.text}
        </a>
    )
}
