import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { StatusFlag } from '../StatusFlag/StatusFlag'

interface Props {
    text: string
}

export const CopyToClipboardStateful: React.FC<Props> = (props) => {
    const [copied, setCopied] = useState(false)

    return (
        <CopyToClipboard
            onCopy={() => {
                setCopied(true)
                setTimeout(() => {
                    setCopied(false)
                }, 1000)
            }}
            text={props.text}
        >
            <button
                className="button button--text-icon story__button story__button--copy"
                type="button"
            >
                <StatusFlag text={copied ? 'Copied!' : 'Copy'} />
            </button>
        </CopyToClipboard>
    )
}
