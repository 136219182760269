import React from 'react'
import { Link } from 'react-router-dom'
import { Desk } from 'gazette/types'

interface Props {
    desk: Desk
    activeDeskName: string
}

export const DeskSelectionItem: React.FC<Props> = ({ desk, activeDeskName }) => {
    if (!desk) {
        return null
    }

    return (
        <li className="desk-selection__list-item">
            <Link
                className="desk-selection__list-link"
                to={`/desk/${desk.id}`}
                onClick={(e) => {
                    if (desk.name === activeDeskName) {
                        e.preventDefault()
                    }
                }}
            >
                <desk.icon />
                <span>{desk.name}</span>
            </Link>
        </li>
    )
}
