import React from 'react'
import { connect } from 'react-redux'
import StoryList from '../story_list'

import { Desk, StoriesState, GazetteState } from 'gazette/types'

interface OwnProps {
    desk: Desk
}

interface Props extends OwnProps {
    stories: StoriesState
}

class StoryListContainer extends React.Component<Props> {
    render() {
        const { stories, desk } = this.props

        return <StoryList stories={stories} desk={desk} />
    }
}

const mapStateToProps = ({ search: { stories } }: GazetteState) => ({
    stories,
})

export default connect(mapStateToProps)(StoryListContainer)
