import { Dispatch } from 'redux'
import { Redirect, RedirectsEditorState, GazetteAction } from 'gazette/types'
import { CurationApi_RedirectV4DTO } from '@west-australian-newspapers/publication-types'

const REDIRECT_EDIT_INIT = '@@REDIRECT/EDIT_INIT'
const REDIRECT_NEW_INIT = '@@REDIRECT/NEW_INIT'

const REDIRECT_CANCEL = '@@REDIRECT/CANCEL'

const REDIRECT_DELETE_BEGIN = '@@REDIRECT/DELETE_BEGIN'
const REDIRECT_DELETE_COMPLETE = '@@REDIRECT/DELETE_COMPLETE'
const REDIRECT_DELETE_FAIL = '@@REDIRECT/DELETE_FAIL'

const REDIRECTS_FETCH_BEGIN = '@@REDIRECT/FETCH_BEGIN'
const REDIRECTS_FETCH_COMPLETE = '@@REDIRECT/FETCH_COMPLETE'
const REDIRECTS_FETCH_FAIL = '@@REDIRECT/FETCH_FAIL'

const REDIRECT_SAVE_BEGIN = '@@REDIRECT/SAVE_BEGIN'
const REDIRECT_SAVE_PENDING = '@@REDIRECT/SAVE_PENDING'
const REDIRECT_SAVE_COMPLETE = '@@REDIRECT/SAVE_COMPLETE'
const REDIRECT_SAVE_FAIL = '@@REDIRECT/SAVE_FAIL'

const REDIRECT_EDIT_BEGIN = '@@REDIRECT/EDIT_BEGIN'
const REDIRECT_EDIT_PENDING = '@@REDIRECT/EDIT_PENDING'
const REDIRECT_EDIT_COMPLETE = '@@REDIRECT/EDIT_COMPLETE'
const REDIRECT_EDIT_FAIL = '@@REDIRECT/EDIT_FAIL'

export type RedirectActions =
    | RedirectEditInitAction
    | RedirectNewInitAction
    | RedirectCancelAction
    | RedirectDeleteBeginAction
    | RedirectDeleteCompleteAction
    | RedirectDeleteFailAction
    | RedirectFetchBeginAction
    | RedirectFetchCompleteAction
    | RedirectFetchFailAction
    | RedirectSaveBeginAction
    | RedirectSavePendingAction
    | RedirectSaveCompleteAction
    | RedirectSaveFailAction
    | RedirectEditBeginAction
    | RedirectEditPendingAction
    | RedirectEditCompleteAction
    | RedirectEditFailAction

export interface RedirectEditInitAction {
    type: typeof REDIRECT_EDIT_INIT
    id: number
}
export interface RedirectNewInitAction {
    type: typeof REDIRECT_NEW_INIT
}
export interface RedirectCancelAction {
    type: typeof REDIRECT_CANCEL
}
export interface RedirectDeleteBeginAction {
    type: typeof REDIRECT_DELETE_BEGIN
    id: number
}
export interface RedirectDeleteCompleteAction {
    type: typeof REDIRECT_DELETE_COMPLETE
}
export interface RedirectDeleteFailAction {
    type: typeof REDIRECT_DELETE_FAIL
    err: any
}
export interface RedirectFetchBeginAction {
    type: typeof REDIRECTS_FETCH_BEGIN
}
export interface RedirectFetchCompleteAction {
    type: typeof REDIRECTS_FETCH_COMPLETE
    data: {
        redirects: CurationApi_RedirectV4DTO[]
    }
}
export interface RedirectFetchFailAction {
    type: typeof REDIRECTS_FETCH_FAIL
    err: any
}

export interface RedirectSaveBeginAction {
    type: typeof REDIRECT_SAVE_BEGIN
}
export interface RedirectSavePendingAction {
    type: typeof REDIRECT_SAVE_PENDING
}
export interface RedirectSaveCompleteAction {
    type: typeof REDIRECT_SAVE_COMPLETE
    item: Redirect
}
export interface RedirectSaveFailAction {
    type: typeof REDIRECT_SAVE_FAIL
    err: any
}
export interface RedirectEditBeginAction {
    type: typeof REDIRECT_EDIT_BEGIN
}
export interface RedirectEditPendingAction {
    type: typeof REDIRECT_EDIT_PENDING
    item: Redirect
}
export interface RedirectEditCompleteAction {
    type: typeof REDIRECT_EDIT_COMPLETE
    item: Redirect
}
export interface RedirectEditFailAction {
    type: typeof REDIRECT_EDIT_FAIL
    err: any
}

const emptyRedirect: Redirect = {
    id: 0,
    sourceUrl: '',
    targetUrl: '',
    comment: '',
    active: false,
    httpStatusCode: 302,
}

const initialState: RedirectsEditorState = {
    items: [emptyRedirect],
    editMode: false,
    editing: {
        redirect: emptyRedirect,
        drawerOpen: false,
    },
    loading: false,
    error: undefined,
    isSaving: false,
}

/* eslint-disable no-case-declarations */

export const redirects = (state = initialState, action: GazetteAction): RedirectsEditorState => {
    const itemStateArray = [...state.items]

    switch (action.type) {
        case REDIRECT_EDIT_INIT:
            const filteredItem = state.items.filter((item: Redirect) => item.id === action.id)

            return {
                ...state,
                editMode: true,
                editing: {
                    drawerOpen: true,
                    redirect: filteredItem[0],
                },
            }

        case REDIRECT_NEW_INIT:
            return {
                ...state,
                editMode: false,
                editing: {
                    drawerOpen: true,
                    redirect: emptyRedirect,
                },
            }

        case REDIRECT_CANCEL:
            return {
                ...state,
                editMode: false,
                editing: {
                    drawerOpen: false,
                    redirect: emptyRedirect,
                },
            }

        case REDIRECT_DELETE_BEGIN:
            const filteredArray = state.items.filter((item: Redirect) => item.id !== action.id)

            return {
                ...state,
                items: filteredArray,
            }

        case REDIRECT_DELETE_COMPLETE:
            return {
                ...state,
                loading: false,
            }

        case REDIRECT_EDIT_BEGIN:
            return {
                ...state,
            }

        case REDIRECT_EDIT_PENDING:
            const itemToEdit = { ...action.item, pending: true }

            return {
                ...state,
                items: updateItemList(state.items, itemToEdit),
            }

        case REDIRECT_EDIT_COMPLETE:
            const editedItem = { ...action.item, pending: false }

            return {
                ...state,
                items: updateItemList(state.items, editedItem),
                editing: { drawerOpen: false, redirect: null },
            }

        case REDIRECT_SAVE_BEGIN:
            return {
                ...state,
            }

        case REDIRECT_SAVE_PENDING:
            return {
                ...state,
                isSaving: true,
            }

        case REDIRECT_SAVE_COMPLETE:
            const newItem = action.item

            itemStateArray.unshift(newItem)

            return {
                ...state,
                items: itemStateArray,
                isSaving: false,
                editing: { drawerOpen: false, redirect: null },
            }

        case REDIRECTS_FETCH_BEGIN:
            return {
                ...state,
                loading: true,
            }

        case REDIRECTS_FETCH_COMPLETE:
            // Sort by lastUpdated desc
            const sortedRedirects = action.data.redirects
                .map<Redirect>((redirect) => ({
                    active: redirect.active,
                    comment: redirect.comment,
                    httpStatusCode: redirect.httpStatusCode,
                    id: redirect.id,
                    lastUpdated: redirect.lastUpdated,
                    pending: undefined,
                    sourceUrl: redirect.sourceUrl,
                    targetUrl: redirect.targetUrl,
                }))
                .sort((a: Redirect, b: Redirect) => {
                    if (!a.lastUpdated || !b.lastUpdated) {
                        return 0
                    }

                    const dateA = new Date(a.lastUpdated).getTime()
                    const dateB = new Date(b.lastUpdated).getTime()

                    return dateB - dateA
                })

            return {
                ...state,
                items: sortedRedirects,
                loading: false,
            }

        case REDIRECT_DELETE_FAIL:
        case REDIRECT_EDIT_FAIL:
        case REDIRECT_SAVE_FAIL:
            // tslint:disable-next-line:no-console
            console.error(action.err)
            return {
                ...state,
                loading: false,
                error: true,
                isSaving: false,
                errorMessage: action.err,
            }

        default:
            return state
    }
}

/** Helper to return updated state.items  */
function updateItemList(items: Redirect[], redirect: Redirect) {
    const itemList = items.map((item) => {
        if (item.id !== redirect.id) {
            return item
        }
        return redirect
    })

    return itemList
}

/**
 * Set local state to show redirect editor
 */
export const editRedirect = (id: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: REDIRECT_EDIT_INIT,
            id,
        })
    }
}

/**
 * Set local state to show redirect creator window
 */
export const newRedirect = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: REDIRECT_NEW_INIT,
        })
    }
}

/**
 * Close editor form
 */
export const closeForm = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: REDIRECT_CANCEL,
        })
    }
}

/**
 * Delete single redirect
 */
export const deleteRedirect = (api: string, id: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: REDIRECT_DELETE_BEGIN,
            id,
        })

        return fetch(`${api}/redirect/${id}`, {
            credentials: 'same-origin',
            method: 'DELETE',
        })
            .then((resp) => resp.json() as Promise<any>)
            .then(() => dispatch({ type: REDIRECT_DELETE_COMPLETE }))
            .catch((err) => {
                dispatch({ type: REDIRECT_DELETE_FAIL, err })
            })
    }
}

/**
 * Update single redirect
 */

export const updateRedirect = (api: string, item: Redirect) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: REDIRECT_EDIT_PENDING,
            item,
        })

        return fetch(`${api}/redirect/${item.id}`, {
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'PUT',
            body: JSON.stringify(item),
        })
            .then((resp) => resp.json() as Promise<any>)
            .then((resp) => dispatch({ type: REDIRECT_EDIT_COMPLETE, item: resp }))
            .catch((err) => {
                dispatch({ type: REDIRECT_SAVE_FAIL, err, item })
            })
    }
}

/**
 * Fetch all redirects from API
 */
export const fetchRedirects = (api: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({
            type: REDIRECTS_FETCH_BEGIN,
        })

        return fetch(`${api}/redirect`, {
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((resp) => resp.json() as Promise<CurationApi_RedirectV4DTO>)
            .then((data) => {
                dispatch({ type: REDIRECTS_FETCH_COMPLETE, data })
            })
            .catch((err) => {
                dispatch({ type: REDIRECTS_FETCH_FAIL, err })
            })
    }
}

/**
 * Save single new redirect
 */
export const saveRedirect = (api: string, item: any) => {
    return async (dispatch: Dispatch<GazetteAction>) => {
        dispatch({
            type: REDIRECT_SAVE_PENDING,
            item,
        })

        try {
            const resp = await fetch(`${api}/redirect`, {
                credentials: 'same-origin',
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(item),
            })
            const resp_1 = await (resp.json() as Promise<any>)
            if (resp_1.error) {
                throw Error(resp_1.error)
            }
            const resp_2 = resp_1
            dispatch({ type: REDIRECT_SAVE_COMPLETE, item: resp_2 })
        } catch (err) {
            dispatch({ type: REDIRECT_SAVE_FAIL, err, item })
        }
    }
}
