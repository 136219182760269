import React from 'react'
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom'
import { connect, InferThunkActionCreatorType } from 'react-redux'
import {
    fetchCurations,
    saveCurationOrder,
    refreshCurations,
} from 'gazette/actions/curations/curations'

import { getHoursFromPresent } from '../../utils/get-hours-from-present'

import { Desk, GazetteState, CurationsData } from 'gazette/types'

import 'gazette/components/CurationList/CurationList.scss'

interface OwnProps extends RouteComponentProps {
    desk: Desk
}

interface StateProps {
    curationsData: CurationsData | undefined
}

interface DispatchProps {
    fetchCurations: InferThunkActionCreatorType<typeof fetchCurations>
    saveCurationOrder: typeof saveCurationOrder
    refreshCurations: InferThunkActionCreatorType<typeof fetchCurations>
}

interface Props extends OwnProps, StateProps, DispatchProps {}
interface State {
    draggingOver?: string
}

class InternalCurationList extends React.Component<Props, State> {
    state: State = {}
    dragging?: string

    refreshInterval?: number

    constructor(props: Props) {
        super(props)

        props.fetchCurations(props.desk.api, props.desk.id)
    }

    refreshCurations = () => {
        this.props.refreshCurations(this.props.desk.api, this.props.desk.id)
    }

    componentDidMount() {
        this.refreshInterval = window.setInterval(this.refreshCurations, 60000)
    }

    componentWillUnmount() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval)
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.desk.id !== prevProps.desk.id) {
            this.props.fetchCurations(this.props.desk.api, this.props.desk.id)
        }
    }

    render() {
        const {
            desk: { id: deskId },
        } = this.props

        return (
            <section className="curations">
                <header className="curations__header">
                    <h1 className="curations__heading">Curations</h1>
                </header>
                <nav className="nav nav--curations">
                    {!this.props.curationsData || this.props.curationsData.loading ? (
                        <div>Loading...</div>
                    ) : (
                        <ul>
                            {this.props.curationsData.list.map((curation, curationIndex) => {
                                const ageOldCount = () => (
                                    <div className="nav__badge">
                                        {getHoursFromPresent(curation.lastUpdated)}
                                        &nbsp;
                                        <span className="nav__badge-unit">hrs</span>
                                    </div>
                                )

                                return (
                                    <li
                                        className={`nav__item${
                                            this.state.draggingOver === curation.id
                                                ? ' nav__item--drag-target'
                                                : ''
                                        }`}
                                        key={curation.id}
                                        draggable={true}
                                        onDragEnter={() => {
                                            if (
                                                this.dragging &&
                                                this.state.draggingOver !== curation.id
                                            ) {
                                                this.setState({ draggingOver: curation.id })
                                            }
                                        }}
                                        onDragStart={() => {
                                            this.dragging = curation.id
                                        }}
                                        onDragEnd={() => {
                                            this.dragging = undefined
                                        }}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(e) => {
                                            e.preventDefault()
                                            this.setState({ draggingOver: undefined })
                                            if (!this.dragging) {
                                                return
                                            }
                                            const ids =
                                                this.props.curationsData &&
                                                !this.props.curationsData.loading
                                                    ? this.props.curationsData.list.map(
                                                          (cura) => cura.id,
                                                      )
                                                    : []

                                            ids.splice(ids.indexOf(this.dragging!), 1)
                                            ids.splice(curationIndex, 0, this.dragging!)

                                            this.props.saveCurationOrder(deskId, ids)
                                            this.dragging = undefined
                                        }}
                                    >
                                        <NavLink
                                            activeClassName="nav__link--active"
                                            className="nav__link"
                                            to={`/desk/${deskId}/curations/${curation.id}`}
                                        >
                                            {curation.title}
                                            {ageOldCount()}
                                        </NavLink>
                                    </li>
                                )
                            })}
                        </ul>
                    )}
                </nav>
            </section>
        )
    }
}

const mapStateToProps = ({ curations }: GazetteState): StateProps => ({
    curationsData: curations.data,
})

export const CurationList = withRouter(
    connect<StateProps, DispatchProps, OwnProps, GazetteState>(mapStateToProps, {
        fetchCurations,
        saveCurationOrder,
        refreshCurations,
    })(InternalCurationList),
)
