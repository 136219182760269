import { differenceInHours } from 'date-fns'

export const getHoursFromPresent = (timeToCheck: string) => {
    const hoursFromPresent = differenceInHours(
        new Date().getTime(),
        new Date(timeToCheck).getTime(),
    )

    return hoursFromPresent
}
