import React from 'react'
import CollectionMetadata from '../curation_metadata'
import CollectionSlot from '../curation_slot'

import { Desk, UpdateCurationActionParam, CurationState } from 'gazette/types'

import './curation_slot_list.scss'
import { CurationV4ResponseDTO } from '@west-australian-newspapers/publication-types'
import { ListPublicationV4DTO } from '@west-australian-newspapers/publication-types'

interface Props {
    desk: Desk
    curation: CurationState
    removeCurationItem: (id: string) => Promise<any>
    section?: string
    updateCuration: (update: UpdateCurationActionParam) => Promise<any>
    updateCurationMetadata: (update: CurationV4ResponseDTO['metadata']) => Promise<any>
}

export const CollectionSlotList: React.FC<Props> = ({
    curation,
    updateCuration,
    updateCurationMetadata,
    removeCurationItem,
    desk,
}) => {
    const futurePublications = curation.articles.filter((article) => {
        const isLive = article.status === 'live'
        const futurePublication = isLive && new Date(article.publicationDate).valueOf() > Date.now()
        return futurePublication
    })

    const curationSlots =
        ((curation.data && !curation.data.loading && curation.data.curation.itemSlots) || 0) +
        futurePublications.length
    const paddedCurations: Array<ListPublicationV4DTO | undefined> = [...curation.articles]

    if (curation.articles.length < curationSlots) {
        for (let i = 0; i < curationSlots - curation.articles.length; i++) {
            paddedCurations.push(undefined)
        }
    }

    return (
        <React.Fragment>
            <ul className="collection-slot-list">
                {paddedCurations.map((c, i) => (
                    <li className="collection-slot-list__item" key={c ? c.id : i}>
                        <CollectionSlot
                            index={i + 1}
                            item={c}
                            desk={desk}
                            removeCurationItem={removeCurationItem}
                            updateCuration={updateCuration}
                        />
                    </li>
                ))}
            </ul>

            {curation.data && !curation.data.loading && (
                <CollectionMetadata
                    desk={desk}
                    curation={curation}
                    updateCurationMetadata={updateCurationMetadata}
                />
            )}
        </React.Fragment>
    )
}
CollectionSlotList.displayName = 'CollectionSlotList'
