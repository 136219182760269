import React from 'react'
import { EditorState, RichUtils, ContentState } from 'draft-js'

export const AddLinkControl: React.FC<{
    editorState: EditorState
    updateState: (state: EditorState) => void
}> = ({ editorState, updateState }) => {
    const isLink = RichUtils.currentBlockContainsLink(editorState)
    return (
        <button
            type="button"
            className="RichEditor-styleButton"
            disabled={editorState.getSelection().isCollapsed()}
            onMouseDown={() => {
                if (!isLink) {
                    const urlValue = prompt('Paste URL', '')
                    if (!urlValue) {
                        return
                    }

                    const contentState = editorState.getCurrentContent()
                    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
                        url: urlValue,
                    })

                    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
                    const newEditorState = EditorState.set(editorState, {
                        currentContent: contentStateWithEntity,
                    })

                    updateState(
                        RichUtils.toggleLink(
                            newEditorState,
                            newEditorState.getSelection(),
                            entityKey,
                        ),
                    )

                    return
                }

                const selection = editorState.getSelection()
                updateState(RichUtils.toggleLink(editorState, selection, null))
            }}
        >
            {!isLink ? 'Add' : 'Remove'} Link
        </button>
    )
}

export const LinkComponent: React.FC<{ contentState: ContentState; entityKey: string }> = ({
    contentState,
    entityKey,
    children,
}) => {
    const { url } = contentState.getEntity(entityKey).getData()
    return (
        <a href={url} title={url} className="ed-link">
            {children}
        </a>
    )
}
