import React from 'react'
import { Formik, Field, Form, FieldProps } from 'formik'

import 'gazette/features/manage-redirects/components/RedirectItem/RedirectItem.scss'

import { Desk, GazetteState, Redirect, GazetteAction } from 'gazette/types'
import { connect } from 'react-redux'
import PublishIcon from 'gazette/features/manage-curation/save_curation/components/publish_icon'
import {
    saveRedirect,
    updateRedirect,
    closeForm,
} from 'gazette/features/manage-redirects/components/EditRedirect/EditRedirect.redux'
import { ThunkDispatch } from 'redux-thunk'

interface InternalProps {
    desk: Desk
}

interface DispatchProps {
    saveRedirect: any
    updateRedirect: any
    closeForm: any
}

interface StateProps {
    editMode: boolean
    item: any
    isSaving: boolean
    error?: boolean
    errorMessage?: string
}

type Props = InternalProps & DispatchProps & StateProps
const isLinkActiveValues = new Set([true, 'active'])
export class InternalEditRedirect extends React.Component<Props> {
    handleItemSave = async (values: Redirect) => {
        // Check form active field's value is one of the active checkbox values
        const activeValue = isLinkActiveValues.has(values.active)

        const correctedValues = { ...values, active: activeValue }

        this.props.editMode
            ? await this.props.updateRedirect(this.props.desk.api, correctedValues)
            : await this.props.saveRedirect(this.props.desk.api, correctedValues)
    }

    handleCancelForm = () => {
        this.props.closeForm()
    }

    render() {
        const item = this.props.item

        return (
            <section>
                <Formik
                    enableReinitialize
                    initialValues={item}
                    onSubmit={(values, actions) => {
                        this.handleItemSave(values)
                        actions.setSubmitting(this.props.isSaving)
                    }}
                    render={(setFieldValue) => (
                        <Form className="form SaveRedirect">
                            <Field
                                type="text"
                                name="sourceUrl"
                                render={({ field }: FieldProps<Redirect>) => (
                                    <div className="form__control form__control--text">
                                        <label className="form__label" htmlFor="redirectSource">
                                            Source/Vanity URL
                                        </label>
                                        <input
                                            className="form__input form__input--text"
                                            {...field}
                                        />
                                        <div className="form__label--info">
                                            Do not include {this.props.desk.baseUrl}, but include
                                            leading slash ‘/‘.
                                        </div>
                                    </div>
                                )}
                            />

                            <Field
                                type="text"
                                name="targetUrl"
                                render={({ field }: FieldProps<Redirect>) => (
                                    <div className="form__control form__control--text">
                                        <label className="form__label" htmlFor="redirectTarget">
                                            Target URL
                                        </label>
                                        <input
                                            className="form__input form__input--text"
                                            {...field}
                                        />
                                    </div>
                                )}
                            />

                            <Field
                                type="textarea"
                                name="comment"
                                render={({ field }: FieldProps<Redirect>) => (
                                    <div className="form__control form__control--text">
                                        <label className="form__label" htmlFor="redirectTarget">
                                            Comment
                                        </label>
                                        <textarea
                                            className="form__input form__input--text"
                                            {...field}
                                        />
                                        <div className="form__label--info">
                                            Provide job ticket, who requested and when and if it can
                                            be removed at a later date.
                                        </div>
                                    </div>
                                )}
                            />

                            <div className="form__control form__control--text">
                                <p className="form__label">Redirect is active</p>
                                <div className="form__radiogroup">
                                    <Field
                                        type="radio"
                                        name="active"
                                        value="active"
                                        onChange={setFieldValue}
                                        render={({ field, form }: any) => {
                                            return (
                                                <div className="form__radiogroup--control">
                                                    <input
                                                        id="form-active"
                                                        className="form__input form__input--radio"
                                                        {...field}
                                                        type="radio"
                                                        checked={
                                                            form.values.active ||
                                                            form.values.active === 'active'
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <label
                                                        className="form__label"
                                                        htmlFor="form-active"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>
                                            )
                                        }}
                                    />
                                    <Field
                                        type="radio"
                                        name="active"
                                        onChange={setFieldValue}
                                        value="inactive"
                                        render={({ field, form }: any) => (
                                            <div className="form__radiogroup--control">
                                                <input
                                                    id="form-inactive"
                                                    className="form__input form__input--radio"
                                                    {...field}
                                                    type="radio"
                                                    checked={
                                                        !form.values.active ||
                                                        form.values.active === 'inactive'
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <label
                                                    className="form__label"
                                                    htmlFor="form-inactive"
                                                >
                                                    No
                                                </label>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            <Field
                                type="hidden"
                                name="httpStatusCode"
                                render={({ field }: any) => (
                                    <div className="form__control form__control--text">
                                        <label
                                            className="form__label--hidden form__label"
                                            htmlFor="active"
                                        >
                                            HTTP Redirect Type
                                        </label>
                                        <input
                                            className="form__input"
                                            {...field}
                                            type="hidden"
                                            value="302"
                                        />
                                    </div>
                                )}
                            />

                            <div className="form__control form__control--submit">
                                <button
                                    className="button button--success button--small"
                                    type="submit"
                                    disabled={this.props.isSaving}
                                >
                                    <PublishIcon />
                                    Save Redirect
                                </button>

                                <button
                                    className="button button--small button--cancel"
                                    onClick={this.handleCancelForm}
                                    type="button"
                                >
                                    Cancel
                                </button>
                            </div>
                        </Form>
                    )}
                />
                {this.props.error && (
                    <div>{this.props.errorMessage && this.props.errorMessage.toString()}</div>
                )}
            </section>
        )
    }
}

const mapStateToProps = ({ redirects }: GazetteState): StateProps => ({
    editMode: redirects.editMode,
    item: redirects.editing.redirect,
    isSaving: redirects.isSaving,
    error: redirects.error,
    errorMessage: redirects.errorMessage,
})

const mapDispatchToProps = (
    dispatch: ThunkDispatch<GazetteState, GazetteAction, GazetteAction>,
) => ({
    saveRedirect: (api: string, item: any) => dispatch(saveRedirect(api, item)),
    updateRedirect: (api: string, item: any) => dispatch(updateRedirect(api, item)),
    closeForm: () => dispatch(closeForm()),
})

export const EditRedirect = connect(mapStateToProps, mapDispatchToProps)(InternalEditRedirect)
