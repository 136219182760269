import * as React from 'react'

const IconClose = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="#9b9b9b"
        fillRule="evenodd"
    >
        <path d="M11.34 9.07c.18.18.28.4.28.66s-.1.48-.28.67L10 11.73c-.17.18-.4.27-.65.27-.26 0-.48-.1-.67-.27L5.8 8.85l-2.87 2.88c-.18.18-.4.27-.66.27s-.5-.1-.67-.27L.27 10.4C.1 10.2 0 10 0 9.73c0-.26.1-.48.27-.66L3.15 6.2.27 3.3C.1 3.12 0 2.92 0 2.65 0 2.4.1 2.17.27 2L1.6.65c.18-.18.4-.28.67-.28.26 0 .48.1.66.28L5.8 3.53 8.7.66c.2-.18.4-.28.67-.28.26 0 .48.1.66.28L11.35 2c.18.17.28.4.28.65 0 .26-.1.48-.28.67L8.47 6.2l2.87 2.87z" />
    </svg>
)

export default IconClose
