import React from 'react'

export const PerthNowIcon: React.FC = () => (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
        <title>Desk Avatar / PerthNow</title>
        <g id="Symbols" fill="none" fillRule="evenodd">
            <g id="Desk-Avatar-/-PerthNow">
                <path id="Black-Background" fill="#FFF" d="M0 0h64v64H0z" />
                <g id="PerthNow">
                    <path
                        d="M12.25398 32.97743C9.3534 32.97743 7 35.3301 7 38.23066v7.313c0 2.90156 2.3534 5.25348 5.25398 5.25348h39.42717c2.90107 0 5.25323-2.35192 5.25323-5.25348v-7.313c0-2.90057-2.35216-5.25323-5.25323-5.25323H12.25398"
                        id="Fill-9"
                        fill="#D42626"
                    />
                    <path
                        id="Fill-15"
                        fill="#FFFFFE"
                        d="M34.128 36.38363h2.9716l2.3781 7.68093h.04536l2.21755-7.68093h2.9719l2.3769 7.68093h.0459l2.2861-7.68093h2.7655l-3.7254 10.9731h-2.7206l-2.6061-7.4982h-.04535l-2.30856 7.4982h-2.835l-3.8177-10.9731"
                    />
                    <path
                        d="M28.53738 45.19252c2.01166 0 3.22314-1.57667 3.22314-3.29143 0-1.7146-1.21148-3.292-3.22314-3.292-2.01165 0-3.22313 1.5774-3.22313 3.2919 0 1.7147 1.21148 3.2914 3.22313 3.2914m0-9.0522c3.31487 0 5.9664 2.3088 5.9664 5.7607 0 3.4514-2.65153 5.7612-5.9664 5.7612-3.3146 0-5.96616-2.3098-5.96616-5.76128 0-3.45196 2.65155-5.76075 5.96616-5.76075"
                        id="Fill-14"
                        fill="#FFFFFE"
                    />
                    <path
                        d="M11.3794 36.41478h2.60618v1.76014h.04587c.50254-1.09706 1.5313-2.0346 3.36023-2.0346 2.94893 0 4.00063 2.08046 4.00063 4.32045v6.92712h-2.743v-5.5551c0-1.212-.0915-3.2237-2.0119-3.2237-1.8057 0-2.5147 1.3261-2.5147 2.7891v5.9896h-2.7433V36.4147"
                        id="Fill-13"
                        fill="#FFFFFE"
                    />
                    <g id="Group" fill="#000">
                        <path
                            d="M56.71996 22.80647c-.01472-3.5937-1.39655-5.66072-4.79663-5.66072-1.1292 0-2.1275.2969-3.06492 1.08345v-4.18075H46.206v14.3678h2.65243v-6.06393c0-1.28543.6711-2.61484 2.88363-2.61484 1.41125 0 2.31676.96395 2.31676 2.21535.01196.2795.0142.5354.0142.7406v5.72283h2.65194v-5.59712l-.00498-.01267"
                            id="Fill-39"
                        />
                        <path
                            d="M44.77552 25.7746v-.00647l-.01123.00646c-1.2083-.0617-2.3732-.9628-2.7662-1.9401-.0888-.2211-.1397-.5866-.1397-1.0055v-3.1437h2.917v-2.272h-2.917v-3.0976H39.206v7.9645c0 .3736.0364 1.1604.10625 1.514.51346 2.6106 2.83098 4.5802 5.61135 4.5802v-2.5929c-.04963 0-.0985-.005-.14813-.007"
                            id="Fill-40"
                        />
                        <path
                            d="M37.56137 17.16712c-.10325-.01267-.20724-.02137-.31497-.02137l-.05013.00323c-.0222 0-.0439-.00323-.06658-.00323-1.129 0-2.3652.26882-3.0657 1.27997v-1.01264h-2.6517v11.00318h2.6517v-5.6811l.001-.0385c0-1.5289.897-2.95893 3.0646-2.95893.3798 0 .7421.0708 1.0785.19503V17.2501c-.2118-.04025-.4277-.0656-.6469-.08298"
                            id="Fill-42"
                        />
                        <path
                            d="M24.8785 19.73773c1.33046 0 2.46316.83278 2.90432 2.0002H21.9737c.44165-1.16742 1.57335-2.0002 2.9048-2.0002m2.57438 4.80112c-.55737.82035-1.50203 1.36046-2.57437 1.36046-1.408 0-2.595-.9299-2.9758-2.2031h5.9508l-.0007.002h2.6958c.0295-.2949.0469-.5803.0469-.8792 0-3.1336-2.5594-5.6734-5.7176-5.6734-3.1574 0-5.7176 2.5398-5.7176 5.6734s2.5602 5.6732 5.7176 5.6732c1.8901 0 3.5654-.9093 4.6064-2.3112l-2.0312-1.642"
                            id="Fill-41"
                        />
                        <path
                            d="M12.73032 25.8993c-1.715 0-3.10507-1.3791-3.10507-3.08016 0-1.70182 1.39006-3.08166 3.10507-3.08166 1.37385 0 2.53622.88644 2.94596 2.1125.0788.29315.11995.59923.11995.91003 0 .6579-.18354 1.2924-.5227 1.8234-.5616.7953-1.49032 1.316-2.5432 1.316m-.00026-8.7535c-3.15744 0-5.71735 2.5396-5.71735 5.6734 0 .0271.004.0527.004.079L7 22.9242v8.71504h2.65244v-4.0451c.8893.56594 1.94344.89786 3.07763.89786 3.15793 0 5.7176-2.5398 5.7176-5.6729 0-3.13382-2.55967-5.67338-5.7176-5.67338"
                            id="Fill-37"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
