import { CurationState } from '../../types'

export const CHECK_CURATION_UPDATED = 'CHECK_CURATION_UPDATED'

export const LOAD_CURATION = 'LOAD_CURATION'
export const LOAD_CURATION_DONE = 'LOAD_CURATION_DONE'

export const SAVE_CURATION = 'SAVE_CURATION'
export const SAVE_CURATION_DONE = 'SAVE_CURATION_DONE'

export const UPDATE_CURATION = 'UPDATE_CURATION'
export const UPDATE_CURATION_METADATA = 'UPDATE_CURATION_METADATA'

export const REMOVE_CURATION_ITEM = 'REMOVE_CURATION_ITEM'

export const curationInitialState: CurationState = {
    id: null,
    articles: [],
    error: undefined,
}
