import React from 'react'
import { Post } from './post-list'
import { createPost, editPost } from './api'
import {
    EditorState,
    Editor,
    convertFromRaw,
    Modifier,
    CompositeDecorator,
    ContentState,
    BlockMapBuilder,
} from 'draft-js'
import 'draft-js/dist/Draft.css'
import './post-editor.scss'
import { fromDraftJsState, getRawDraftJSBlocks } from './blocks'
import { InlineControls } from './controls/inline-controls'
import { MakeEmbedControl, EmbedComponent } from './controls/embed-control'
import { AddLinkControl, LinkComponent } from './controls/link-control'

const decorator = new CompositeDecorator([
    {
        strategy: (contentBlock, callback, contentState) => {
            contentBlock.findEntityRanges((character) => {
                const entityKey = character.getEntity()
                if (entityKey === null) {
                    return false
                }
                return contentState.getEntity(entityKey).getType() === 'EMBED'
            }, callback)
        },
        component: EmbedComponent,
    },
    {
        strategy: (contentBlock, callback, contentState) => {
            contentBlock.findEntityRanges((character) => {
                const entityKey = character.getEntity()
                if (entityKey === null) {
                    return false
                }
                return contentState.getEntity(entityKey).getType() === 'LINK'
            }, callback)
        },
        component: LinkComponent,
    },
])

export const PostEditor: React.FC<{
    blogId: string
    editingPost?: Post
    cancelEdit: () => void
}> = ({ editingPost, cancelEdit, blogId }) => {
    const [author, setAuthor] = React.useState('')
    const [valid, setValid] = React.useState(false)
    const [editorState, setEditorState] = React.useState(() =>
        editingPost
            ? EditorState.createWithContent(
                  convertFromRaw(getRawDraftJSBlocks(editingPost.content.blocks)),
                  decorator,
              )
            : EditorState.createEmpty(decorator),
    )

    React.useEffect(() => {
        const hasText = editorState.getCurrentContent().hasText()
        setValid(hasText)
    }, [editorState])

    React.useEffect(() => {
        if (editingPost) {
            setEditorState(
                EditorState.createWithContent(
                    convertFromRaw(getRawDraftJSBlocks(editingPost.content.blocks)),
                    decorator,
                ),
            )
            setAuthor(editingPost.content.author)
        }
    }, [editingPost])

    const resetEditor = () => {
        setEditorState(EditorState.createEmpty(decorator))
        cancelEdit()
    }

    return (
        <form>
            <div className="live-blog__status">
                {editingPost ? 'Editing post #' + editingPost.post : ''}
            </div>
            <div className="live-blog__form-row">
                <label htmlFor="author-input" className="live-blog__label form__label">
                    Author
                </label>
                <input
                    id="author-input"
                    className="live-blog__ input"
                    type="text"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                />
            </div>

            <div className="live-blog__form-row live-blog__form-row--stacked">
                <div className="RichEditor-root">
                    <div className="RichEditor-controls">
                        <InlineControls
                            editorState={editorState}
                            updateState={(state) => {
                                setEditorState(state)
                            }}
                        />
                    </div>
                    <div className="RichEditor-controls">
                        <MakeEmbedControl
                            editorState={editorState}
                            updateState={(state) => setEditorState(state)}
                        />
                        <AddLinkControl
                            editorState={editorState}
                            updateState={(state) => {
                                setEditorState(state)
                            }}
                        />
                    </div>
                    <Editor
                        editorState={editorState}
                        onChange={(newEditorState: EditorState) => {
                            setEditorState(newEditorState)
                        }}
                        spellCheck={true}
                        handlePastedText={(text, _html, currentEditorState) => {
                            const pastedBlocks = ContentState.createFromText(
                                // eslint-disable-next-line no-control-regex
                                text.replace(/[^\x00-\xFF]/g, ''), // remove non-ascii characters
                            ).getBlocksAsArray()
                            const newState = Modifier.replaceWithFragment(
                                currentEditorState.getCurrentContent(),
                                currentEditorState.getSelection(),
                                BlockMapBuilder.createFromArray(pastedBlocks),
                            )
                            const newEditorState = EditorState.push(
                                currentEditorState,
                                newState,
                                'insert-fragment',
                            )
                            setEditorState(newEditorState)
                            return 'handled'
                        }}
                    />
                </div>
                <div className="live-blog__buttons">
                    <button
                        type="button"
                        disabled={!author || !valid}
                        className="live-blog__button button button--generic button--small"
                        onClick={() => {
                            const blocks = fromDraftJsState(editorState)
                            if (editingPost) {
                                editPost(editingPost, author, blocks)
                            } else {
                                createPost(blogId, author, blocks)
                            }
                            resetEditor()
                        }}
                    >
                        {editingPost ? 'Update' : 'Submit'}
                    </button>

                    {editingPost && (
                        <button
                            type="button"
                            className="live-blog__button button button--generic button--small"
                            onClick={resetEditor}
                        >
                            Cancel
                        </button>
                    )}
                </div>
            </div>
        </form>
    )
}
