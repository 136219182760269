import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import NoMatches from 'gazette/components/no_matches'
import { CurationList } from 'gazette/components/CurationList/CurationList'
import { ManageCuration } from 'gazette/features/manage-curation/manage-curation'
import { MenuBar } from 'gazette/components/menu_bar/menu_bar'
import { findById } from 'gazette/actions/common'
import DESK_STATE from 'gazette/constants/desks'

import { Desk as DeskType } from 'gazette/types'
import { ManageRedirects } from 'gazette/features/manage-redirects/manage-redirects'
import { UploadFile } from 'gazette/features/upload-file/upload-file'
import { LiveBlog } from 'gazette/features/live-blog/live-blog'

interface Props {
    params: {
        desk: string
    }
}

interface SectionMatchProps {
    params: {
        section: string
    }
}

const Desk: React.FC<Props> = (props) => {
    const { params } = props
    const defaultDesk = findById(DESK_STATE.desks, DESK_STATE.defaultDesk) as DeskType

    const activeDesk = params && params.desk ? findById(DESK_STATE.desks, params.desk) : defaultDesk

    if (!params.desk || !activeDesk) {
        return <Redirect to={`/desk/${DESK_STATE.defaultDesk}`} />
    }

    const collection = activeDesk.defaultCollection
    const deskId = activeDesk.id

    return (
        <div>
            <MenuBar activeDesk={activeDesk} desks={DESK_STATE.desks} />
            <main className="main" role="main">
                <Switch>
                    {/* Redirect both / and /curations to /curations/<defaultCollection> */}
                    <Route
                        exact
                        path={`/desk/${deskId}`}
                        render={() => <Redirect to={`/desk/${deskId}/curations/${collection}`} />}
                    />
                    <Route
                        exact
                        path={`/desk/${deskId}/upload-file`}
                        render={() => <UploadFile desk={activeDesk} />}
                    />

                    <Route
                        path={`/desk/${deskId}/live-blogs`}
                        render={() => <LiveBlog desk={activeDesk} />}
                    />
                    <Route
                        exact
                        path={`/desk/${deskId}/curations`}
                        render={() => <Redirect to={`/desk/${deskId}/curations/${collection}`} />}
                    />
                    {/* End redirect both / and /curations to /curations/<defaultCollection> */}

                    <Route
                        path={`/desk/${deskId}/curations/:section`}
                        render={({ match }: { match: SectionMatchProps }) => (
                            <React.Fragment>
                                <CurationList desk={activeDesk} />

                                <ManageCuration desk={activeDesk} {...match} />
                            </React.Fragment>
                        )}
                    />

                    <Route
                        exact
                        path={`/desk/${deskId}/redirects`}
                        render={() => <ManageRedirects desk={activeDesk} />}
                    />

                    <Route component={NoMatches} />
                </Switch>
            </main>
        </div>
    )
}

export default Desk
