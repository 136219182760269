import {
    LoadStoriesAction,
    GazetteAction,
    ErrorAction,
    LoadTopicsAction,
    TaxonomyTopicsResponse,
    SearchState,
} from 'gazette/types'
import { Dispatch, combineReducers } from 'redux'
import { checkStatus } from 'gazette/actions/common'
import topicsReducer from './reducers/topics'
import storiesReducer from './reducers/stories'
import { PublicationListingV4ResponseDTO } from '@west-australian-newspapers/publication-types'
import { CLEAR_STORIES, LOAD_STORIES, LOAD_TOPICS } from 'gazette/features/search'

export const dispatchTopics = (
    data: TaxonomyTopicsResponse | Error,
    isError = false,
): LoadTopicsAction | ErrorAction => {
    if (isError) {
        return {
            type: LOAD_TOPICS,
            payload: data as Error,
            error: isError,
        }
    }

    return {
        type: LOAD_TOPICS,
        payload: data as TaxonomyTopicsResponse,
        error: isError,
    }
}

export const fetchTopics = (api: string) => (dispatch: Dispatch<GazetteAction>) => {
    dispatch({
        type: LOAD_TOPICS,
        error: false,
    })

    fetch(`${api}/taxonomy/topics`, { credentials: 'same-origin' })
        .then(checkStatus)
        .then((resp) => resp.json() as Promise<TaxonomyTopicsResponse>)
        .then((data) => dispatch(dispatchTopics(data)))
        .catch((err) => dispatch(dispatchTopics(err, true)))
}

/**
 * Action to add story data to state
 *
 * @param  {object}  data - A list of articles
 * @param  {boolean} [isError=false] - Flag whether action should process an error
 * @return {object}  Action object for reducers
 */
export const loadStories = (
    data: PublicationListingV4ResponseDTO | Error,
): LoadStoriesAction | ErrorAction => {
    if (data instanceof Error) {
        return {
            type: LOAD_STORIES,
            payload: data,
            error: true,
        }
    }

    return {
        type: LOAD_STORIES,
        payload: data,
        error: false,
    }
}

export const clearStories = () => ({
    type: CLEAR_STORIES,
})

export type SyndicationId = 'AAP' | 'Newscorp'

export interface FetchStoriesOptions {
    search: string
    topics: string[]
    includeFuturePublications: boolean
    includePremium?: boolean
    premiumOnly?: boolean
    excludedSyndications?: SyndicationId[]
    requiredSyndications?: SyndicationId[]
}

export function buildFetchQuery(options: FetchStoriesOptions): string {
    // Returns string representation of true/false. Undefined if we prefer the query not to appear
    const stringyBool = (bool: boolean, always?: boolean): string | undefined => {
        if (!bool && !always) {
            return undefined
        }

        return bool ? 'true' : 'false'
    }
    const { includePremium = false, premiumOnly = false } = options

    const query = {
        page: '1',
        page_size: '100',
        idOrKeyword: options.search,
        topics: options.topics,
        includeFuture: stringyBool(options.includeFuturePublications),
        include_premium: stringyBool(includePremium || premiumOnly, true),
        premium_only: stringyBool(premiumOnly),
        exclude_syndication_source: options.excludedSyndications,
        include_syndication_source: options.requiredSyndications,
        include_expired: 'true',
        includeDead: 'true',
    }

    const queryArray = Object.keys(query).reduce((accumulator: string[], current: string) => {
        const queryOption = (query as any)[current]

        if (typeof queryOption === 'undefined') {
            return accumulator
        }

        if (Array.isArray(queryOption)) {
            if (queryOption.length === 0) {
                return accumulator
            }

            return [...accumulator, ...queryOption.map((value) => `${current}=${value}`)]
        }

        return [...accumulator, `${current}=${queryOption}`]
    }, [])

    return queryArray.join('&')
}

/**
 * Action to fetch articles from the Content API
 * @param  {string} [query=''] API query parameters
 * @return {Promise} Store dispatch event
 */
export const fetchStories = (api: string, options: FetchStoriesOptions) => (
    dispatch: Dispatch<GazetteAction>,
) => {
    dispatch({
        type: LOAD_STORIES,
    })

    const query = buildFetchQuery(options)

    return fetch(`${api}/v4/publication?${encodeURI(query)}`, { credentials: 'same-origin' })
        .then(checkStatus)
        .then((resp) => resp.json() as Promise<PublicationListingV4ResponseDTO>)
        .then((data) => dispatch(loadStories(data)))
        .catch((err) => dispatch(loadStories(err)))
}

export default combineReducers<SearchState>({
    topics: topicsReducer,
    stories: storiesReducer,
})
