import React, { FC } from 'react'
import { ClassificationV4DTO } from '@west-australian-newspapers/publication-types'
import { SponsoredIcon } from 'gazette/icons/sponsored_icon'

import './ClassificationLabel.scss'

interface ClassificationLabelProps {
    classification: ClassificationV4DTO | undefined
    isSponsored: boolean
}

export const ClassificationLabel: FC<ClassificationLabelProps> = (props) => {
    const { classification, isSponsored } = props

    if (!isSponsored && !classification) {
        return null
    }

    if (!isSponsored && classification && classification.kind !== 'sales') {
        return (
            <div className="story__classification-wrapper">
                <strong>{classification.label}</strong>
            </div>
        )
    }

    return (
        <div className="story__classification-wrapper scw--sponsored">
            <SponsoredIcon />
            <strong>
                {classification && classification.label ? classification.label : 'Sponsored'}
            </strong>
        </div>
    )
}
ClassificationLabel.displayName = 'ClassificationLabel'
