import React from 'react'
import { connect, InferThunkActionCreatorType } from 'react-redux'
import ArticleSearch from 'gazette/features/search/article_search'
import NoMatches from 'gazette/components/no_matches'
import { Curation } from './curation/curation'

import { GazetteState, CurationState, Desk } from 'gazette/types'
import {
    checkCurationUpdated,
    fetchCuration,
    updateCuration,
    removeCurationItem,
    updateCurationMetadata,
} from 'gazette/features/manage-curation/manage-curation.redux'
import { CurationV4InfoDTO } from '@west-australian-newspapers/publication-types'

interface OwnProps {
    desk: Desk
    params: {
        section?: string
    }
}

interface DispatchProp {
    checkCurationUpdated: InferThunkActionCreatorType<typeof checkCurationUpdated>
    fetchCuration: InferThunkActionCreatorType<typeof fetchCuration>
    removeCurationItem: InferThunkActionCreatorType<typeof removeCurationItem>
    updateCuration: InferThunkActionCreatorType<typeof updateCuration>
    updateCurationMetadata: InferThunkActionCreatorType<typeof updateCurationMetadata>
}

interface StateProps {
    curationInfo?: CurationV4InfoDTO
    curation: CurationState
}
interface Props extends OwnProps, DispatchProp, StateProps {}

export class ManageCurationRaw extends React.Component<Props> {
    static displayName = 'ManageCurationRaw'

    pollTimeout: any // conflicting types for timeouts in browser/node

    componentWillMount() {
        const { desk } = this.props

        if (!this.props.curationInfo) {
            return
        }
        this.props.fetchCuration(desk.api, desk.id, this.props.curationInfo.id)
        clearTimeout(this.pollTimeout)
        this.pollTimeout = setTimeout(this.pollUpdates, 10000)
    }

    componentWillReceiveProps(nextProps: Props) {
        const { desk } = nextProps

        if (!nextProps.curationInfo) {
            return
        }

        if (
            (!this.props.curationInfo && nextProps.curationInfo) ||
            this.props.curationInfo?.id !== nextProps.curationInfo.id
        ) {
            clearTimeout(this.pollTimeout)
            this.props.fetchCuration(desk.api, desk.id, nextProps.curationInfo.id).then(() => {
                this.pollTimeout = setTimeout(this.pollUpdates, 10000)
            })
        }
    }

    componentWillUnmount() {
        clearTimeout(this.pollTimeout)
    }

    pollUpdates = () => {
        const { desk, curationInfo } = this.props

        if (!curationInfo) {
            return
        }
        this.props.checkCurationUpdated(desk.api, curationInfo.id, desk.id).then(() => {
            this.pollTimeout = setTimeout(this.pollUpdates, 10000)
        })
    }

    render() {
        const { curation, desk, curationInfo } = this.props
        if (!curationInfo) {
            return <NoMatches />
        }

        return (
            <React.Fragment>
                <Curation
                    desk={desk}
                    curation={curation}
                    removeCurationItem={this.props.removeCurationItem}
                    updateCuration={this.props.updateCuration}
                    updateCurationMetadata={this.props.updateCurationMetadata}
                />
                <ArticleSearch desk={desk} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (
    { curations, curation: currentCuration }: GazetteState,
    { params }: OwnProps,
): StateProps => {
    const curationInfo =
        curations.data && !curations.data.loading
            ? curations.data.list.filter((curation) => curation.id === (params.section || '1'))[0]
            : undefined
    return {
        curationInfo,
        curation: currentCuration,
    }
}

export const ManageCuration = connect(mapStateToProps, {
    checkCurationUpdated,
    fetchCuration,
    removeCurationItem,
    updateCuration,
    updateCurationMetadata,
})(ManageCurationRaw)
