import { Post } from './post-list'
import { Blog } from './live-blog'
import { config } from 'gazette/config'

export async function getPosts(blogId: string): Promise<{ items: Post[] }> {
    const url = `${config.liveBlogUri}/blog/${blogId}/posts`
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    })
    const responseBody = await response.json()

    return responseBody
}

export async function getBlogs(masthead: string): Promise<Blog[]> {
    const response = await fetch(`${config.liveBlogUri}/blogs/${masthead}`)

    if (response.status === 200) {
        const result = await response.json()

        return result.items
    }

    return []
}

export async function getBlog(masthead: string, blogId: string): Promise<Blog | null> {
    const response = await fetch(`${config.liveBlogUri}/blogs/${masthead}/${blogId}`)

    if (response.status === 200) {
        const result = await response.json()

        return result
    }

    return null
}

export async function createBlog(masthead: string, name: string) {
    return fetch(`${config.liveBlogUri}/blogs/${masthead}`, {
        method: 'POST',
        body: JSON.stringify({
            name,
        }),
        ...(config.liveBlogApiKey && { headers: { 'X-Api-Key': config.liveBlogApiKey } }),
    })
}

export async function createPost(blogId: string, author: string, blocks: any[]) {
    const url = `${config.liveBlogUri}/blog/${blogId}/posts`
    const content = {
        posted: new Date().toISOString(),
        blocks,
        author,
        version: 2,
    }

    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(content),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...(config.liveBlogApiKey && { 'X-Api-Key': config.liveBlogApiKey }),
        },
    })

    const responseBody = await response.json()

    const post: Post = {
        id: blogId,
        post: responseBody.postId,
        content,
    }
    return post
}

export async function editPost(post: Post, author: string, blocks: any[]) {
    const blogId = post.id
    const postId = post.post

    const url = `${config.liveBlogUri}/blog/${blogId}/posts/${postId}`
    const content = {
        posted: post.content.posted,
        blocks,
        author,
        version: 2,
    }
    const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(content),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Api-Key': config.liveBlogApiKey || '',
        },
    })
    await response.json()
    const edited: Post = {
        id: blogId,
        post: postId,
        content,
    }
    return edited
}
