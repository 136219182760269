import React from 'react'
import { MenuBarSimple } from '../components/menu_bar/menu_bar'
import { Desk } from 'gazette/types'
import { checkStatus, findById } from 'gazette/actions/common'
import DESK_STATE from 'gazette/constants/desks'
import { EdmStory } from './edm_story'

import { ListArticleV4DTO } from '@west-australian-newspapers/publication-types'

const ERROR_NO_ACTIVE_DESK = 'Could not retrieve the active Desk'

interface Props {
    params: {
        desk: string
        curationid: string
    }
}

interface State {
    data: any // gave up trying to type this
    error: string | null
    success: string | null
    activeDesk: Desk | undefined
}

export class Edm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        const defaultDesk = findById(DESK_STATE.desks, DESK_STATE.defaultDesk)

        const activeDesk: Desk | undefined =
            this.props.params && this.props.params.desk
                ? findById(DESK_STATE.desks, this.props.params.desk)
                : defaultDesk

        this.state = { data: null, error: null, success: null, activeDesk }
    }

    componentWillMount() {
        this.getCurationData()
    }
    getCurationData() {
        const activeDesk = this.state.activeDesk
        if (activeDesk) {
            fetch(
                `${activeDesk.api}/v4/curation/${this.props.params.curationid}?includeFuture=true&expand=articles&include_premium=true`,
                { credentials: 'same-origin' },
            )
                .then(checkStatus)
                .then((resp) => resp.json() as Promise<any>)
                .then((data) => {
                    this.setState({ data })
                })
                .catch((error) => {
                    this.setState({ error })
                    // tslint:disable-next-line:no-console
                    console.error(error)
                })
        } else {
            this.setState({
                error: ERROR_NO_ACTIVE_DESK,
            })
        }
    }

    articles() {
        if (!this.state.data) {
            return <div>no articles</div>
        }
        if (!this.state.activeDesk) {
            return <div>no active desk</div>
        }

        const articles: any[] = []

        this.state.data.articles.forEach((article: ListArticleV4DTO) => {
            articles.push(
                <EdmStory
                    desk={this.state.activeDesk as Desk}
                    publication={article}
                    hasCopied={false}
                    key={article.id}
                />,
            )
        })

        return articles
    }

    render() {
        if (!this.state.data) {
            return (
                <main>
                    <MenuBarSimple />
                    <div className="welcome__container">loading...</div>
                </main>
            )
        }
        return (
            <main>
                <MenuBarSimple />
                <section className="collection" data-outdated={false}>
                    <header className="collection__header">
                        <h1 className="collection__heading">EDM Links</h1>
                    </header>
                    <p className="lead">
                        Use the 'Copy' button to copy info to the clipboard for pasting into the
                        EDM.
                    </p>
                    <h2 className="collection__list--heading">
                        {this.props.params.curationid} Curation
                    </h2>
                    <div className="collection__list--edm">{this.articles()}</div>
                </section>
            </main>
        )
    }
}
