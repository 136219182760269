import React from 'react'

export const NewsdeskIcon = () => (
    <svg
        className="svg svg--newsdesk"
        fill="#fff"
        fillRule="evenodd"
        viewBox="0 0 16 12"
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* tslint:disable-next-line max-line-length */}
        <path d="M13.58 9.06H9.66v-.9h3.92v.9zm0-1.8H9.66V6.03h3.92v1.2zm0-2.13H9.66v-.9h3.92v.9zm0-1.8H9.66V2.1h3.92v1.22zm-5.73 0H4.83v2.7h3.02v-2.7zm.9 5.73H3.92v-.9h4.83v.9zm0-1.8H3.92V2.1h4.83v5.15zm5.74 2.3V1.22H2.7v8.37c0 .17.1.33.05.4h11.3c.14 0 .26.04.35-.06.1-.1.15-.2.15-.33h-.08zm-12.7 0V2.12H.9v7.47c0 .13.05.24.15.34.1.1.2.15.34.15.12 0 .24-.04.34-.14.1-.1.14-.2.14-.33H1.8zM15.4.3v9.27c0 .4 0 .75-.28 1.03-.3.3-.63.57-1.04.57H1.38c-.4 0-.74-.28-1.02-.57-.3-.28-.36-.63-.36-1.03V1.2h1.8V.3h13.6z" />
    </svg>
)
