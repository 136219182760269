import rawConfig from './config'

export interface GazetteConfig {
    contentEditor: string | undefined
    cueBaseUrl: string
    liveBlogApiKey: string | undefined
    liveBlogSocket: string | undefined
    liveBlogUri: string | undefined
    perthnowBaseUrl: string
    perthnowCurationApi: string
    pixelAndInkBaseUrl: string
    pixelAndInkCurationApi: string
    sevennewsBaseUrl: string
    sevennewsCurationApi: string
    stsLoginUrl: string
    theWestBaseUrl: string
    theWestCurationApi: string
    trackJsToken: string | false
}

export const config = (rawConfig as unknown) as GazetteConfig
