import React from 'react'
import classnames from 'classnames'

import 'gazette/components/ItemContainer/ItemContainer.scss'

export interface Props {
    className: string
    draggable: boolean
    onDragStart?: (event: React.DragEvent<HTMLDivElement>) => void
    onMouseLeave?: () => void

    children: React.ReactNode
}

export const ItemContainer: React.FC<Props> = (props) => {
    return (
        <div
            className={classnames('item__container', props.className)}
            draggable={props.draggable}
            onDragStart={props.onDragStart}
            onMouseLeave={props.onMouseLeave}
        >
            {props.children}
        </div>
    )
}
ItemContainer.displayName = 'ItemContainer'
