import React from 'react'
import { StatusFlag, StatusFlagProps } from 'gazette/components/StatusFlag/StatusFlag'
import cx from 'classnames'
import { ImageV4DTO } from '@west-australian-newspapers/publication-types'
import imgPlaceholder from 'gazette/images/img-placeholder.png'
import { ImageSelection, pickImage } from 'gazette/utils/pick-image'

interface StoryImageProps {
    miniMode?: boolean
    images: ImageV4DTO[]
    label: string
    status?: StatusFlagProps['status']
    draggable: boolean
}

const determineDisplayImage = (
    mainImage: ImageSelection | undefined,
    query: string,
): { reference: string } => {
    let reference = imgPlaceholder

    if (mainImage) {
        reference = mainImage.image.src
    }

    return { reference: `${reference}${query}` }
}

export const StoryImage: React.FC<StoryImageProps> = ({
    miniMode,
    draggable,
    images,
    label,
    status,
}) => {
    const mainImage = pickImage([{ width: 16, height: 9 }], images)
    const displayImage = determineDisplayImage(mainImage, '?imwidth=100&impolicy=.auto')

    return (
        <div className={cx('story__image-container')}>
            <img
                alt=""
                className={cx('story__image', { 'story__image-nopos': miniMode })}
                draggable={miniMode && draggable}
                src={displayImage.reference}
            />
            {miniMode && <StatusFlag text={label} status={status} />}
        </div>
    )
}
StoryImage.displayName = 'StoryImage'
