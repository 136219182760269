import React from 'react'
import { NavLink } from 'react-router-dom'
import { Location } from 'history'
import CollectionsIcon from 'gazette/icons/collections_icon'
import TaxonomyIcon from 'gazette/icons/taxonomy_icon'
import RedirectsIcon from 'gazette/icons/redirects_icon'

import { Desk } from 'gazette/types'

import './main_navigation.scss'
import { UploadFileIcon } from 'gazette/icons/upload_file_icon'
import { config } from 'gazette/config'

interface Props {
    desk: Desk
}

const MainNavigation: React.FC<Props> = ({ desk }) => (
    <nav className="nav nav--main">
        <ul>
            <li className="nav__item">
                <NavLink
                    activeClassName="nav__link--active"
                    className="nav__link icon icon--curations"
                    isActive={(_match: any, location: Location) =>
                        // Match exactly / or starts with /collection
                        !!location.pathname.match(/(\/{1}$|\/curations)/)
                    }
                    to={`/desk/${desk.id}/curations`}
                >
                    <CollectionsIcon />
                    <span>Curations</span>
                </NavLink>
            </li>
            <li className="nav__item">
                <a
                    className="nav__link icon icon--taxonomy"
                    href="https://taxonomy-editor.swmdigital.io/"
                >
                    <TaxonomyIcon />
                    <span>Taxonomy</span>
                </a>
            </li>
            <li className="nav__item">
                <NavLink
                    activeClassName="nav__link--active"
                    className="nav__link icon icon--redirects"
                    to={`/desk/${desk.id}/redirects`}
                >
                    <RedirectsIcon />
                    <span>Redirects</span>
                </NavLink>
            </li>
            <li className="nav__item">
                <NavLink
                    activeClassName="nav__link--active"
                    className="nav__link icon icon--taxonomy"
                    to={`/desk/${desk.id}/upload-file`}
                >
                    <UploadFileIcon />
                    <span>File Upload</span>
                </NavLink>
            </li>

            {config.liveBlogUri && (
                <li className="nav__item">
                    <NavLink
                        activeClassName="nav__link--active"
                        className="nav__link icon icon--live-blogs"
                        to={`/desk/${desk.id}/live-blogs`}
                    >
                        <span>Live Blogs</span>
                    </NavLink>
                </li>
            )}
        </ul>
    </nav>
)

export default MainNavigation
