import React from 'react'
import classnames from 'classnames'
import { Story } from 'gazette/components/story/story'

import { Desk, UpdateCurationActionParam } from 'gazette/types'
import { ListPublicationV4DTO } from '@west-australian-newspapers/publication-types'

import './curation_slot.scss'

interface Props {
    desk: Desk
    item?: ListPublicationV4DTO
    index: number
    removeCurationItem: (id: string) => Promise<any>
    updateCuration: (update: UpdateCurationActionParam) => Promise<any>
}

interface State {
    parentOver: boolean
    childOver: boolean
}

export class CollectionSlot extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            parentOver: false,
            childOver: false,
        }
    }

    handleOnDragOver: React.EventHandler<React.DragEvent<HTMLDivElement>> = (e) => {
        e.preventDefault()

        if (e.currentTarget.querySelector('story')) {
            return this.setState({ childOver: true })
        }

        return this.setState({ parentOver: true })
    }

    handleOnDragLeave: React.EventHandler<React.DragEvent<HTMLDivElement>> = (e) => {
        e.preventDefault()

        this.setState({
            parentOver: false,
            childOver: false,
        })
    }

    handleOnDrop: React.EventHandler<React.DragEvent<HTMLDivElement>> = (e) => {
        e.preventDefault()
        const article = JSON.parse(e.dataTransfer.getData('text')) as ListPublicationV4DTO

        this.props.updateCuration({
            index: this.props.index - 1,
            article,
        })

        this.setState({
            parentOver: false,
            childOver: false,
        })
    }
    removeStory: React.EventHandler<React.SyntheticEvent<any>> = (e) => {
        e.preventDefault()
        if (this.props.item) {
            this.props.removeCurationItem(this.props.item.id)
        }
    }

    render() {
        const { item, index, desk } = this.props
        const { childOver, parentOver } = this.state
        const hasStory = !!item
        const isLive = item && item.status === 'live'
        const isFuturePublication =
            isLive && item && new Date(item.publicationDate).valueOf() > Date.now()
        const isActive = isLive && hasStory
        const isDead = !isLive
        const isPremium = item && item.kind === 'article' && item.isPremium

        const classes = classnames({
            'collection-slot': true,
            'collection-slot--active': isActive,
            'collection-slot--dead': isDead,
            'collection-slot--future-publication': isFuturePublication,
            'collection-slot--premium': isPremium,
        })
        const postionClasses = classnames({
            'collection-slot__position': true,
            'collection-slot__position--empty': !hasStory,
            'collection-slot__position--active': hasStory,
            'collection-slot__position--drag-over': parentOver,
        })
        const storyClasses = classnames({
            story: true,
            'story--drag-over': childOver,
        })

        return (
            <div className={classes}>
                <div className="collection-slot__marker">{index}</div>
                <div
                    className={postionClasses}
                    onDragLeave={this.handleOnDragLeave}
                    onDragOver={this.handleOnDragOver}
                    onDrop={this.handleOnDrop}
                    draggable
                >
                    {item ? (
                        <Story
                            className={storyClasses}
                            removeStory={this.removeStory}
                            desk={desk}
                            publication={item}
                            enableDragDrop={true}
                        />
                    ) : (
                        <span>EMPTY &ndash; PLACE STORY HERE</span>
                    )}
                </div>
            </div>
        )
    }
}

export default CollectionSlot
