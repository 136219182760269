import React from 'react'

const PublishIcon = () => (
    <svg fillRule="evenodd" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        {/* tslint:disable-next-line max-line-length */}
        <path d="M19.68 10.6c.18-.2.28-.42.28-.7 0-.28-.1-.5-.28-.7l-1.42-1.35c-.2-.2-.42-.3-.7-.3-.27 0-.5.1-.7.3L10.53 14l-3.5-3.4c-.2-.2-.44-.3-.7-.3-.28 0-.5.1-.7.3l-1.43 1.35c-.2.18-.28.42-.28.7 0 .27.1.5.27.68l5.62 5.5c.2.18.43.27.7.27.28 0 .52-.1.72-.28l8.44-8.23zm2.6-4.08c1.06 1.78 1.6 3.73 1.6 5.84 0 2.1-.54 4.06-1.6 5.84-1.07 1.8-2.52 3.2-4.35 4.24-1.83 1.04-3.83 1.56-6 1.56-2.16 0-4.16-.52-5.98-1.56C4.12 21.4 2.67 20 1.6 18.2.53 16.42 0 14.47 0 12.36c0-2.1.53-4.06 1.6-5.84 1.07-1.78 2.52-3.2 4.35-4.24 1.82-1.04 3.82-1.56 6-1.56 2.15 0 4.15.52 5.98 1.56s3.28 2.46 4.35 4.24z" />
    </svg>
)

export default PublishIcon
