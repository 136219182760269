import React from 'react'
import { GazetteFieldProps } from './metadata_item'
import { NumberDefinition } from '../data'

export const MetadataNumber: React.FC<GazetteFieldProps<NumberDefinition>> = ({
    field,
    updateCurationMetadata,
    currentValue,
}) => (
    <input
        className="form__input form__input--text"
        type="number"
        name={field.name}
        id={field.name}
        onChange={(e) => {
            updateCurationMetadata(parseInt(e.target.value, 0))
        }}
        value={currentValue}
    />
)

MetadataNumber.displayName = 'MetadataNumber'
