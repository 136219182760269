import React from 'react'

const CheveronIcon = () => (
    <svg
        className="svg svg--chevron"
        fill="#fff"
        fillRule="evenodd"
        viewBox="0 0 16 10"
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* tslint:disable-next-line max-line-length */}
        <path d="M15.36 1.12c.1.1.15.22.15.35 0 .14-.04.26-.14.36L8.1 9.08c-.1.1-.2.15-.35.15-.13 0-.25-.05-.35-.15L.15 1.83C.05 1.73 0 1.6 0 1.47c0-.13.05-.25.15-.35L.93.34c.1-.1.23-.16.36-.16.12 0 .24.05.35.16l6.1 6.1 6.1-6.1c.12-.1.23-.16.37-.16.13 0 .25.05.36.16l.78.78z" />
    </svg>
)

export default CheveronIcon
