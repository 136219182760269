import React from 'react'
import { DropdownIcon } from 'gazette/icons/dropdown_icon'

import './ItemActions.scss'

interface Props {
    children: React.ReactNode
    isOpen?: boolean
    handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export class ItemActions extends React.Component<Props> {
    render() {
        return (
            <div className="ItemActions-wrapper">
                <button
                    className="button button--icon story__button story__button--dropdown"
                    onClick={this.props.handleClick}
                    type="button"
                >
                    <DropdownIcon />
                    <span className="sr-only">Dropdown</span>
                </button>
                {this.props.isOpen && (
                    <div className="dropdown ItemActions">{this.props.children}</div>
                )}
            </div>
        )
    }
}
