export default {
    contentEditor:
        'https://s3-ap-southeast-2.amazonaws.com/static.swmdigital.io/editor/dev/editor.min.js',
    cueBaseUrl: 'https://test-cue.wanews.com.au',
    liveBlogApiKey: '9696e3b0fb7e2f64dafd77e7f120854ca17a99631d8705c2c208589006ce9c81',
    liveBlogSocket: 'wss://84j6vquyqh.execute-api.ap-southeast-2.amazonaws.com/api',
    liveBlogUri: 'https://vc975cm7t1.execute-api.ap-southeast-2.amazonaws.com/stage',
    perthnowBaseUrl: 'https://dev-perthnow.swmdigital.io',
    perthnowCurationApi: 'https://elb-dev-perthnow-batc-cura.swmdigital.io',
    sevennewsBaseUrl: 'https://dev-sevennews.swmdigital.io',
    sevennewsCurationApi: 'https://elb-dev-sevennews-batc-cura.swmdigital.io',
    stsLoginUrl:
        'https://sts.wanews.com.au/adfs/ls/IdpInitiatedSignon.aspx?loginToRp=https%3A%2F%2Fjenkins.swmdigital.io%2FsecurityRealm%2FfinishLogin',
    theWestBaseUrl: 'https://dev-thewest.swmdigital.io',
    theWestCurationApi: 'https://elb-dev-thewest-claf-www.swmdigital.io',
    trackJsToken: false,
}
