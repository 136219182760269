import React from 'react'
import { EditorState, Modifier, AtomicBlockUtils, SelectionState } from 'draft-js'

export const MakeEmbedControl: React.FC<{
    editorState: EditorState
    updateState: (state: EditorState) => void
}> = ({ editorState, updateState }) => {
    return (
        <button
            type="button"
            className="RichEditor-styleButton"
            disabled={editorState.getSelection().isCollapsed()}
            onClick={() => {
                const contentState = editorState.getCurrentContent()
                const selectionState = editorState.getSelection()

                const currentContentBlock = contentState.getBlockForKey(
                    selectionState.getAnchorKey(),
                )
                const start = selectionState.getStartOffset()
                const end = selectionState.getEndOffset()
                const selectedText = currentContentBlock.getText().slice(start, end)

                // First create an immutable entry, this makes the selected text a entity which cannot be edited
                // (ie, if you backspace it, the whole thing gets deleted, rather than part of the text)
                const contentStateWithEntity = contentState.createEntity('EMBED', 'IMMUTABLE')
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
                // This applies the immutable entry to the current selection
                const newContentState = Modifier.applyEntity(
                    contentStateWithEntity,
                    selectionState,
                    entityKey,
                )
                const newEditorState = EditorState.push(
                    editorState,
                    newContentState,
                    'apply-entity',
                )

                // Now we insert an atomic block, this breaks the text into 3 blocks so the embed stands in it's own block
                const editorStateWithNewBlock = AtomicBlockUtils.insertAtomicBlock(
                    newEditorState,
                    entityKey,
                    selectedText,
                )

                const newContent = editorStateWithNewBlock.getCurrentContent()
                const currentAtomicBlock = newContent.getBlockMap().find((block) => {
                    return !!block && block.getEntityAt(0) === entityKey
                })
                const rangeToReplace = new SelectionState({
                    anchorKey: currentAtomicBlock.getKey(),
                    focusKey: currentAtomicBlock.getKey(),
                })
                // Update the type of the block to be an embed, rather than an `atomic` type
                const contentStateWithBlock = Modifier.setBlockType(
                    newContent,
                    rangeToReplace,
                    'EMBED',
                )

                const newEditorState2 = EditorState.push(
                    editorState,
                    contentStateWithBlock,
                    'change-block-type',
                )
                updateState(newEditorState2)
            }}
        >
            Make Embed
        </button>
    )
}

export const EmbedComponent: React.FC = ({ children }) => (
    <span className="RichEditor-embed">{children}</span>
)
