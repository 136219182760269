import React from 'react'

export const DropdownIcon: React.FC = () => (
    <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <rect height="16" id="dropdownicon-a" rx="3" width="16" />
            <mask fill="#fff" id="dropdownicon-b" height="16" width="16" x="0" y="0">
                <use xlinkHref="#dropdownicon-a" />
            </mask>
        </defs>
        <g fill="none" fillRule="evenodd">
            <use
                fill="#fff"
                stroke="#c3c3c3"
                strokeWidth="2"
                mask="url(#dropdownicon-b)"
                xlinkHref="#dropdownicon-a"
            />
            {/* tslint:disable max-line-length */}
            <path
                fill="#c3c3c3"
                d="M8.5 10.72c-.25.24-.67.24-.93 0l-3.3-3.26c-.26-.24-.26-.65 0-.9l.37-.38c.25-.24.67-.24.92 0L8.04 8.6l2.46-2.4c.26-.24.68-.24.93 0l.38.38c.27.25.27.66 0 .9l-3.3 3.26z"
            />
        </g>
    </svg>
)
