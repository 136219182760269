import { GazetteAction, CurationState } from 'gazette/types'
import {
    LOAD_CURATION,
    LOAD_CURATION_DONE,
    curationInitialState,
} from 'gazette/features/manage-curation'
import {
    curationMetadataDefinitions,
    CollectionMetadata,
} from 'gazette/features/manage-curation/curation_metadata/data'
import { serialiseCurationStory } from '../curation_metadata/components/metadata_story'
import { CurationMetaData } from '@west-australian-newspapers/publication-types'

/**
 * Checks the existing metadata against the defined metadata and removes the items that don't match
 * @param {string} curationId The id of the curation
 * @param {*} currentMetadata The metadata currently saved against the curation
 * @param {*} definedMetadata The metadata defintions in components/collection_metadata/data.ts
 */
const filterObsoleteMetadata = (
    curationId: string,
    deskId: string,
    currentMetadata: { [key: string]: any },
    definedMetadata: CollectionMetadata,
): Record<string, CurationMetaData> => {
    const definedMetadataCommon = definedMetadata.common || []
    const deskDefinedMetadata = definedMetadata.targeted && definedMetadata.targeted[deskId]
    const definedMetadataTargeted = (deskDefinedMetadata && deskDefinedMetadata[curationId]) || []
    const combinedDefinedMetadata = definedMetadataCommon.concat(definedMetadataTargeted)
    const newMetadata: Record<string, CurationMetaData> = {}

    Object.keys(currentMetadata).forEach((key: any) => {
        const typedKey: keyof CollectionMetadata = key
        const definition = combinedDefinedMetadata.find((def) => def.name === key)
        if (definition) {
            if (definition.inputType === 'story') {
                newMetadata[typedKey] = {
                    ...currentMetadata[typedKey],
                    toJSON: serialiseCurationStory,
                }
            } else {
                newMetadata[typedKey] = currentMetadata[typedKey]
            }
        }
    })

    return newMetadata
}

export const loadCurationReducer = (
    state = curationInitialState,
    action: GazetteAction,
): CurationState => {
    switch (action.type) {
        case LOAD_CURATION: {
            if (action.error) {
                return {
                    ...state,
                    error: action.payload.message,
                    data: undefined,
                }
            }
            return {
                ...state,
                id: action.meta.id,
                data: { loading: true },
            }
        }
        case LOAD_CURATION_DONE: {
            if (action.error) {
                return {
                    ...state,
                    error: action.payload.message,
                    data: undefined,
                }
            }

            const metadata = filterObsoleteMetadata(
                action.payload.id,
                action.meta.desk,
                action.payload.metadata,
                curationMetadataDefinitions,
            )

            const meta = action.meta || {}

            return {
                ...state,
                articles: action.payload.articles,
                data: { loading: false, curation: action.payload },
                desk: meta.desk,
                metadata,
                lastUpdatedWhenLoaded: action.payload.lastUpdated,
                lastCheckedForUpdate: new Date(),
                curationSlug: action.payload.title
                    .toLowerCase()
                    .replace(' ', '-')
                    .replace('&', 'and'),
            }
        }

        default:
            return state
    }
}
