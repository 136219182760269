import React from 'react'

interface Props {
    name: string
    label: string
}

const MetadataLabel: React.FC<Props> = ({ label, name }) => (
    <label className="form__label" htmlFor={name}>
        {label}
    </label>
)

export default MetadataLabel
