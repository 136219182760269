import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'

import { fetchStories, fetchTopics, FetchStoriesOptions, SyndicationId } from '../search.redux'

import { Desk, GazetteState, TaxonomyTopicsResponse } from 'gazette/types'

import './article_search_container.scss'
import { SearchIcon } from './components/SearchIcon'
import { HelpIcon } from './components/HelpIcon'
import { ResetIcon } from './components/ResetIcon'
import {
    SelectOption,
    SelectOptionActionMeta,
} from 'gazette/features/manage-curation/curation_metadata/data'
import { ActionMeta, ValueType, GroupType } from 'react-select'

interface OwnProps {
    desk: Desk
}

interface Props extends OwnProps {
    topics: TaxonomyTopicsResponse
    fetchStories: (api: string, options: FetchStoriesOptions) => void
    fetchTopics: (api: string) => void
}

interface State {
    topicValue: SelectOption[]
    searchValue: string
    filterValues: FilterOption[]
}

// Exported for tests
export interface FilterOption {
    value: string
    label: string
    toggles?: string
}
const filterStorageKey = (deskId: string) => `${deskId}-filter-values`
const topicStorageKey = (deskId: string) => `${deskId}-topic-values`

function filtersByDesk(deskId: string) {
    const filters: Array<GroupType<FilterOption>> = [
        {
            label: 'Future publications',
            options: [
                {
                    label: 'Include future publications in results',
                    value: 'includeFuture',
                },
            ],
        },
        ...(deskId === '7news'
            ? [
                  {
                      label: 'AAP',
                      options: [
                          {
                              label: 'Exclude AAP',
                              value: 'excludeAAP',
                              toggles: 'AAPOnly',
                          },
                          {
                              label: 'AAP Only',
                              value: 'AAPOnly',
                              toggles: 'excludeAAP',
                          },
                      ],
                  },
              ]
            : []),
        ...(deskId === 'perthnow' || deskId === 'the-west'
            ? [
                  {
                      label: 'NCA',
                      options: [
                          {
                              label: 'Exclude NCA',
                              value: 'excludeNCA',
                              toggles: 'NCAOnly',
                          },
                          {
                              label: 'NCA Only',
                              value: 'NCAOnly',
                              toggles: 'excludeNCA',
                          },
                      ],
                  },
              ]
            : []),
        ...(deskId === 'the-west'
            ? [
                  {
                      label: 'Premium',
                      options: [
                          {
                              label: 'Exclude Premium',
                              value: 'excludePremium',
                              toggles: 'premiumOnly',
                          },
                          {
                              label: 'Premium Only',
                              value: 'premiumOnly',
                              toggles: 'excludePremium',
                          },
                      ],
                  },
              ]
            : []),
    ]
    return filters
}

export class ArticleSearch extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            topicValue: [],
            searchValue: '',
            filterValues: [],
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.desk !== nextProps.desk) {
            this.fetchSearchState(nextProps.desk, () => {
                this.performSearch(nextProps.desk)
            })
        }
    }

    componentDidMount() {
        const { desk } = this.props
        this.props.fetchTopics(desk.api)
        this.fetchSearchState(this.props.desk, () => {
            this.performSearch(this.props.desk)
        })
    }

    handleTopicChange = (val: ValueType<SelectOption>): void => {
        const updatedTopicValues: SelectOption[] = Array.isArray(val) ? val : []
        this.setState({ topicValue: updatedTopicValues }, () => {
            this.saveSearchState(this.props.desk)
        })
    }

    handleFilterChange = (
        value: ValueType<FilterOption>,
        meta: ActionMeta<FilterOption> | SelectOptionActionMeta<FilterOption>,
    ): void => {
        let updatedFilterValues: FilterOption[] = Array.isArray(value) ? value : []

        // Remove clashing filters
        if (meta.action === 'select-option' && 'option' in meta) {
            if (typeof meta.option?.toggles === 'string') {
                const toggledFilter = meta.option.toggles
                updatedFilterValues = updatedFilterValues.filter(
                    (filter) => filter.value !== toggledFilter,
                )
            }
        }

        this.setState(
            {
                filterValues: updatedFilterValues,
            },
            () => {
                this.saveSearchState(this.props.desk)
            },
        )
    }

    handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchValue: e.target.value })
    }

    handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        this.performSearch(this.props.desk)
    }

    performSearch = (desk: Desk) => {
        const { searchValue, topicValue, filterValues } = this.state
        let includeFuture = false
        let includePremium = true
        let premiumOnly = false
        const requiredSyndications: SyndicationId[] = []
        const excludedSyndications: SyndicationId[] = []

        filterValues.forEach((filter) => {
            switch (filter.value) {
                case 'includeFuture':
                    includeFuture = true
                    break
                case 'excludeAAP':
                    excludedSyndications.push('AAP')
                    break
                case 'excludeNCA':
                    excludedSyndications.push('Newscorp')
                    break
                case 'AAPOnly':
                    requiredSyndications.push('AAP')
                    break
                case 'NCAOnly':
                    requiredSyndications.push('Newscorp')
                    break
                case 'excludePremium':
                    includePremium = false
                    premiumOnly = false
                    break
                case 'premiumOnly':
                    premiumOnly = true
                    includePremium = true
                    break
            }
        })

        return this.props.fetchStories(desk.api, {
            search: searchValue,
            topics: topicValue.map((t) => t.value),
            includeFuturePublications: includeFuture,
            includePremium,
            premiumOnly,
            requiredSyndications,
            excludedSyndications,
        })
    }

    openSearchHelp = () => {
        // eslint-disable-next-line
        window.open(
            '/help/search',
            'Search Help',
            'width=800,height=500,resizable,scrollbars=yes,status=1',
        )
    }

    resetState = () => {
        this.setState(
            {
                searchValue: '',
                topicValue: [],
                filterValues: [],
            },
            () => {
                this.saveSearchState(this.props.desk)
            },
        )
    }

    fetchSearchState = (desk: Desk, callback: () => void) => {
        try {
            const filterGroups: Array<GroupType<FilterOption>> = filtersByDesk(desk.id)
            const topicValuesStored = localStorage.getItem(topicStorageKey(desk.id))
            const filterValuesStored = localStorage.getItem(filterStorageKey(desk.id))
            let topicValue: SelectOption[] = []
            let filterValues: FilterOption[] = []

            if (topicValuesStored) {
                topicValue = JSON.parse(topicValuesStored)
                // Ensure what's in storage matches current topics
                if (this.props.topics.length > 0) {
                    topicValue = topicValue.filter((topic) =>
                        this.props.topics.some(
                            (existingTopics) => topic.value === existingTopics.value,
                        ),
                    )
                }
            }

            if (filterValuesStored) {
                filterValues = JSON.parse(filterValuesStored)
                if (filterGroups.length > 0) {
                    const filterOptions: FilterOption[] = []
                    filterGroups.forEach(({ options }) => filterOptions.push(...options))

                    filterValues = filterValues.filter((filter) =>
                        filterOptions.some(
                            (existingFilter) => filter.value === existingFilter.value,
                        ),
                    )
                }
            }

            this.setState(
                {
                    filterValues,
                    topicValue,
                },
                callback,
            )
        } catch (err) {
            // tslint:disable-next-line: no-console
            console.error('Failed to fetch search state', err)
            callback()
        }
    }

    saveSearchState = (desk: Desk) => {
        try {
            localStorage.setItem(topicStorageKey(desk.id), JSON.stringify(this.state.topicValue))
            localStorage.setItem(filterStorageKey(desk.id), JSON.stringify(this.state.filterValues))
        } catch (err) {
            // tslint:disable-next-line: no-console
            console.error('Failed to save search state', err)
        }
    }

    render() {
        const { searchValue, filterValues, topicValue /*, includeFuture*/ } = this.state
        const { topics, desk } = this.props
        const filters: Array<GroupType<FilterOption>> = filtersByDesk(desk.id)

        return (
            <form className="form form--articles" onSubmit={this.handleOnSubmit}>
                <div className="form__grid">
                    <div className="form__control form__control--text">
                        <label className="form__label" htmlFor="topics">
                            Topics
                        </label>
                        <Select
                            autofocus
                            name="topics"
                            options={topics}
                            placeholder="Type to select topic(s)"
                            onChange={this.handleTopicChange}
                            value={topicValue}
                            isMulti
                        />
                    </div>
                    <div className="form__control form__control--text">
                        <label className="form__label" htmlFor="filters">
                            Search Filters
                        </label>
                        <Select<FilterOption>
                            name="filters"
                            options={filters}
                            value={filterValues}
                            placeholder="Type to select filter(s)"
                            onChange={this.handleFilterChange}
                            isMulti
                        />
                    </div>
                    <div className="form__control form__control--text">
                        <label className="form__label" htmlFor="keyword">
                            Keyword/NewsGate ID
                            <HelpIcon onClick={this.openSearchHelp} />
                        </label>
                        <input
                            className="form__input form__input--text"
                            id="keyword"
                            onChange={this.handleSearchChange}
                            type="text"
                            value={searchValue}
                        />
                    </div>
                    <div className="form__control form__control--submit">
                        <button className="button button--generic button--small" type="submit">
                            <SearchIcon />
                            Search
                        </button>
                        <button
                            className="button button--keyline button--small"
                            onClick={this.resetState}
                            type="button"
                        >
                            <ResetIcon />
                            Reset Criteria
                        </button>
                    </div>
                </div>
            </form>
        )
    }
}

const mapStateToProps = ({ search: { topics } }: GazetteState) => ({
    topics: topics.list,
})

export default connect(mapStateToProps, { fetchTopics, fetchStories })(ArticleSearch)
