import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import classnames from 'classnames'

import { ItemActionLink } from 'gazette/components/ItemActionLink/ItemActionLink'
import { ViewIcon } from 'gazette/icons/view_icon'
import { EditIcon } from 'gazette/icons/edit_icon'
import { CopyIcon } from 'gazette/icons/copy_icon'
import { isCueArticle } from 'gazette/actions/cue/isCueArticle'
import { config } from 'gazette/config'

interface Props {
    className?: string
    id: string
    desk: string
    hasCopied: boolean
    link?: string
    onCopy: () => void
    canEditContent?: boolean
}

declare const BASE_URL: string

export const generateEditLink = (publicationId: string, desk: string) => {
    if (isCueArticle(publicationId)) {
        const cueID = publicationId.slice(2)
        const storyUri = encodeURI(`${config.cueBaseUrl}/webservice/escenic/content/${cueID}`)
        const link = `${config.cueBaseUrl}/cue-web/#/main?mimetype=x-ece/story&uri=${storyUri}`
        return link
    } else {
        const link =
            (typeof BASE_URL === 'undefined' ? window.location.origin : BASE_URL) +
            '/desk/' +
            desk +
            '/edit/' +
            publicationId
        return link
    }
}

export const StoryDropdown: React.FC<Props> = (props) => {
    const isNewsgateID = props.id.indexOf('B88') === 0

    const searchableID = isNewsgateID ? props.id.substring(3) : props.id

    return (
        <div className={classnames('dropdown', props.className)}>
            <CopyToClipboard onCopy={props.onCopy} text={searchableID}>
                <button
                    className="button button--text-icon story__button story__button--copy"
                    type="button"
                >
                    <CopyIcon />
                    {props.hasCopied ? 'Copied!' : 'Copy ID'}
                </button>
            </CopyToClipboard>

            {props.link && (
                <ItemActionLink
                    icon={<ViewIcon />}
                    text="View"
                    linkTo={props.link}
                    forceNewWindow
                />
            )}

            {props.canEditContent && (
                <ItemActionLink
                    icon={<EditIcon />}
                    text="Edit"
                    linkTo={generateEditLink(props.id, props.desk)}
                    forceNewWindow
                    useWindowOpen
                />
            )}
        </div>
    )
}
