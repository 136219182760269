import { ActionMeta } from 'react-select'

export interface FieldDefinitionBase {
    name: string
    label: string
    defaultValue: string | undefined
}

export interface NumberDefinition extends FieldDefinitionBase {
    inputType: 'number'
}

export interface SelectOption {
    value: string
    label: string
}

export interface SelectDefinition extends FieldDefinitionBase {
    inputType: 'select'
    options: SelectOption[]
}

export interface TextDefinition extends FieldDefinitionBase {
    inputType: 'text'
    validation?: string // @TODO: when converting to typescript, make this RegExp
    invalidMessage?: string
}

export interface SelectOptionActionMeta<OptionType> extends ActionMeta<OptionType> {
    action: 'select-option'
    name: string
    option: OptionType
}

export interface StoryPlaceholderDefinition extends FieldDefinitionBase {
    inputType: 'story'
    invalidMessage?: string
}

export type FieldDefinition =
    | NumberDefinition
    | SelectDefinition
    | TextDefinition
    | StoryPlaceholderDefinition

export interface CollectionMetadata {
    common?: FieldDefinition[]
    targeted?: {
        [desk: string]: {
            [curationId: string]: FieldDefinition[]
        }
    }
}

export const curationMetadataDefinitions: CollectionMetadata = {
    // metadata that applies to all curations
    common: [],

    // metadata that applies to specific curations
    targeted: {
        'the-west': {
            'marketing-tile': [
                {
                    name: 'background-colour',
                    label: 'Background Colour',
                    defaultValue: '#FFF',
                    inputType: 'text',
                    validation: '^#[0-9a-fA-F]{3,6}$', // regex to validate for a hex code
                    invalidMessage: 'Background colour must be a valid hex value',
                },
            ],
            home: [
                {
                    name: 'promotional-card',
                    label: 'Promotional Card',
                    defaultValue: '',
                    inputType: 'story',
                },
            ],
            'breaking-news': [
                {
                    name: 'breaking-news-theme',
                    label: 'Breaking News Theme',
                    inputType: 'select',
                    options: [
                        {
                            value: 'default',
                            label: 'Default',
                        },
                        {
                            value: 'announcement',
                            label: 'Announcement',
                        },
                        {
                            value: 'cricket',
                            label: 'Cricket',
                        },
                        {
                            value: 'thegame',
                            label: 'The Game - AFL',
                        },
                        {
                            value: 'pick11',
                            label: 'The Game - Cricket',
                        },
                        {
                            value: 'csk',
                            label: 'Claremont The Trial',
                        },
                    ],
                    defaultValue: 'default',
                },
                {
                    name: 'breaking-news-title',
                    label: 'Breaking News Title',
                    inputType: 'text',
                    defaultValue: 'Breaking News',
                    validation: '(|.{5,15})',
                },
            ],
            'promotional-cards': [
                {
                    name: 'news-promotional-card',
                    label: 'News',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'sport-promotional-card',
                    label: 'Sport',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'business-promotional-card',
                    label: 'Business',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'politics-promotional-card',
                    label: 'Politics',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'lifestyle-promotional-card',
                    label: 'Lifestyle',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'entertainment-promotional-card',
                    label: 'Entertainment',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'travel-promotional-card',
                    label: 'Travel',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'technology-promotional-card',
                    label: 'Technology',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'regional-subscriber-exclusive-card',
                    label: 'Regional subscriber exclusive',
                    defaultValue: '',
                    inputType: 'story',
                },
            ],
        },
        perthnow: {
            'promotional-cards': [
                {
                    name: 'news-promotional-card',
                    label: 'News',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'sport-promotional-card',
                    label: 'Sport',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'business-promotional-card',
                    label: 'Business',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'politics-promotional-card',
                    label: 'Politics',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'opinion-promotional-card',
                    label: 'Opinion',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'lifestyle-promotional-card',
                    label: 'Lifestyle',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'entertainment-promotional-card',
                    label: 'Entertainment',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'travel-promotional-card',
                    label: 'Travel',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'technology-promotional-card',
                    label: 'Technology',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'features-promotional-card',
                    label: 'Features',
                    defaultValue: '',
                    inputType: 'story',
                },
            ],
            'community-news-promotion-card': [
                {
                    name: 'city-west-promotional-card',
                    label: 'City West => Stirling Times / Western Suburbs Weekly',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'hills-east-promotional-card',
                    label:
                        'Hills East => Eastern Reporter / Hills Avon Valley Gazette / Midland Kalamunda Reporter / The Advocate',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'north-promotional-card',
                    label: 'North => Joondalup Times / Wanneroo Times / North Coast Times',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'south-promotional-card',
                    label:
                        'South => Canning Gazette / Cockburn Gazette / Comment News / Fremantle Gazette / Melville Times / Southern Gazette',
                    defaultValue: '',
                    inputType: 'story',
                },
                {
                    name: 'south-coast-promotional-card',
                    label: 'South Coast => Mandurah Coastal Times / Weekend Kwinana Courier',
                    defaultValue: '',
                    inputType: 'story',
                },
            ],
        },
    },
}
