import React from 'react'
import { Story } from 'gazette/components/story/story'

import { StoriesState, Desk } from 'gazette/types'

import './story_list.scss'

interface Props {
    stories: StoriesState
    desk: Desk
}

const StoryList: React.FC<Props> = ({ stories, desk }) => {
    let i = 0

    if (stories.error) {
        return <div>Could not retrieve stories: {stories.error}</div>
    }

    if (stories.loading) {
        return <div>Loading article list&hellip;</div>
    }

    if (!stories.loaded) {
        return <div />
    }

    return (
        <div className="story-list">
            <p className="result-count">Search Results &ndash; {stories.list.length} found</p>
            <ul className="story-list__results">
                {stories.list.map((story) => {
                    /**
                     * @note There's currently duplicate IDs within the returned array of stories
                     * so need to add an external influence on really making sure the IDs are
                     * unique.
                     */
                    const storyId = `${story.id}-${(i += 1)}`
                    return (
                        <li key={storyId}>
                            <Story
                                publication={story}
                                desk={desk}
                                miniMode={true}
                                enableDragDrop={true}
                            />
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default StoryList
