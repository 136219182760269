import React from 'react'
import { MenuBarSimple } from 'gazette/components/menu_bar/menu_bar'
import { config } from 'gazette/config'

import './welcome.scss'

export default class Welcome extends React.Component {
    render() {
        return (
            <main>
                <MenuBarSimple />
                <div className="welcome__container">
                    <a
                        href={config.stsLoginUrl}
                        className="button button--generic button--medium button--keyline"
                    >
                        Sign in with WANEWS
                    </a>
                </div>
            </main>
        )
    }
}
