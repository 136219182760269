import * as React from 'react'

import './live-blog.scss'
import { Desk } from 'gazette/types'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { ViewLiveBlog } from './view-live-blog'
import { LiveBlogList } from './live-blog-list'

interface Props {
    desk: Desk
}

export interface Blog {
    id: string
    name: string
}

export const LiveBlog: React.FC<Props> = ({ desk }) => {
    const match = useRouteMatch()

    return (
        <Switch>
            <Route path={`${match.url}/:blogId`}>
                <ViewLiveBlog desk={desk} />
            </Route>

            <Route>
                <LiveBlogList desk={desk} />
            </Route>
        </Switch>
    )
}
