import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { ArticleV4DTO } from '@west-australian-newspapers/publication-types'
import { config } from 'gazette/config'
import { createScript, scriptExists } from 'gazette/utils/scripts'
;(window as any).React = React
;(window as any).ReactDOM = ReactDOM

interface Props {
    article: ArticleV4DTO
}

interface State {
    scriptLoaded: boolean
    scriptFailedToLoad: boolean
}

export interface EditorProps {
    showToolbar?: boolean
    initialContent?: any
}

declare const ContentEditor: {
    insertTheWestFonts: () => void
    insertPerthNowFonts: () => void
    getContentBlocks: (article: ArticleV4DTO) => any

    WestEditor: React.ComponentClass<EditorProps>
    PerthNowEditor: React.ComponentClass<EditorProps>
}

export class ContentEditorWrapper extends React.Component<Props, State> {
    state: State = {
        scriptFailedToLoad: false,
        scriptLoaded: false,
    }
    async componentDidMount() {
        if (!config.contentEditor) {
            return
        }
        if (!scriptExists(config.contentEditor)) {
            try {
                await createScript(config.contentEditor)
                ContentEditor.insertTheWestFonts()

                this.setState({
                    scriptLoaded: true,
                })
            } catch (err) {
                this.setState({
                    scriptFailedToLoad: true,
                })
            }
        } else {
            this.setState({
                scriptLoaded: true,
            })
        }
    }
    render() {
        if (!config.contentEditor) {
            return <div>Content editing disabled in this environment</div>
        }
        if (this.state.scriptFailedToLoad) {
            return <div>Editor failed to load</div>
        }

        if (!this.state.scriptLoaded) {
            return <div>Editor loading...</div>
        }

        return (
            <ContentEditor.WestEditor
                initialContent={ContentEditor.getContentBlocks(this.props.article)}
            />
        )
    }
}
