import { combineReducers, Reducer } from 'redux'
import { reducer as notifications, NotificationsState } from 'react-redux-notifications'
import { GazetteState, GazetteAction } from 'gazette/types'

import search from 'gazette/features/search/search.redux'
import curation from 'gazette/features/manage-curation/manage-curation.redux'
import curations from 'gazette/reducers/curations'
import { redirects } from 'gazette/features/manage-redirects/components/EditRedirect/EditRedirect.redux'

const rootReducer = combineReducers<GazetteState, GazetteAction>({
    search,
    curation,
    curations,
    redirects,
    notifications: notifications as Reducer<NotificationsState, GazetteAction>,
})

export default rootReducer
