import { JSONSchema6 } from 'json-schema'
import { TopicV4DTO } from '@west-australian-newspapers/publication-types'
import { TaxonomyTopicsResponse } from 'gazette/types'

export interface ArticleContent {
    headKicker: string
    homepageHead: string
    homepageTeaser: string
    heading: string
    byline: {
        text: string
    }
    socialHead: string
    socialTeaser: string
}

export interface Settings {
    status: string
    source: string
    topics: {
        primary: {
            id: string
        }
        secondary: TopicV4DTO[]
    }
    isSponsored: boolean
    isPremium: boolean
    excludeFeaturedVideo: boolean
    lastUpdated: string
    publicationDate: string
    canonicalTitle: string
    canonicalUrl: string
    primaryTopic: string
    secondaryTopics: string[]
    changeReason: string
    allowCommenting: boolean
}

export type PublicationData = ArticleContent &
    Settings & { requiredAccess: { level: 'anonymous' | 'subscriber' | 'registered' } }

export interface FormSchema {
    articleContent: ArticleContent
    settings: Settings & {
        requiredAccess: 'Everyone' | 'Registered' | 'Subscriber'
    }
}

export interface ReturnSchema {
    articleContent: Partial<ArticleContent>
    settings: Partial<
        Settings & {
            requiredAccess: 'Everyone' | 'Registered' | 'Subscriber'
        }
    >
}

export const getSchema = (topics: TaxonomyTopicsResponse): JSONSchema6 => {
    return {
        type: 'object',
        properties: {
            articleContent: {
                title: 'Article Content',
                type: 'object',

                properties: {
                    headKicker: {
                        type: 'string',
                        title: 'Head Kicker',
                    },
                    homepageHead: {
                        type: 'string',
                        title: 'Homepage Head',
                    },
                    homepageTeaser: {
                        type: 'string',
                        title: 'Homepage Teaser',
                    },
                    heading: {
                        type: 'string',
                        title: 'Headline',
                    },
                    byline: {
                        type: 'string',
                        title: 'Byline',
                    },
                    socialHead: {
                        type: 'string',
                        title: 'Social Head',
                    },
                    socialTeaser: {
                        type: 'string',
                        title: 'Social Teaser',
                    },
                },
                required: ['headKicker', 'homepageHead', 'homepageTeaser', 'heading'],
            },
            settings: {
                title: 'Settings',
                type: 'object',

                properties: {
                    status: {
                        type: 'string',
                        title: 'Status',
                        enum: ['live', 'dead', 'held', 'pending'],
                    },
                    source: {
                        type: 'string',
                        title: 'Source',
                    },
                    primaryTopic: {
                        type: 'string',
                        title: 'Primary Topic',
                    },
                    secondaryTopics: {
                        type: 'array',
                        title: 'Secondary Topics',
                        items: {
                            type: 'string',
                            enum: topics.map((topic) => topic.value),
                        },
                    },
                    lastUpdated: {
                        type: 'string',
                        title: 'Last Updated',
                    },
                    publicationDate: {
                        type: 'string',
                        title: 'Publication Date',
                    },
                    isSponsored: {
                        type: 'boolean',
                        title: 'Sponsored Content',
                    },
                    requiredAccess: {
                        type: 'string',
                        title: 'Who can access this?',
                        enum: ['Everyone', 'Registered', 'Subscriber'],
                    },
                    excludeFeaturedVideo: {
                        type: 'boolean',
                        title: 'Exclude Featured Video',
                    },
                    allowCommenting: {
                        type: 'boolean',
                        title: 'Allow Commenting',
                    },
                    canonicalTitle: {
                        type: 'string',
                        title: 'Canonical Title',
                    },
                    canonicalUrl: {
                        type: 'string',
                        title: 'Canonical URL',
                    },
                    changeReason: {
                        type: 'string',
                        title: 'Change Reason',
                        minLength: 5,
                    },
                },
                required: ['changeReason'],
            },
        },
    }
}

export const uiSchema = {
    settings: {
        status: {
            'ui:widget': 'select',
        },
        primaryTopic: {
            'ui:disabled': true,
        },
        secondaryTopics: {
            'ui:widget': 'ui:ArrayFieldTemplate',
            'ui:options': {
                orderable: false,
            },
        },
        lastUpdated: {
            'ui:readonly': true,
        },
        publicationDate: {
            'ui:readonly': true,
        },
        isSponsored: {
            classNames: 'full-width',
        },
        isPremium: {
            classNames: 'full-width',
        },
        excludeFeaturedVideo: {
            classNames: 'full-width',
        },
        allowCommenting: {
            classNames: 'full-width',
        },
    },
}
