import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Blog } from './live-blog'
import { getBlogs, createBlog } from './api'
import { Desk } from 'gazette/types'

export const LiveBlogList: React.FC<{ desk: Desk }> = ({ desk }) => {
    const match = useRouteMatch()

    const [blogs, setBlogs] = React.useState<null | Blog[]>(null)
    const [creating, setCreating] = React.useState(false)
    const [createText, setCreateText] = React.useState<string>('')
    const [createFailedError, setCreateFailedError] = React.useState<string | null>(null)

    React.useEffect(() => {
        async function loadBlogs() {
            const result = await getBlogs(desk.id)
            setBlogs(result)
        }

        loadBlogs()
    }, [desk.id])

    const preventSubmit = !createText || blogs === null || creating
    return (
        <section className="container">
            <h2 className="live-blog__page-title">Live Blogs</h2>

            <div className="live-blog__form-section">
                <h3 className="live-blog__heading">Open existing blog</h3>

                <div data-testid="blog-list" className="live-blog__form--stacked">
                    {blogs ? (
                        blogs.map((blog) => {
                            return (
                                <div
                                    key={blog.id}
                                    className="live-blog__existing-blog item__container"
                                >
                                    <label className="live-blog__label form__label">
                                        {blog.name}
                                    </label>

                                    <Link
                                        to={`${match.url}/${blog.id}`}
                                        data-testid={`open-blog-${blog.id}`}
                                        className="live-blog__button button button--generic button--small"
                                    >
                                        Open
                                    </Link>
                                </div>
                            )
                        })
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </div>

            <div className="live-blog__form-section">
                <h3 className="live-blog__heading">Setup a New Live Blog</h3>

                <form
                    className="live-blog__form"
                    onSubmit={async (e) => {
                        e.preventDefault()
                        if (preventSubmit) {
                            return
                        }

                        setCreating(true)
                        try {
                            const response = await createBlog(desk.id, createText)
                            if (response.status !== 201) {
                                setCreateFailedError('Failed to create blog')
                                setCreating(false)
                                return
                            }

                            const responseBody = await response.json()
                            setCreating(false)
                            setBlogs([
                                { id: responseBody.blogId, name: createText },
                                ...(blogs || []),
                            ])
                            setCreateText('')
                        } catch (err) {
                            // tslint:disable-next-line:no-console
                            console.log('Failed to create live blog', err)
                            setCreateFailedError('Failed to create blog')
                            setCreating(false)
                        }
                    }}
                >
                    {createFailedError && <div>{createFailedError}</div>}
                    <label className="live-blog__label form__label" htmlFor="new-blog-name">
                        Blog Name:
                    </label>
                    <input
                        id="new-blog-name"
                        className="live-blog__input"
                        type="text"
                        value={createText}
                        onChange={(e) => setCreateText(e.target.value)}
                    />
                    <button
                        type="submit"
                        className="live-blog__button button button--generic button--small"
                        disabled={preventSubmit}
                    >
                        Create Blog
                    </button>
                </form>
            </div>
        </section>
    )
}
