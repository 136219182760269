import { useEffect } from 'react'
import { createScript, scriptExists } from 'gazette/utils/scripts'

const trackJsScript = 'https://cdn.trackjs.com/agent/v3/latest/t.js'

export const TrackJS: React.FC<{ trackJsToken: string }> = ({ trackJsToken }) => {
    useEffect(() => {
        async function initTrackJs() {
            if (!scriptExists(trackJsScript)) {
                try {
                    await createScript(trackJsScript)

                    trackJs.install({
                        token: trackJsToken,
                        application: 'gazette',
                    })
                } catch (e) {
                    console.error(e)
                }
            }
        }

        initTrackJs()
    })

    return null
}
