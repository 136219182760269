import { Desk } from 'gazette/types'
import { NewsdeskIcon } from 'gazette/constants/components/newsdesk_icon'
import { PerthNowIcon } from 'gazette/constants/components/perth-now'
import { TheWestIcon } from 'gazette/constants/components/the-west'
import { SevenNewsIcon } from 'gazette/constants/components/sevennews'
import { config } from 'gazette/config'

const desks: Desk[] = [
    {
        id: 'pixel-and-ink',
        name: 'Pixel & Ink',
        api: config.pixelAndInkCurationApi,
        baseUrl: config.pixelAndInkBaseUrl,
        defaultCollection: 'front-page',
        icon: NewsdeskIcon,
    },
    {
        id: 'the-west',
        name: 'The West Australian',
        api: config.theWestCurationApi,
        baseUrl: config.theWestBaseUrl,
        defaultCollection: 'home',
        icon: TheWestIcon,
    },
    {
        id: 'perthnow',
        name: 'PerthNow',
        api: config.perthnowCurationApi,
        baseUrl: config.perthnowBaseUrl,
        defaultCollection: 'top-stories',
        icon: PerthNowIcon,
    },
    {
        id: '7news',
        name: '7News',
        api: config.sevennewsCurationApi,
        baseUrl: config.sevennewsBaseUrl,
        defaultCollection: 'home',
        icon: SevenNewsIcon,
    },
].filter((desk) => !!desk.baseUrl && !!desk.api)

export default {
    defaultDesk: 'the-west',
    desks,
}
