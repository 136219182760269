import * as React from 'react'
import { Desk } from 'gazette/types'

import './upload-file.scss'

interface Props {
    desk: Desk
}

interface State {
    uploadedFile?: string
    errorMessage?: string
    name: string
    file?: string
    uploading?: boolean
}

export class UploadFile extends React.Component<Props, State> {
    state: State = {
        name: '',
        uploading: false,
    }

    submit = async (e: React.FormEvent<any>) => {
        e.preventDefault()

        const formData = new FormData()
        const fileField = document.querySelector<HTMLInputElement>("input[type='file']")
        if (!fileField || !fileField.files || !fileField.files[0]) {
            // No file error message
            return
        }

        formData.append('filefield', fileField.files[0])

        try {
            this.setState({
                uploading: true,
                errorMessage: undefined,
                uploadedFile: undefined,
            })
            const response = await fetch(`${this.props.desk.api}/upload-file`, {
                method: 'POST',
                body: formData,
                mode: 'cors',
                headers: {
                    'Access-Control-Request-Headers': 'Location',
                },
            })

            if (response.status !== 201) {
                throw new Error('Upload failed')
            }
            const location = response.headers.get('location')

            if (location) {
                this.setState({
                    uploadedFile: location,
                    uploading: false,
                })
            }
        } catch (err) {
            this.setState({
                uploading: false,
                errorMessage: 'Upload failed, please contact #product-help',
            })
            // tslint:disable-next-line:no-console
            console.error(err)
        }
    }

    openHelp = () => {
        // eslint-disable-next-line
        window.open(
            '/help/upload',
            'Search Help',
            'width=800,height=500,resizable,scrollbars=yes,status=1',
        )
    }

    render() {
        return (
            <section className="collection">
                <h2 className="upload-heading">Upload file</h2>

                <p className="upload-help">
                    <a onClick={this.openHelp}>How does this work?</a>
                </p>
                <form method="POST" onSubmit={this.submit} encType="multipart/form-data">
                    <input
                        type="file"
                        name="filefield"
                        value={this.state.name}
                        className="file-uploader"
                        onChange={(e) => this.setState({ name: e.currentTarget.value })}
                    />
                    <input
                        value="Upload file"
                        onChange={(e) => this.setState({ file: e.currentTarget.value })}
                        className="button button--generic button--small upload-button"
                        type="submit"
                        disabled={this.state.uploading}
                    />
                </form>

                {this.state.errorMessage && <div>{this.state.errorMessage}</div>}
                {this.state.uploadedFile && (
                    <div className="uploaded-file-message">
                        Your file is:{' '}
                        <a href={this.state.uploadedFile} target="_blank">
                            {this.state.uploadedFile}
                        </a>
                    </div>
                )}
            </section>
        )
    }
}
