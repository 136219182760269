import React from 'react'

export interface Props {
    delay: number // in milliseconds (ms)
    children: React.ReactNode
}

interface State {
    isVisible: boolean
}

export class RemoveAfter extends React.Component<Props, State> {
    private timer: any
    constructor(props: Props) {
        super(props)

        this.state = {
            isVisible: true,
        }
    }

    runTimeout() {
        if (!this.timer) {
            this.timer = setTimeout(() => {
                this.setState({
                    isVisible: false,
                })
            }, this.props.delay)
        } else {
            clearTimeout(this.timer)
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    componentDidMount() {
        this.timer = this.runTimeout()
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.children !== this.props.children) {
            this.runTimeout()
        } else {
            clearTimeout(this.timer)
        }
    }

    render() {
        return this.state.isVisible ? <React.Fragment>{this.props.children}</React.Fragment> : null
    }
}
