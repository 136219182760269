import {
    RawDraftContentBlock,
    RawDraftEntity,
    EditorState,
    convertToRaw,
    RawDraftContentState,
} from 'draft-js'
import { toDraftJSBlock } from './utils'
import { BlockV4DTO, Intention } from '@west-australian-newspapers/publication-types'

export function fromDraftJsRangesToIntentions(
    text: string,
    block: RawDraftContentBlock,
    entityMap: { [key: string]: RawDraftEntity },
) {
    const intentions: Intention[] = []
    for (const range of block.inlineStyleRanges) {
        if (range.style === 'BOLD') {
            intentions.push({
                kind: 'important',
                index: 0,
                match: text.substring(range.offset, range.offset + range.length),
            })
        }

        if (range.style === 'ITALIC') {
            intentions.push({
                kind: 'emphasized',
                index: 0,
                match: text.substring(range.offset, range.offset + range.length),
            })
        }
    }

    for (const range of block.entityRanges) {
        const entity = entityMap[range.key]
        if (entity.type === 'LINK') {
            intentions.push({
                href: entity.data.url,
                kind: 'link',
                index: 0,
                match: text.substring(range.offset, range.offset + range.length),
            })
        }
    }

    return intentions
}

export function fromDraftJsState(editorState: EditorState): BlockV4DTO[] {
    // Support more than just plain paragraphs
    const raw = convertToRaw(editorState.getCurrentContent())
    return raw.blocks
        .map<BlockV4DTO | null>((block) => {
            if (block.type === 'paragraph' || block.type === 'unstyled') {
                const text = block.text.trim()

                if (text === '') {
                    return null
                }

                return {
                    kind: 'text',
                    text,
                    intentions: fromDraftJsRangesToIntentions(text, block, raw.entityMap),
                }
            }
            if (block.type === 'EMBED') {
                if (block.text === '') {
                    return null
                }
                return {
                    kind: 'embed',
                    reference: block.text.trim(),
                }
            }

            return null
        })
        .filter((block): block is BlockV4DTO => block !== null)
}

export function getRawDraftJSBlocks(blocks: BlockV4DTO[]) {
    return blocks
        .map((block, entityMapIndex) => toDraftJSBlock(block, () => entityMapIndex))
        .reduce<RawDraftContentState>(
            (acc, val) => {
                acc.blocks.push(...val.blocks)
                acc.entityMap = {
                    ...acc.entityMap,
                    ...val.entityMap,
                }
                return acc
            },
            { blocks: [], entityMap: {} },
        )
}
