import React from 'react'

import MetadataLabel from './metadata_label'

interface Props {
    /** Unique id attribute of the form field */
    name: string

    /** Human-readable name of the field */
    label: string
}

export const MetadataFieldset: React.FC<Props> = ({ label, name, children }) => (
    <div className="form__control">
        <MetadataLabel name={name} label={label} />
        {children}
    </div>
)
