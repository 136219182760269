import reduceReducers from 'reduce-reducers'
import { Dispatch } from 'redux'
import { checkCurationUpdatedReducer } from './reducers/check-updated'
import { updateCurationReducer } from './reducers/update-curation'
import { saveCurationReducer } from './reducers/save-curation'
import { loadCurationReducer } from 'gazette/features/manage-curation/reducers/load-curation'

import {
    CheckCurationUpdatedAction,
    GazetteAction,
    CurationAction,
    UpdateCurationActionParam,
    SaveCurationObject,
    ErrorAction,
    CurationState,
    LoadCurationAction,
} from 'gazette/types'
import { checkStatus } from 'gazette/actions/common'
import { CurationApi_ExpandedCurationV4ResponseDTO } from '@west-australian-newspapers/publication-types'
import {
    CHECK_CURATION_UPDATED,
    LOAD_CURATION,
    LOAD_CURATION_DONE,
    REMOVE_CURATION_ITEM,
    SAVE_CURATION,
    SAVE_CURATION_DONE,
    UPDATE_CURATION,
    UPDATE_CURATION_METADATA,
} from 'gazette/features/manage-curation'

const loadCurationUpdated = (
    id: string,
    desk: string,
    data?: CurationApi_ExpandedCurationV4ResponseDTO | Error,
): CheckCurationUpdatedAction | ErrorAction => {
    if (!data) {
        return {
            type: CHECK_CURATION_UPDATED,
            meta: { id, desk },
        }
    }

    if (data instanceof Error) {
        return {
            type: CHECK_CURATION_UPDATED,
            meta: { id, desk },
            payload: data,
            error: true,
        }
    }

    return {
        type: CHECK_CURATION_UPDATED,
        meta: { id, desk },
        payload: data,
        error: false,
    }
}

export const checkCurationUpdated = (api: string, id: string, desk: string) => (
    dispatch: Dispatch<GazetteAction>,
) => {
    dispatch(loadCurationUpdated(id, desk))

    return fetch(
        `${api}/v4/curation/${id}?expand=articles&includeFuture=true&includeDead=true&include_expired=true`,
        {
            credentials: 'same-origin',
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json() as Promise<CurationApi_ExpandedCurationV4ResponseDTO>)
        .then((data) => ({ ...data, id }))
        .then((data) => dispatch(loadCurationUpdated(id, desk, data)))
        .catch((err) => dispatch(loadCurationUpdated(id, desk, err)))
}

/**
 * Action to fetch curation from the Content API
 * @return {Promise} Store dispatch event
 */
export const fetchCuration = (api: string, desk: string, id: string) => (
    dispatch: Dispatch<GazetteAction>,
) => {
    const meta = { id, desk }
    const loadCuration: LoadCurationAction = {
        type: LOAD_CURATION,
        meta: { desk, id },
        payload: undefined,
    }
    dispatch(loadCuration)

    return fetch(
        `${api}/v4/curation/${id}?expand=articles&includeFuture=true&includeDead=true&include_expired=true`,
        {
            credentials: 'same-origin',
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json() as Promise<CurationApi_ExpandedCurationV4ResponseDTO>)
        .then((data) => ({
            ...data,
            id,
            meta,
        }))
        .then((data) =>
            dispatch(
                createCurationAction({
                    type: LOAD_CURATION_DONE,
                    payload: data,
                    meta,
                    error: false,
                }),
            ),
        )
        .catch((err) =>
            dispatch(createCurationAction({ type: LOAD_CURATION_DONE, error: true, payload: err })),
        )
}

/**
 * Action to update articles in the local state
 * @param  {array}  curation - A curation to be saved
 * @return {Promise} Store dispatch event
 */
export const updateCuration = (update: UpdateCurationActionParam) => (
    dispatch: Dispatch<GazetteAction>,
) =>
    Promise.resolve(
        dispatch(
            createCurationAction({
                type: UPDATE_CURATION,
                payload: {
                    update,
                },
            }),
        ),
    )

/**
 * Action to update articles curation's metadata in the local state
 * @param  {object}  metadata - Metadata to be saved to curation
 * @return {Promise} Store dispatch event
 */
export const updateCurationMetadata = (
    update: CurationApi_ExpandedCurationV4ResponseDTO['metadata'],
) => (dispatch: Dispatch<GazetteAction>) =>
    Promise.resolve(
        dispatch(
            createCurationAction({
                type: UPDATE_CURATION_METADATA,
                payload: {
                    update,
                },
            }),
        ),
    )

/**
 * Action to remove an article in the local state
 * @param  {id}  story_id - Id of story to be removed from curation
 * @return {Promise} Store dispatch event
 */
export const removeCurationItem = (id: string) => (dispatch: Dispatch<GazetteAction>) =>
    Promise.resolve(
        dispatch(
            createCurationAction({
                type: REMOVE_CURATION_ITEM,
                payload: {
                    id,
                },
            }),
        ),
    )

/**
 * Action to update articles via the Curation API
 * @param  {array}  curation - A curation to be saved
 * @return {Promise} Store dispatch event
 */
export const saveCuration = (api: string, curation: SaveCurationObject) => (
    dispatch: Dispatch<GazetteAction>,
) => {
    dispatch(
        createCurationAction({
            type: SAVE_CURATION,
            payload: null,
        }),
    )

    fetch(`${api}/v4/curation/${curation.id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
        body: JSON.stringify(curation.body),
    })
        .then(checkStatus)
        .then((resp) => resp.json() as Promise<CurationApi_ExpandedCurationV4ResponseDTO>)
        .then((data) =>
            dispatch(
                createCurationAction({ type: SAVE_CURATION_DONE, payload: data, error: false }),
            ),
        )
        .catch((err) =>
            dispatch(createCurationAction({ type: SAVE_CURATION_DONE, payload: err, error: true })),
        )
}

/**
 * Reducer to load curation into the state
 * @param  {array}  [state={ list: [], error: null }] - Existing state
 * @param  {string} action - Action object passed in from actions
 * @return {object} Article or error object for state
 */
export default reduceReducers<CurationState>(
    checkCurationUpdatedReducer,
    loadCurationReducer,
    updateCurationReducer,
    saveCurationReducer,
)

export function createCurationAction<Action extends CurationAction | ErrorAction>(
    action: Action,
): Action {
    return action
}
