import React from 'react'
import CheveronIcon from './components/cheveron_icon'
import { DeskSelectionItem } from './components/desk_selection_item'

import { Desk } from 'gazette/types'

import './desk_selection.scss'

interface Props {
    activeDesk: Desk
    desks: Desk[]
}

interface State {
    dropdownEnabled: boolean
}

class DeskSelection extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            dropdownEnabled: false,
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (
            this.props.activeDesk &&
            nextProps.activeDesk &&
            this.props.activeDesk.name !== nextProps.activeDesk.name
        ) {
            this.setState({ dropdownEnabled: false })
        }
    }

    disableDropdown = () => this.setState({ dropdownEnabled: false })

    toggleDropdown = () => this.setState({ dropdownEnabled: !this.state.dropdownEnabled })

    render() {
        const {
            activeDesk: { name, icon: Icon },
            desks,
        } = this.props
        const { dropdownEnabled } = this.state

        return (
            <nav className="desk-selection" onMouseLeave={this.disableDropdown}>
                <button
                    className="desk-selection__current"
                    onClick={this.toggleDropdown}
                    type="button"
                >
                    <Icon />
                    <span>{name}</span>
                    <CheveronIcon />
                </button>
                {dropdownEnabled && (
                    <ul className="dropdown dropdown--desk-selection dropdown--active-block">
                        {desks.map((d) => (
                            <DeskSelectionItem key={d.id} desk={d} activeDeskName={name} />
                        ))}
                    </ul>
                )}
            </nav>
        )
    }
}

export default DeskSelection
