import React from 'react'
import StoryListContainer from 'gazette/features/search/story_list_container'
import ArticleSearchContainer from 'gazette/features/search/article_search_container'
import { Toolbar } from 'gazette/components/Toolbar/Toolbar'

import { Desk } from 'gazette/types'

import './article_search.scss'

interface Props {
    desk: Desk
}
const ArticleSearch: React.FC<Props> = ({ desk }) => (
    <Toolbar title="Article Search" halfWidth>
        <ArticleSearchContainer desk={desk} />
        <StoryListContainer desk={desk} />
    </Toolbar>
)

export default ArticleSearch
